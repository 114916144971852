import React from "react"
import { hideHelp } from '../../actions/actions'
import { connect } from 'react-redux'
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

function HelpDialog(props) {
  if (props.help.title === null) {
    return null
  }

  return (
    <Dialog open={true} onClose={() => props.dispatch(hideHelp())}>
        <DialogTitle>{props.help.title}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {props.help.body}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="outlined" onClick={() => props.dispatch(hideHelp())}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
  )
}

function mapStateToProps(state) {
  const { help } = state

  return { help }
}

export default connect(mapStateToProps)(HelpDialog)