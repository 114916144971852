import Routes from 'routes'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import store from './stores/store'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'

render(
    <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <Routes />
        </MuiPickersUtilsProvider>
    </Provider>,
    document.getElementById('app')
)
