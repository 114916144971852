import { createReducer } from '@reduxjs/toolkit'
import {
  LOADING_ACCOUNT_APPS_STATUS,
  LOADED_ACCOUNT_APPS_STATUS,
  SWITCHED_ACCOUNT_APP_STATUS,
  UPDATING_ACCOUNT_APP_STATUS,
  UPDATED_ACCOUNT_APP_STATUS,
  ADDING_ACCOUNT_APP_STATUS,
  ADDED_ACCOUNT_APP_STATUS
} from '../actions/actions'
import { Enums } from 'utils'

const initialState = {
  isLoading: false,
  currentAccountApp: null,
  currentAccountAppId: null,
  forCurrentAccount: null, // []
  forAllAccounts: null // []
}

const accountApps = createReducer(initialState, {
  LOADING_ACCOUNT_APPS_STATUS: (state, action) => { 
    state.isLoading = true
    // don't blat the currentAccountAppId as this should only be changed when switching account apps
    state.forCurrentAccount = null
    state.forAllAccounts = null
  },
  LOADED_ACCOUNT_APPS_STATUS: (state, action) => {
    state.isLoading = false
    state.forAllAccounts = action.data
    state.forCurrentAccount = state.forAllAccounts.filter(x => x.accountId === action.currentAccountId)
  },
  SWITCHED_ACCOUNT_APP_STATUS: (state, action) => {
    state.isLoading = false
    state.currentAccountApp = state.forAllAccounts.find(
      x => x.id === action.accountAppId
    )
    state.currentAccountAppId = action.accountAppId
    state.forCurrentAccount = state.forAllAccounts.filter(x => x.accountId === state.currentAccountApp.accountId)
  },
  UPDATING_ACCOUNT_APP_STATUS: (state, action) => {
    state.isLoading = true
  },
  UPDATED_ACCOUNT_APP_STATUS: (state, action) => {
    state.isLoading = false

    let updatedAccountAppAll = state.forAllAccounts.find(
      x => x.id === action.accountAppId
    )
    Object.assign(updatedAccountAppAll, action.params)

    let updatedAccountAppCurrent = state.forCurrentAccount.find(
      x => x.id === action.accountAppId
    )
    if (updatedAccountAppCurrent !== undefined) {
      Object.assign(updatedAccountAppCurrent, action.params)
    }
    
    if (state.currentAccountApp !== null && state.currentAccountApp.id === action.accountAppId) {
      Object.assign(state.currentAccountApp, action.params)
    }
  },
  ADDING_ACCOUNT_APP_STATUS: (state, action) => {
    // to make the UX fast, we add it locally when making the API request, then update it with the ID and isDefaultForAccount things we don't know, when it comes back
    state.isLoading = true

    const newAccountApp = {
      id: null,
      tempId: action.accountApp.tempId,
      accountId: action.accountApp.accountId,
      url: action.accountApp.url,
      urlPath: action.accountApp.url.replace('https://','').replace('http://', '').replace('apps.shopify.com/', ''),
      name: action.accountApp.name,
      appType: action.accountApp.appType,
      googleAnalyticsProperty: action.accountApp.googleAnalyticsProperty,
      googleAnalyticsPropertyGA4: action.accountApp.googleAnalyticsPropertyGA4,
      isDefaultForAccount: null
    }

    state.forAllAccounts.push(newAccountApp)

    if (state.currentAccountApp === null || state.currentAccountApp.accountId === newAccountApp.accountId) {
      state.forCurrentAccount.push(newAccountApp)
    }
  },
  ADDED_ACCOUNT_APP_STATUS: (state, action) => {
    state.isLoading = false

    let updatedAccountAppAll = state.forAllAccounts.find(
      x => x.tempId === action.data.tempId
    )
    Object.assign(updatedAccountAppAll, { id: action.data.id, isDefaultForAccount: action.data.isDefaultForAccount })

    let updatedAccountAppCurrent = state.forCurrentAccount.find(
      x => x.tempId === action.data.tempId
    )
    if (updatedAccountAppCurrent !== undefined) {
      Object.assign(updatedAccountAppCurrent, { id: action.data.id, isDefaultForAccount: action.data.isDefaultForAccount })
    }
    
    if (state.currentAccountApp === null) {
      state.currentAccountApp = updatedAccountAppCurrent
      state.currentAccountAppId = updatedAccountAppCurrent.id
    } else if (state.currentAccountApp.id === action.data.id || state.currentAccountApp.id === action.data.tempId) {
      Object.assign(state.currentAccountApp, { id: action.data.id, isDefaultForAccount: action.data.isDefaultForAccount })
    } else if (action.switchAfterAdd === true) {
      Object.assign(state.currentAccountApp, action.data)
      state.currentAccountAppId = action.data.id
    }
  },
  DELETING_ACCOUNT_APP_STATUS: (state, action) => {
    state.isLoading = true
  },
  DELETED_ACCOUNT_APP_STATUS: (state, action) => {
    state.isLoading = false

    const deletedAccountApp = state.forAllAccounts.find(
      x => x.id === action.deletedAccountAppId
    )

    if (state.currentAccountApp !== null && state.currentAccountApp.accountId === deletedAccountApp.accountId) {
      state.forCurrentAccount = action.data
    }

    let newForAllAccounts = []
    state.forAllAccounts.forEach(accountApp => {
      if (accountApp.accountId !== deletedAccountApp.accountId) {
        newForAllAccounts.push(accountApp)
      }
    })
    state.forAllAccounts = newForAllAccounts.concat(action.data)

    if (state.currentAccountAppId !== null && state.currentAccountAppId === deletedAccountApp.id) {
      state.currentAccountApp = state.forAllAccounts.find(
        x => x.accountId === deletedAccountApp.accountId && x.isDefaultForAccount === true
      )
      state.currentAccountAppId = state.currentAccountApp.id
    }
  }
})

export default accountApps