import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { updateAccount, loadAllApps, checkGAStatus, loadGAProperties, addAccountApp } from '../../actions/actions'
import 'url-search-params-polyfill'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Toolbar from '@material-ui/core/Toolbar'
import AddAccountApp from '../../components/accountApps/AddAccountApp'
import { Enums } from 'utils'
import { Link } from 'react-router-dom'


class SetupContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectAppsId: null
    }
    this.onAdd = this.onAdd.bind(this)
  }

  componentDidMount() {
    if (this.props.apps.all.data === null && this.props.apps.all.isLoading === false) {
      if(this.state.selectAppsId == null){
        this.props.dispatch(loadAllApps())
      }
    }

    let query = new URLSearchParams(this.props.location.search)
    let returning = query.get('returning')
    if (returning) {
      this.setState({
        selectAppsId: query.get('apps_id')
      }, () => {
        this.props.dispatch(updateAccount( 
          query.get('accountId'),
          query.get('googleAuthEmail'),
          query.get('googleAuthToken'),
          query.get('googleAuthRefreshToken'),
          null,
          null,

        ))
        this.props.history.replace(this.props.location.pathname)
      })
    } else {
      this.props.dispatch(checkGAStatus(this.props.accounts.currentAccountId))
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.googleAnalytics.status === Enums.GAStatus.Authed && prevProps.googleAnalytics.status !== Enums.GAStatus.Authed) {
      // just checked auth
      this.props.dispatch(loadGAProperties(this.props.accounts.currentAccountId))
    }
  }

  onAdd(appsId, competitor, googleAnalyticsPropertyGA4, url, name) {
    const appType = competitor === true ? Enums.AppType.Competitor : Enums.AppType.Own
    this.props.dispatch(addAccountApp(
        this.props.accounts.currentAccountId,
        appsId,
        appType,
        null,
        googleAnalyticsPropertyGA4,
        null,
        null,
        url,
        name
    ))
  }

  render() {
    let title = null
    let control = null

    const nonQuickLookupApps = this.props.accountApps.forCurrentAccount.filter(x => x.appType !== Enums.AppType.Lookup)

    if (nonQuickLookupApps === null || nonQuickLookupApps === undefined || nonQuickLookupApps.length < 1) {
      // not loaded or loaded and none
      title = (
        <React.Fragment>
          <p>Welcome! You can track both your own apps and competitors in App Store Analytics</p>
          <p><strong>Add one of your apps</strong> to start<br/>(you can add more later)</p>
        </React.Fragment>
      )
      if (this.props.apps.all.data == null) {
        let loading_message = null
        if(this.state.selectAppsId == null){
          loading_message = 'One moment, we’re just fetching a list of all the apps on the app store'
        }else{
          loading_message = 'One moment, We are fetching properties from google analytics'
        }
        control =(
          <Card style={{ width: '309px', height: '309px' }} elevation={3}>
            <CardHeader style={{ alignItems: 'initial', overflowWrap: 'anywhere', textAlign: 'center' }} title={ <Typography variant='h6'>{loading_message}</Typography> }/>
          </Card>
        )
      }
      if (this.props.apps.all.data !== null) {
        control = (
          <div style={{ minWidth: 320, maxWidth: 500 }}>
            <AddAccountApp
              key='addownapp'
              adding={true}
              canCancel={false}
              onAdded={this.onAdd}
              properties={this.props.googleAnalytics.properties}
              isCompetitor={false}
              disabled={false}
              allApps={this.props.apps.all.data || []}
              currentAccountId={this.props.accounts.currentAccountId}
              gaStatus={this.props.googleAnalytics.status}
              selectAppsId={this.state.selectAppsId}
              />
          </div>
        )
      }
    } else {
      title = (
        <React.Fragment>
          <p>Added. Analytics are now loading up for your app,<br/>{nonQuickLookupApps[0].name}</p>
        </React.Fragment>
      )
      control = (
        <Grid container direction='column' justify='center' alignItems='center'>
            <Typography variant='h5' gutterBottom><strong>What's next?</strong></Typography>
            <Button component={Link} to='/l/team' color='secondary' variant='contained' style={{ marginBottom: 11, width: 230 }}>Invite team members</Button>
            <Button component={Link} to='/l/home' color='secondary' variant='contained' style={{ marginBottom: 11, width: 230 }}>Go to Dashboard</Button>
            <p><strong>In around 15 minutes</strong> app listing traffic analytics will be available</p>
        </Grid>
      )
    }

    return (
      <React.Fragment>
        <AppBar>
          <Toolbar style={{ paddingLeft: 18 }}>
            <img style={{ width: 185 }} alt="App Store Analytics" src="/assets/images/logo-white.png"/>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <Container style={{ marginTop: 20 }}>
          <Grid container spacing={3} direction='column' justify='center' alignItems='center'>
            <Grid item xs={12}>
              <Typography variant='h5' style={{ textAlign: 'center', maxWidth: 580 }}>{title}</Typography>
            </Grid>
            <Grid item xs={12}>
              {control}
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { team, accounts, accountApps, googleAnalytics, apps } = state

  return { team, accounts, accountApps, googleAnalytics, apps }
}

SetupContainer.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(SetupContainer)