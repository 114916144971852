import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { resetPassword } from '../../actions/actions'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import 'url-search-params-polyfill'

class ResetPasswordContainer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      password: '',
      resetToken: null
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
  }

  componentDidMount() {
    let query = new URLSearchParams(this.props.location.search)
    let resetToken = query.get('t')
    if (resetToken) {
      this.props.history.replace(this.props.location.pathname)
      this.setState({ resetToken })
    }
  }

  onInputChange(event) {
    let newState = {}
    newState[event.target.name] = event.target.value
    newState.errors = {}
    this.setState(newState)
  }

  onSubmit(event) {
    event.preventDefault()
    event.stopPropagation()
    this.props.dispatch(resetPassword(this.state.resetToken, this.state.password))
  }

  render() {
    let dialogBody = null
    if (this.state.resetToken === null || this.props.passwordReset.resetPasswordValidToken === false) {
      dialogBody = (
        <DialogContent dividers>
          <Typography variant="h5">
            Reset Password
          </Typography>
          <Typography variant="body1" gutterBottom>
            Error: The reset password link is invalid or has expired. Please try following the link again or <Link to='/request-password-reset'>request another password reset email</Link>.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Email hello@appastoreanalytics.io if you continue to have issues.
          </Typography>
        </DialogContent>
      )
    } else if (this.props.passwordReset.resetPassword === true) {
      dialogBody = (
        <DialogContent dividers>
          <Typography variant="h5">
            Reset Password
          </Typography>
          <Typography variant="body1" gutterBottom>
            Your password has been reset. You can now <Link to='/sign-in'>sign in with your new password</Link>.
          </Typography>
        </DialogContent>
      )
    } else {
      dialogBody = (
        <form onSubmit={this.onSubmit}>
          <DialogContent dividers>
            <Typography variant="h5">
              Reset Password
            </Typography>
            <Typography variant="body1" gutterBottom>
              Choose a password
            </Typography>
            <div>
              <TextField 
                autoFocus
                value={this.state.password}
                disabled={this.props.passwordReset.resettingPassword === true}
                ref='SignUpPassword'
                name='password'
                type='password'
                label='Password'
                onChange={this.onInputChange}
                fullWidth={true}
                margin='normal'
                required={true}
                error={this.props.passwordReset.resetPasswordErrors !== null}
                helperText={this.props.passwordReset.resetPasswordErrors}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' disabled={this.props.passwordReset.resettingPassword === true} color='primary' type='submit'>
              RESET PASSWORD
            </Button>
          </DialogActions>
        </form>
      )
    }

    return (
      <Dialog open={true} disableBackdropClick={true} transitionDuration={0} disableEnforceFocus>
        <DialogTitle disableTypography={true}>
          <img style={{ height: '35px', width: 'auto' }} alt="App Store Analytics" src="/assets/images/logo.png"/>
        </DialogTitle>
        {dialogBody}
      </Dialog>
    )
  }
}

function mapStateToProps(state) {
  const { passwordReset } = state

  return { passwordReset }
}

ResetPasswordContainer.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(ResetPasswordContainer)