import PropTypes from 'prop-types'
import 'url-search-params-polyfill'
import Typography from '@material-ui/core/Typography'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import moment from 'moment'
import 'url-search-params-polyfill'
import Box from '@material-ui/core/Box'
import VersionTimelineItem from './VersionTimelineItem'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'

class VersionTimeline extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    let timeline = null
    if (this.props.changes.length > 0) {
      const timelineItems = this.props.changes.map((change, index) =>
        <TimelineItem key={change.changeDate}>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color={ index === 0 ? 'primary' : 'grey' } />
            { index === this.props.changes.length - 1 ? null : <TimelineConnector /> }
          </TimelineSeparator>
          <TimelineContent style={{ minWidth: 350 }}>
            <VersionTimelineItem
              change={change}
              currentVersion={index === 0}
              firstVersion={index === this.props.changes.length - 1}
              ownApp={this.props.ownApp}
              onChangeDateClick={this.props.onChangeDateClick} />
          </TimelineContent>
        </TimelineItem>
      )
      timeline = (
        <Box style={{ maxWidth: 380 }}>
          <Timeline>
            {timelineItems}
          </Timeline>
        </Box>
      )
    } else {
      timeline =
        <React.Fragment>
          <Card style={{ width: 400, marginLeft: 100, marginTop: 30 }}>
            <CardContent>
              <Typography>
                {moment().format('DD MMMM YYYY')}
                {
                  <Chip size="medium" label="We are now tracking this app." style={{ marginLeft: 7 }} />
                }
              </Typography>
            </CardContent>
          </Card>
        </React.Fragment>
        // <Typography>
        //   No app listing change history found. If your listing has just been published, wait a day and then check again. Email <a href='mailto:help@appstoreanalytics.io'>help@appstoreanalytics.io</a> if that's not the case.
        // </Typography>
    }

    return timeline
  }
}

VersionTimeline.propTypes = {
  changes: PropTypes.array.isRequired,
  onChangeDateClick: PropTypes.func.isRequired,
  ownApp: PropTypes.bool.isRequired
}

export default VersionTimeline