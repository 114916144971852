import { connect } from 'react-redux'
import {updateAccount} from '../../actions/actions'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import SubAppBar from '../../components/navigation/SubAppBar'
import TextField from '@material-ui/core/TextField'
import React from 'react'
import { Link } from 'react-router-dom'

class PartnerApiContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      partnerApiOrganizationId: props.accounts.currentAccount.partnerApiOrganizationId,
      partnerApiAccessToken: props.accounts.currentAccount.partnerApiAccessToken,
      credentialsSavedSnackbarOpen: false
    }
    this.onSave = this.onSave.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.accounts.isLoading === true && this.props.accounts.isLoading === false && this.state.credentialsSavedSnackbarOpen === false) {
      this.setState({ credentialsSavedSnackbarOpen: true })
    }
  }

  onSave() {
    this.props.dispatch(updateAccount(
        this.props.accounts.currentAccount.id,
        null,
        null,
        null,
        this.state.partnerApiOrganizationId,
        this.state.partnerApiAccessToken
    ))
  }

  render() {
    return (
      <React.Fragment>
        <SubAppBar title='Partner API' />
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                If <Link to='/l/billing'>your plan</Link> includes Attribution, to see installed shops and revenue data enter your <a href="https://shopify.dev/docs/api/partner#authentication" target="_blank">Shopify Partner API credentials</a>.
              </Typography>
              <Typography>
                The API client you create should have 'View financials' and 'Manage apps' permissions.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} md={4}>
              <TextField
                value={this.state.partnerApiOrganizationId}
                label='Organization ID'
                onChange={ (event) => this.setState({ partnerApiOrganizationId: event.target.value }) }
                required={true}
                fullWidth={true}
                disabled={this.props.accounts.isLoading}
            />
                </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} md={4}>
              <TextField
                value={this.state.partnerApiAccessToken}
                label='Access Token'
                onChange={ (event) => this.setState({ partnerApiAccessToken: event.target.value }) }
                required={true}
                fullWidth={true}
                disabled={this.props.accounts.isLoading}
            />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" type='submit' color='secondary'
                      onClick={this.onSave} disabled={this.props.accounts.isLoading}>
                Save
              </Button>
            </Grid>
          </Grid>
          <Snackbar
              open={this.state.credentialsSavedSnackbarOpen}
              autoHideDuration={6000}
              onClose={() => this.setState({ credentialsSavedSnackbarOpen: false })}
              message="Credentials saved"
          />
        </Container>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { accounts } = state

  return { accounts }
}

export default connect(mapStateToProps)(PartnerApiContainer)