import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import 'url-search-params-polyfill'
import { switchAccountApp, searchApps, addAccountApp, loadAllApps } from '../../actions/actions'
import { Enums } from 'utils'
import SubAppBar from '../../components/navigation/SubAppBar'
import AppSearch from '../../components/apps/AppSearch'
import Typography from '@material-ui/core/Typography'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import moment from 'moment'

class CurrentAppBaseContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentAccountApp: null,
      appDoesntExist: null
    }
    this.renderChildren = this.renderChildren.bind(this)
    this.switchOrAddApp = this.switchOrAddApp.bind(this)
    this.renderQuickSearch = this.renderQuickSearch.bind(this)
    this.onQuickLookup = this.onQuickLookup.bind(this)
  }

  componentDidMount() {
    this.switchOrAddApp()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.appUrlPath !== prevProps.match.params.appUrlPath) {
      this.switchOrAddApp()
    } else if (this.props.apps.search.isLoading === false && prevProps.apps.search.isLoading === true) {
      this.switchOrAddApp()
    }

    if (this.state.appDoesntExist === true && prevState.appDoesntExist !== this.state.appDoesntExist && this.props.apps.all.data === null && this.props.apps.all.isLoading === false) {
      this.props.dispatch(loadAllApps())
    }
  }

  onQuickLookup(e, selectedApp) {
    this.props.history.push(`/l/apps/${selectedApp.url.replace('https://','').replace('http://', '').replace('apps.shopify.com/', '')}/listing-history`)
  }

  switchOrAddApp() {
    const appUrlPath = this.props.match.params.appUrlPath
    const currentAccountApp = this.props.accountApps.forCurrentAccount.find(
      x => x.urlPath === appUrlPath
    )
    if (currentAccountApp !== undefined) {
      // we have it in our accountApps
      this.setState({ appDoesntExist: false })
      this.props.dispatch(switchAccountApp(currentAccountApp.id))
    } else if (this.props.apps.search.appUrlPath !== appUrlPath) {
      // we have it in our accountApps and haven't started searching for it
      this.setState({ appDoesntExist: null })
      this.props.dispatch(searchApps(appUrlPath))
    } else {
      // we have it in our accountApps, have searched / are searching for it, may or may not have found it
      if (this.props.apps.search.isLoading === true) {
        this.setState({ appDoesntExist: null })
        return
      } else {
        if (this.props.apps.search.data !== null && this.props.apps.search.data.length > 0) {
          this.setState({ appDoesntExist: false })
          const result = this.props.apps.search.data[0]
          this.props.dispatch(addAccountApp(
              this.props.accounts.currentAccountId,
              result.apps_id,
              Enums.AppType.Lookup,
              null,
              null,
              null,
              null,
              result.url,
              result.app_name,
              true))
        } else {
          this.setState({ appDoesntExist: true })
        }
      }
    }
  }
  
  render() {
    if (this.state.appDoesntExist === true) {
      return this.renderQuickSearch()
    } else if (this.props.accountApps.currentAccountApp === null || this.props.accountApps.currentAccountApp.urlPath !== this.props.match.params.appUrlPath) {
      return (
        <SubAppBar title='' />
      )
    } else {
      return this.renderChildren()
    }
  }

  renderChildren() {
    if (this.props.children !== undefined) {
      return this.props.children
    } else {
      return null
    }
  }

  renderQuickSearch() {
    return (
      <React.Fragment>
        <Card style={{ width: 400, marginLeft: 100, marginTop: 30 }}>
          <CardContent>
            <Typography>
              {moment().format('DD MMMM YYYY')}
              {
                <Chip size="medium" label="We are now tracking this app." style={{ marginLeft: 7 }} />
              }
            </Typography>
          </CardContent>
        </Card>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { accounts, accountApps, apps } = state

  return { accounts, accountApps, apps }
}

CurrentAppBaseContainer.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(CurrentAppBaseContainer)