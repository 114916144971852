import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import AppSearch from '../apps/AppSearch'
import { ApiUtils, Enums } from 'utils'
import { loadAllApps } from '../../actions/actions'

import Avatar from '@material-ui/core/Avatar'
import HistoryIcon from '@material-ui/icons/History'
import HomeIcon from '@material-ui/icons/Home'
import EditIcon from '@material-ui/icons/Edit'
import LinearProgress from '@material-ui/core/LinearProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import InsertChartIcon from '@material-ui/icons/InsertChart';
import ShowChartIcon from '@material-ui/icons/ShowChart'
import StarHalfIcon from '@material-ui/icons/StarHalf'
import Typography from '@material-ui/core/Typography'

class AccountAppsMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.onQuickLookup = this.onQuickLookup.bind(this)
    this.renderQuickLookup = this.renderQuickLookup.bind(this)
  }

  componentDidMount() {
    if (
      this.props.apps.all.data === null &&
      this.props.apps.all.isLoading === false
    ) {
      this.props.dispatch(loadAllApps())
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.apps.all.data === null &&
      this.props.apps.all.isLoading === false
    ) {
      this.props.dispatch(loadAllApps())
    }
  }

  onQuickLookup(e, selectedApp) {
    this.props.history.push(
      `/l/apps/${selectedApp.url
        .replace('https://', '')
        .replace('http://', '')
        .replace('apps.shopify.com/', '')}/listing-history`
    )
    this.props.onNavigate()
  }

  render() {
    let accountAppsMenuOwnApps = null
    let accountAppsMenuCompetitors = null
    let accountAppsMenuQuickLookups = null

    accountAppsMenuOwnApps =
      this.props.user.anonymous !== true
        ? [<ListSubheader key='aamoaSubA'>Own Apps</ListSubheader>]
        : []
    accountAppsMenuCompetitors =
      this.props.user.anonymous !== true
        ? [<ListSubheader key='aamoaSubC'>Competitors</ListSubheader>]
        : []
    accountAppsMenuQuickLookups = []
    if (
      this.props.accountApps.forCurrentAccount &&
      this.props.accountApps.forCurrentAccount.length > 0
    ) {
      this.props.accountApps.forCurrentAccount.forEach((accountApp) => {
        const target =
          accountApp.appType === Enums.AppType.Competitor
            ? accountAppsMenuCompetitors
            : accountApp.appType === Enums.AppType.Own
            ? accountAppsMenuOwnApps
            : accountAppsMenuQuickLookups

        target.push(this.renderItem(accountApp))
      })
    }

    return (
      <List dense key='accountAppsMenu'>
        <ListItem
          button
          key='accountAppsMenuHome'
          component={Link}
          to='/l/home'
          onClick={this.props.onNavigate}
          selected={this.props.location.pathname === '/l/home'}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary='Home' style={{ marginLeft: '.75rem' }} />
        </ListItem>
        {accountAppsMenuOwnApps}
        {accountAppsMenuCompetitors}
        {this.renderQuickLookup()}
        {accountAppsMenuQuickLookups}
      </List>
    )
  }

  /*
                       <ListItem style={{ paddingLeft: 38 }} key='sapKey' button component={Link} to={`/l/apps/${accountApp.urlPath}/keywords`} onClick={this.props.onNavigate} selected={this.props.location.pathname.endsWith('/keywords') === true}>
              <ListItemIcon style={{ minWidth: 42 }}>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText primary='Keywords' />
            </ListItem>

                 <ListItem style={{ paddingLeft: 38 }} key='sapCate' button component={Link} to={`/l/apps/${accountApp.urlPath}/positions`} onClick={this.props.onNavigate} selected={this.props.location.pathname.endsWith('/positions') === true}>
            <ListItemIcon style={{ minWidth: 42 }}>
              <FormatListNumberedIcon />
            </ListItemIcon>
            <ListItemText primary='Positions' />
          </ListItem>
*/

  renderItem(accountApp) {
    const isCurrentApp =
      this.props.accountApps.currentAccountApp !== null &&
      this.props.accountApps.currentAccountAppId === accountApp.id &&
      this.props.location.pathname.startsWith('/l/apps') === true;

    let primaryText =
      accountApp.name === accountApp.url ? accountApp.url.replace('https://','').replace('http://', '').replace('apps.shopify.com/', '').replace('-', ' ').toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ') : accountApp.name;

    let collapse = null;
    if (isCurrentApp === true) {
      let appListingTraffic = null;
      if (
        this.props.accountApps.currentAccountApp.appType === Enums.AppType.Own
      ) {
        appListingTraffic = (
          <React.Fragment>
            <ListItem
              style={{ paddingLeft: 60 }}
              key='sapDash'
              button
              component={Link}
              to={`/l/apps/${accountApp.urlPath}/listing-traffic`}
              onClick={this.props.onNavigate}
              selected={
                this.props.location.pathname.endsWith('/listing-traffic') ===
                true
              }
            >
              <ListItemIcon>
                <InsertChartIcon />
              </ListItemIcon>
              <ListItemText
                primary='Analytics'
                style={{ marginLeft: '.75rem' }}
              />
            </ListItem>
            <ListItem
              style={{ paddingLeft: 60, display: 'none' }}
              key='sapPositions'
              button
              component={Link}
              to={`/l/apps/${accountApp.urlPath}/positions`}
              onClick={this.props.onNavigate}
              selected={
                this.props.location.pathname.endsWith('/positions') ===
                true
              }
            >
              <ListItemIcon>
                <ShowChartIcon />
              </ListItemIcon>
              <ListItemText
                primary='Rankings'
                style={{ marginLeft: '.75rem' }}
              />
            </ListItem>
            <ListItem
              style={{ paddingLeft: 60, display: 'none' }}
              key='sapEdit'
              button
              component={Link}
              to={`/l/apps/${accountApp.urlPath}/listing-editor`}
              onClick={this.props.onNavigate}
              selected={
                this.props.location.pathname.endsWith('/listing-editor') ===
                true
              }
            >
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText
                primary='App Listing Editor'
                style={{ marginLeft: '.75rem' }}
              />
            </ListItem>
          </React.Fragment>
        )
      }
      collapse = (
        <List component='div' disablePadding dense>
          {appListingTraffic}
          <ListItem
            style={{ paddingLeft: 60 }}
            key='sapHist'
            button
            component={Link}
            to={`/l/apps/${accountApp.urlPath}/listing-history`}
            onClick={this.props.onNavigate}
            selected={
              this.props.location.pathname.endsWith('/listing-history') === true
            }
          >
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText
              primary='Changelog'
              style={{ marginLeft: '.75rem' }}
            />
          </ListItem>
          <ListItem
            style={{ paddingLeft: 60, display: 'none' }}
            key='sapRevi'
            button
            component={Link}
            to={`/l/apps/${accountApp.urlPath}/reviews`}
            onClick={this.props.onNavigate}
            selected={
              this.props.location.pathname.endsWith('/reviews') === true
            }
          >
            <ListItemIcon>
              <StarHalfIcon />
            </ListItemIcon>
            <ListItemText primary='Reviews' style={{ marginLeft: '.75rem' }} />
          </ListItem>
        </List>
      );
    }

    const avatarStyle =
      isCurrentApp === true
        ? {}
        : {
            WebkitFilter: 'grayscale(1)  opacity(60%)',
            filter: 'grayscale(1) opacity(60%)',
            filter: 'gray',
          };
    const linkTo =
      accountApp.appType === Enums.AppType.Own
        ? `/l/apps/${accountApp.urlPath}/listing-traffic`
        : `/l/apps/${accountApp.urlPath}/listing-history`;

    return (
      <React.Fragment key={`renderItem${accountApp.id}`}>
        <ListItem
          button
          component={Link}
          to={linkTo}
          onClick={this.props.onNavigate}
        >
          <ListItemAvatar>
            <Avatar
              alt={accountApp.urlPath}
              variant='rounded'
              style={{ ...avatarStyle, width: 34, height: 34 }}
              src={ApiUtils.apiUrl(
                `/app_listing/icon?path=${accountApp.urlPath}`
              )}
            />
          </ListItemAvatar>
          <ListItemText
            primary={primaryText}
            style={{ marginLeft: '.75rem' }}
            primaryTypographyProps={{
              style: {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: '100%',
              },
            }}
          />
          {this.props.accountApps.currentAccountAppId === accountApp.id
            ? null
            : null}
        </ListItem>
        {collapse}
      </React.Fragment>
    );
  }

  renderQuickLookup() {
    let limit = null;
    if (this.props.accounts.currentAccount.quickLookupsLimit < 999999) {
      // is the magic number
      let lookupsDone = this.props.accountApps.forCurrentAccount.filter(
        (x) => x.appType === Enums.AppType.Lookup
      );
      lookupsDone = lookupsDone === undefined ? 0 : lookupsDone.length;
      const percentDone =
        (lookupsDone / this.props.accounts.currentAccount.quickLookupsLimit) *
        100;
      const message =
        lookupsDone >= this.props.accounts.currentAccount.quickLookupsLimit
          ? 'No lookups remaining'
          : `${lookupsDone} of ${this.props.accounts.currentAccount.quickLookupsLimit} used`;

      const cta =
        this.props.user.anonymous === true ? (
          <React.Fragment>
            <Link to='/sign-up'>Start a free trial</Link> for more
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Link to='/l/billing'>Choose a plan</Link> for more
          </React.Fragment>
        );

      limit = (
        <React.Fragment key='aamQLL'>
          <Typography style={{ textAlign: 'center', marginTop: 16 }}>
            {this.props.accounts.currentAccount.quickLookupsLimit} Quick
            Lookups/day free
            <br />
            {cta}
          </Typography>
          <LinearProgress
            variant='determinate'
            value={percentDone}
            color='secondary'
            style={{ height: 7, margin: '16px 16px 6px 16px' }}
          />
          <Typography
            variant='subtitle2'
            style={{ textAlign: 'center', marginBottom: 16 }}
          >
            {message}
          </Typography>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <ListSubheader key='aamQL'>Quick Lookup</ListSubheader>
        <ListItem key='littleAppSearch'>
          <AppSearch
            isLoading={
              this.props.apps.all.isLoading === true ||
              this.props.apps.all.data === null
            }
            allApps={this.props.apps.all.data}
            onChange={this.onQuickLookup}
            appType={Enums.AppType.Lookup}
          />
        </ListItem>
        {!this.props.accounts.currentAccount.isMentalUserAccount && limit}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, accounts, accountApps, apps } = state

  return { user, accounts, accountApps, apps }
}

AccountAppsMenu.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(AccountAppsMenu)