import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import SubAppBar from '../../components/navigation/SubAppBar'
import {
  loadRankings,
  showHelp
} from '../../actions/actions'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import PositionsChart from '../../components/positions/PositionsChart'
import ToFromDatesChip from '../../components/googleAnalytics/ToFromDatesChip'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import Chip from '@material-ui/core/Chip'
import PositionCompetitorDropdown from '../../components/positions/PositionCompetitorDropdown'

const styles = theme => ({
  rangeFilter: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(.5),
    },
  }
})

class PositionsContainer extends React.Component {
  constructor(props) {
    super(props)
    // keyword ranking can be loaded immediately when the user adds a new keyword
    this.state = {
      selectedFromDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
      selectedToDate: moment().format('YYYY-MM-DD'),
      selectedTimeGrouping: 'Week',
      categoryData: [],
      collectionData: [],
      searchData: [],
    }
  }

  componentDidMount() {
    // TODO: check if we already have the data
    this.props.dispatch(loadRankings(this.props.accountApps.currentAccountAppId, 'category', this.state.selectedFromDate, this.state.selectedToDate, this.state.selectedTimeGrouping))
    this.props.dispatch(loadRankings(this.props.accountApps.currentAccountAppId, 'collection', this.state.selectedFromDate, this.state.selectedToDate, this.state.selectedTimeGrouping))
    this.props.dispatch(loadRankings(this.props.accountApps.currentAccountAppId, 'search', this.state.selectedFromDate, this.state.selectedToDate, this.state.selectedTimeGrouping))
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedFromDate !== this.state.selectedFromDate || prevState.selectedToDate !== this.state.selectedToDate || prevState.selectedTimeGrouping !== this.state.selectedTimeGrouping) {
      this.props.dispatch(loadRankings(this.props.accountApps.currentAccountAppId, 'category', this.state.selectedFromDate, this.state.selectedToDate, this.state.selectedTimeGrouping))
      this.props.dispatch(loadRankings(this.props.accountApps.currentAccountAppId, 'collection', this.state.selectedFromDate, this.state.selectedToDate, this.state.selectedTimeGrouping))
      this.props.dispatch(loadRankings(this.props.accountApps.currentAccountAppId, 'search', this.state.selectedFromDate, this.state.selectedToDate, this.state.selectedTimeGrouping))
    }
    
    if(prevProps.rankings !== this.props.rankings){
      this.populateCategoryData();
      this.populateCollectionData();
      this.populateSearchData();
    }
  }

  populateCategoryData() {
    let combinedCategoryData = [];
    const categoryReport = this.props.rankings.reports.find(x => x.surfaceType === 'category')
      if(this.props.rankings.competitorReports?.length) {
        const competitorCategoryReport = this.props.rankings.competitorReports.find(x => x.surfaceType === 'category')
        combinedCategoryData = categoryReport.data?.length ? categoryReport.data.concat(competitorCategoryReport.data) : competitorCategoryReport.data
      }
      this.setState({ categoryData: combinedCategoryData?.length ? combinedCategoryData : categoryReport.data })
  }

  populateCollectionData() {
    let combinedCollectionData = [];
    const collectionReport = this.props.rankings.reports.find(x => x.surfaceType === 'collection')
    if(this.props.rankings.competitorReports?.length) {
      const competitorCollectionReport = this.props.rankings.competitorReports.find(x => x.surfaceType === 'collection')
      combinedCollectionData = collectionReport.data?.length ? collectionReport.data.concat(competitorCollectionReport.data) : competitorCollectionReport.data
    }
    this.setState({ collectionData: combinedCollectionData?.length ? combinedCollectionData : collectionReport.data })
  }

  populateSearchData() {
    let combinedSearchData = [];
    const searchReport = this.props.rankings.reports.find(x => x.surfaceType === 'search')
    if(this.props.rankings.competitorReports?.length) {
      const competitorSearchReport = this.props.rankings.competitorReports.find(x => x.surfaceType === 'search')
      combinedSearchData = searchReport.data?.length ? searchReport.data.concat(competitorSearchReport.data) : competitorSearchReport.data
    }
    this.setState({ searchData: combinedSearchData?.length ? combinedSearchData : searchReport.data })
  }

  render() {
    const { classes } = this.props

    let categoryChart = null
    let collectionChart = null
    let searchChart = null
    if (this.props.rankings.reports !== undefined && this.props.rankings.reports.length > 0) {
      
      if (this.state.categoryData) {
        categoryChart = (
          <PositionsChart
            title='Categories'
            data={this.state.categoryData}
            onShowHelp={
              () => {
                this.props.dispatch(showHelp(
                  'Categories',
                  (
                    <React.Fragment>
                      <Typography gutterBottom>
                        Every app is listed in one or more categories. Categories are used by merchants when they browse the app store and as a filter for keyword search.
                      </Typography>
                      <Typography gutterBottom>
                        The categories an app is listed in are initially chosen by Shopify. You can request them to be changed through your partner admin support. This may be a good idea if you can appear at a higher position in another category, or if there's a more suitable category for what you app offers.
                      </Typography>
                      <Typography gutterBottom>
                        You can also request additional categories. Evidence suggests that the more categories you're in, the harder it is to achieve a high position in all of them. It appears that the ranking algorithm takes into account how many categories you're listed in, and your install rate per category.
                      </Typography>
                      <Typography gutterBottom>
                        Data shown here is for the default sort order of 'most relevant'.
                      </Typography>
                    </React.Fragment>
                  )
                ))
              }
            } />
        )
      }
      
      if (this.state.collectionData) {
        collectionChart = (
          <PositionsChart
            title='Collections'
            data={this.state.collectionData}
            onShowHelp={
              () => {
                this.props.dispatch(showHelp(
                  'Collections',
                  (
                    <React.Fragment>
                      <Typography gutterBottom>
                        An app can be in zero or many collections. Collections are curated around a theme like 'Build your customer base' and 'Launching your store', along with features like the four apps featured on the homepage, 'Trending apps', and 'New and noteworthy'.
                      </Typography>
                      <Typography gutterBottom>
                        All collections are curated by Shopify, helped by internal data like apps gaining in popularity and noteable install rates. The criteria to be listed in each collection is different, as is how long you can expect to be listed there.
                      </Typography>
                      <Typography gutterBottom>
                        While you can request a collection listing from Shopify, the most successful apps are a bit smarter than this. <a href='https://www.appstoreanalytics.io/post/everywhere-your-app-can-be-featured-on-shopify' target='_blank'>Read more about how to be picked for a collection</a>.
                      </Typography>
                      <Typography gutterBottom>
                        Because they're curated, it's a small stamp of approval from Shopify if an app is currently listed in collections or has been in a 'trending' collection.
                      </Typography>
                    </React.Fragment>
                  )
                ))
              }
            } />
        )
      }
/*

actionButton={
              <Button color='secondary' variant='outlined' component={Link} to='./keywords'>
                Target More Keywords
              </Button>
            }

*/
      if (this.state.searchData) {
        searchChart = (
          <PositionsChart
            title='Keyword Search'
            data={this.state.searchData}
            onShowHelp={
              () => {
                this.props.dispatch(showHelp(
                  'Keyword Search',
                  (
                    <React.Fragment>
                      <Typography gutterBottom>
                        Every app appears in keyword search. Keyword search is the most popular method for merchants to navigate the app store.
                      </Typography>
                      <Typography gutterBottom>
                        App store search is more case-sensitive and less tolerant of keyword variations and misspellings than Google search. There are also relatively few keywords that account for the majority of searches, around 1,500.
                      </Typography>
                      <Typography gutterBottom>
                        Including a keyword in your app name and tagline carries the most ranking weight, followed by the rest of the app listing text.
                      </Typography>
                      <Typography gutterBottom>
                        An apps position for a keyword is determined by how much that keyword is used in the app listing text and how 'popular' an app is. This popularity can be roughly worked out by sorting the 'all' collection by 'most relevant'. App A that ranks higher here than App B will outrank it for keyword search if they both use the keyword the same amount of times in their app listing text.
                      </Typography>
                      <Typography gutterBottom>
                        Updates to an apps keyword positions are available within a few minutes of publishing an updated app listing. There is no crawl and reindexing delay like Google search.
                      </Typography>
                    </React.Fragment>
                  )
                ))
              }
            }
             />
        )
      }
      searchChart = null // keyword positions don't appear to be rendering, removing it for now
    }

    return (
      <React.Fragment>
        <SubAppBar title='Positions' />
        <Container>
          <Grid container spacing={2}>
            <Grid container>
              <Grid item xs={6}>
                <ToFromDatesChip
                  compare={false}
                  selectedFromDate={this.state.selectedFromDate}
                  selectedToDate={this.state.selectedToDate}
                  onToFromDatesSelected={(selectedToDate, selectedFromDate) => { this.setState({ selectedToDate, selectedFromDate }) }}
                />
              </Grid>
              <Grid item xs={6}>
                <PositionCompetitorDropdown 
                  fromDate={this.state.selectedFromDate}
                  toDate={this.state.selectedToDate}
                  groupBy={this.state.selectedTimeGrouping}
                  currentAccountAppId={this.props.accountApps.currentAccountAppId}
                  />
              </Grid>
            </Grid>
            <Grid item md={12}>
              <div className={classes.rangeFilter}>
                <Chip label="Day"
                  size="small"
                  color={this.state.selectedTimeGrouping === 'Day' ? 'primary' : 'default'}
                  onClick={() =>  this.setState({ selectedTimeGrouping: 'Day' })} />
                <Chip label="Week"
                  size="small"
                  color={this.state.selectedTimeGrouping === 'Week' ? 'primary' : 'default'}
                  onClick={() =>  this.setState({ selectedTimeGrouping: 'Week' })} />
                <Chip label="Month"
                  size="small"
                  color={this.state.selectedTimeGrouping === 'Month' ? 'primary' : 'default'}
                  onClick={() =>  this.setState({ selectedTimeGrouping: 'Month' })} />
              </div>
            </Grid>
            <Grid item md={12}>
              {categoryChart}
            </Grid>
            <Grid item md={12}>
              {collectionChart}
            </Grid>
            <Grid item md={12}>
              {searchChart}
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { rankings, accountApps } = state

  return { rankings, accountApps }
}

export default connect(mapStateToProps)(withStyles(styles)(PositionsContainer))