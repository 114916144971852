import React from 'react'
import PropTypes from 'prop-types'
import { Enums } from 'utils'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { showBanner, hideBanner } from '../../actions/actions'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'

class SelectGoogleAccount extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        
    }
    this.showNotAuthedBanner = this.showNotAuthedBanner.bind(this)
  }

  componentDidMount() {
    if (this.props.gaStatus === Enums.GAStatus.NotAuthed || this.props.gaStatus === Enums.GAStatus.PermissionsError || this.props.gaStatus === Enums.GAStatus.UnknownError) {
        this.showNotAuthedBanner()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.props.gaStatus === Enums.GAStatus.NotAuthed || this.props.gaStatus === Enums.GAStatus.PermissionsError || this.props.gaStatus === Enums.GAStatus.UnknownError) && this.props.gaStatus !== prevProps.gaStatus) {
        this.showNotAuthedBanner()
    }
  }

  componentWillUnmount() {
    this.props.dispatch(hideBanner())
  }

  showNotAuthedBanner() {
    const subtext = this.props.gaStatus === Enums.GAStatus.PermissionsError ? ' (permissions error)' : this.props.gaStatus === Enums.GAStatus.UnknownError ? ' (unknown error)' : null
    this.props.dispatch(
        showBanner(
            <React.Fragment>Google Analytics isn't authorized{subtext}. Authorize Google Analytics to get all features of the app.</React.Fragment>,
            null,
            <Button variant="outlined" color="secondary" href={ this.props.oAuthUrl } style={{ whiteSpace: 'nowrap' }}>
                Authorize Google Analytics
            </Button>
        ))
  }

  render() {
    let message = null
    let button = null

    if (this.props.gaStatus === Enums.GAStatus.UnknownError) {
        //message = 'Unknown error, please refresh the page to try again'
    } else if (this.props.gaStatus === Enums.GAStatus.PermissionsError) {
        //message = 'Permissions error, please try to re-authorize'
        //button = (
        //    <Button color='primary' href={ this.props.oAuthUrl }>
        //        Authorize Google Analytics
        //    </Button>
        //)
    } else if (this.props.gaStatus === Enums.GAStatus.Checking) {
        message = null // 'Checking Google Analytics status...'
    } else if (this.props.gaStatus === Enums.GAStatus.Authed) {
        message = `Connected to Google Analytics as ${ this.props.googleAuthEmail }`
        button = (
            <Button color='secondary' variant="outlined" href={ this.props.oAuthUrl }>
                Change Connected Account
            </Button>
        )
    } else if (this.props.gaStatus === Enums.GAStatus.NotAuthed) {
        //message = 'Google Analytics not authorized'
        /*button = (
            <Button color='primary' href={ this.props.oAuthUrl }>
                Authorize Google Analytics
            </Button>
        )*/
    } else {
        // NotChecked
        message = 'Unknown error'
    }

    return (
        <React.Fragment>
          <Typography variant="body1" gutterBottom>
              {message}
          </Typography>
          {button}

          <br/><br/>

          <Card style={{ width: '100%' }} elevation={3}>
            <CardHeader style={{alignItems: 'initial', overflowWrap: 'anywhere'}}
                             title={<Typography variant='h6'>Check out these guides to set up your Google Analytics account.</Typography>}
                             />

            <Divider />

            <CardContent>
              <ul>
                <li>
                  Track your listing traffic
                  <ul>
                    <li>
                      <a href='https://shopify.dev/docs/apps/launch/marketing/track-listing-traffic#set-up-google-analytics-for-your-app-listing' target='_blank' rel='noopener noreferrer'>
                        Set up Google Analytics for your app listing
                      </a>
                    </li>
                    <li>
                      <a href='https://shopify.dev/docs/apps/launch/marketing/track-listing-traffic#full-funnel-app-install-attributions' target='_blank' rel='noopener noreferrer'>
                        Full-funnel app install attributions
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href='https://www.appstoreanalytics.io/post/set-up-server-side-shopify-app-attribution' target='_blank' rel='noopener noreferrer'>
                    Setup server side shopify app attribution
                  </a>
                </li>
                <li>
                  <a href='https://www.appstoreanalytics.io/post/track-your-shopify-app-listing-using-google-analytics' target='_blank' rel='noopener noreferrer'>
                    Track your shopify app listing using google analytics
                  </a>
                </li>
              </ul>
            </CardContent>
          </Card>
        </React.Fragment>
    )
  }
}

SelectGoogleAccount.propTypes = {
    googleAuthEmail: PropTypes.string,
    gaStatus: PropTypes.oneOf(Object.values(Enums.GAStatus)).isRequired,
    oAuthUrl: PropTypes.string.isRequired
}

export default connect(null)(SelectGoogleAccount)