import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

class InviteTeamMemberDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        email: '',
        first_name: '',
        last_name: ''
    }
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(event) {
    event.preventDefault()
    event.stopPropagation()
    this.props.onInvite(this.state.email, this.state.first_name, this.state.last_name)
  }

  render() {
    return (
        <Dialog open={true} fullWidth={true} disableEnforceFocus>
          <DialogTitle>
            <Typography>Invite team member</Typography>
          </DialogTitle>
          <form onSubmit={this.onSubmit}>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField 
                  autoFocus
                  value={this.state.first_name}
                  disabled={this.props.isLoading}
                  label='First name'
                  onChange={ (event) => this.setState({ first_name: event.target.value }) }
                  fullWidth={true}
                  required={true}
                  error={this.props.errors && this.props.errors.first_name ? true : false}
                  helperText={this.props.errors && this.props.errors.first_name ? this.props.errors.first_name.join(', ') : null}
                  />  
                </Grid>
                <Grid item xs={6}>
                  <TextField 
                  value={this.state.last_name}
                  disabled={this.props.isLoading}
                  label='Last name'
                  onChange={ (event) => this.setState({ last_name: event.target.value }) }
                  fullWidth={true}
                  required={true}
                  error={this.props.errors && this.props.errors.last_name ? true : false}
                  helperText={this.props.errors && this.props.errors.last_name ? this.props.errors.last_name.join(', ') : null}
                  />  
                </Grid>
                <Grid item xs={12}>
                  <TextField 
                    value={this.state.email}
                    disabled={this.props.isLoading}
                    label='Email'
                    onChange={ (event) => this.setState({ email: event.target.value }) }
                    fullWidth={true}
                    required={true}
                    error={this.props.errors && this.props.errors.email ? true : false}
                    helperText={this.props.errors && this.props.errors.email ? this.props.errors.email.join(', ') : null}
                  /> 
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onCancel} disabled={this.props.isLoading}>
                Cancel
                </Button>
                <Button color="primary" disabled={this.props.isLoading} type='submit'>
                Invite
                </Button>
            </DialogActions>
          </form>
        </Dialog>
      )
  }
}

InviteTeamMemberDialog.propTypes = {
    errors: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onInvite: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired
}

export default InviteTeamMemberDialog