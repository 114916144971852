import { connect } from 'react-redux'
import { requestPasswordReset } from '../../actions/actions'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'

class RequestPasswordResetContainer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      email: ''
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
  }

  onInputChange(event) {
    let newState = {}
    newState[event.target.name] = event.target.value
    newState.errors = {}
    this.setState(newState)
  }

  onSubmit(event) {
    event.preventDefault()
    event.stopPropagation()
    this.props.dispatch(requestPasswordReset(this.state.email))
  }

  render() {
    let content = null
    if (this.props.passwordReset.requestedPasswordReset === false) {
      content = (
        <form onSubmit={this.onSubmit}>
          <DialogContent dividers>
            <Typography variant="h5">
              Reset Password
            </Typography>
            <Typography variant="body1" gutterBottom>
              Enter your email address. A password reset link will be emailed to you if an account exists for it.
            </Typography>
            <div>
              <TextField 
                autoFocus
                value={this.state.email}
                disabled={this.props.passwordReset.requestingPasswordReset === true}
                ref='SigninEmail'
                name='email'
                label='Email'
                onChange={this.onInputChange}
                fullWidth={true}
                margin='normal'
                required={true}
                error={this.props.passwordReset.requestPasswordResetErrors !== null ? true : false}
                helperText={this.props.passwordReset.requestPasswordResetErrors}
              />  
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' disabled={this.props.passwordReset.requestingPasswordReset === true} color='primary' type='submit'>
              RESET PASSWORD
            </Button>
          </DialogActions>
        </form>
      )
    } else {
      content = (
        <DialogContent dividers>
          <Typography variant="h5">
            Reset Password
          </Typography>
          <Typography variant="body1" gutterBottom>
            A password reset link has been emailed to {this.state.email}. Follow the instructions there to reset your password.
          </Typography>
        </DialogContent>
      )
    }

    return (
      <Dialog open={true} disableBackdropClick={true} transitionDuration={0} fullWidth={true} disableEnforceFocus>
        <DialogTitle disableTypography={true}>
          <img style={{ height: '35px', width: 'auto' }} alt="App Store Analytics" src="/assets/images/logo.png"/>
        </DialogTitle>
        {content}
      </Dialog>
    )
  }
}

function mapStateToProps(state) {
  const { passwordReset } = state

  return { passwordReset }
}
export default connect(mapStateToProps)(RequestPasswordResetContainer)