import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: null // []
}

const keywords = createReducer(initialState, {
  LOADING_KEYWORDS_STATUS: (state, action) => { 
    state.isLoading = true
    state.data = null
  },
  LOADED_KEYWORDS_STATUS: (state, action) => { 
    state.isLoading = false
    state.data = action.data
  },
})

export default keywords