import { combineReducers } from 'redux'

import user from './user'
import googleAnalytics from './googleAnalytics'
import billing from './billing'
import appListing from './appListing'
import team from './team'
import accounts from './accounts'
import accountApps from './accountApps'
import banner from './banner'
import passwordReset from './passwordReset'
import apps from './apps'
import accountUserSettings from './accountUserSettings'
import accountAppKeywords from './accountAppKeywords'
import help from './help'
import keywords from './keywords'
import rankings from './rankings'
import reviewNumbers from './reviewNumbers'
import attributedInstalls from './attributedInstalls'

const appReducer = combineReducers({
  user,
  googleAnalytics,
  billing,
  appListing,
  team,
  accounts,
  accountApps,
  banner,
  passwordReset,
  apps,
  accountUserSettings,
  accountAppKeywords,
  help,
  keywords,
  rankings,
  reviewNumbers,
  attributedInstalls
})

const rootReducer = (state, action) => {
  if (action.type === 'SIGNED_OUT_STATUS') {
    // wipe all state apart from user
    const { user } = state
    state = { user } 
  }

  return appReducer(state, action)
}

export default rootReducer






/* just a place to draft the schema... may be out-of-date, don't rely on it for anything other than sketching */
const schema = {
  accounts: {
    isLoading: false,
    currentAccount: null,
    currentAccountId: null,
    data: [

    ]
  },
  accountApps: {
    isLoading: false,
    currentAccountApp: null,
    currentAccountAppId: null,
    data: [

    ]
  },
  user: {
    isAuthenticated: false,
    isLoading: false,
    errors: null,
    id: null,
    email: null,
    accessToken: null
  },
  team: {
    isLoading: false,
    error: null,
    members: null
  },
  googleAnalytics: {
    status: 'Enums.GAStatus.NotQueried',
    properties: {
      isLoading: false,
      data: [

      ]
    },
    timeSeriesReport: {
      isLoading: false,
      groupBy: null,
      surfaceType: null,
      locale: null,
      surfaceDetail: null,
      group: null,
      data: [

      ]
    }
  },
  billing: {
    checkoutSession: {
      id: null
    }
  },
  appListing: {
    history: {
      isLoading: false,
      changeDates: []
    },
    snapshots: [
      {
        isLoading: false,
        activeAt: '',
        currentListing: '',
        previousListing: ''
      }
    ]
  }
}