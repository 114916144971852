
let api_base = 'http://localhost:3000'
if (__PRERELEASE__)
  api_base = 'https://api-stage.appstoreanalytics.io'
else if (__RELEASE__)
  api_base = 'https://api.appstoreanalytics.io'
export const API_BASE = api_base

let www_base = 'http://www.appstoreanalytics.local:3000'
if (__PRERELEASE__)
  www_base = 'https://www-stage.appstoreanalytics.io'
else if (__RELEASE__)
  www_base = 'https://www.appstoreanalytics.io'
export const WWW_BASE = www_base

export const APP_NAME = 'App Store Analytics'
export const SUPPORT_EMAIL = 'hello@appstoreanalytics.io'
export const COMPANY_NAME = 'App Store Analytics'

export const validations = {
  email: 'Please enter valid email address',
  url:   'Please enter valid url',
  equal: (a, b) => `${a} does not match the ${b}`,
}

export const SUPPORT_URL = 'https://plug-in-useful.typeform.com/to/DLDifh'
export const GOOGLE_OAUTH2_PATH = '/auth/google_oauth2'
export const GOOGLE_SEARCH_CONSOLE_VERIFY_BASE_URI = "https://search.google.com/search-console/ownership?resource_id="
export const GOOGLE_SEARCH_CONSOLE_PERMISSION_UNVERIFIED = "siteUnverifiedUser"
export const GOOGLE_SEARCH_CONSOLE_SETUP_URL = "https://search.google.com/search-console/about"
