import React from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Radio from '@material-ui/core/Radio'

class PropertiesListGA4 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    if (this.props.properties.data.length === 0) {
        return null
    }

    // { p.profiles.map(prf => {
      //                 return (
      //                 <List key={ prf.id } component="div" disablePadding>
      //                     <ListItem button style={{ paddingLeft: 40 }} onClick={ () => this.props.onPropertySelected(prf.id) }>
      //                     <ListItemIcon>
      //                         <Radio checked={ this.props.selectedProperty === prf.id } />
      //                     </ListItemIcon>
      //                     <ListItemText primary={ prf.name } secondary={ prf.id } />
      //                     </ListItem>
      //                 </List>
      //                 )
      //             })}

    return this.props.properties.data.account_summaries_v4.account_summaries.map(a => {
        if (a.property_summaries === undefined || a.property_summaries.length < 1) {
            return null
        }
        return (
        <span key={ `${a.account}-span` }>
        <ListSubheader disableSticky={ true } key={ a.account }>{ a.display_name } { a.account }</ListSubheader>
        { a.property_summaries.map(p => {
            return (
            <span key={ `${p.property}-span` }>
                <ListItem key={p.property} button onClick={ () => this.props.onPropertySelected(p.property) }>
                    <ListItemIcon>
                        <Radio checked={ this.props.selectedPropertyGA4 === p.property } />
                    </ListItemIcon>
                    <ListItemText primary={ p.display_name } secondary={ p.property } />
                </ListItem>
            </span>
            )
        })}
        </span>
        )
    })        
  }
}

PropertiesListGA4.propTypes = {
    selectedPropertyGA4: PropTypes.string,
    properties: PropTypes.object.isRequired,
    onPropertySelected: PropTypes.func.isRequired
}
export default PropertiesListGA4