import { createReducer } from '@reduxjs/toolkit'
import {
  LOADING_APPS_LIST_STATUS,
  LOADED_APPS_LIST_STATUS
} from '../actions/actions'

const initialState = {
  all: {
    isLoading: false,
    data: null
  },
  search: {
    isLoading: false,
    appUrlPath: null,
    data: null
  }
}

const apps = createReducer(initialState, {
  LOADING_ALL_APPS_STATUS: (state, action) => { 
    state.all.isLoading = true
    state.all.data = null
  },
  LOADED_ALL_APPS_STATUS: (state, action) => { 
    state.all.isLoading = false
    state.all.data = action.data
  },
  SEARCHING_APPS_STATUS: (state, action) => { 
    state.search.isLoading = true
    state.search.appUrlPath = action.appUrlPath
    state.search.data = null
  },
  SEARCHED_APPS_STATUS: (state, action) => { 
    state.search.isLoading = false
    state.search.data = action.data
  }
})

export default apps