import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { createAnonymousUser } from '../actions/actions'
import { Redirect } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import SomethingIsLoadingBar from '../components/navigation/SomethingIsLoadingBar'

// Responsibility: create an anonymous user, redirect once 'logged in'
class AnonymousContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    if (this.props.user.isAuthenticated === false && this.props.user.isLoading === false) {
      this.props.dispatch(createAnonymousUser())
    }
  }

  render() {
    if (this.props.user.isAuthenticated === true) {
      return <Redirect to={`${this.props.location.replace("/a/", "/l/")}${this.props.location.search}`} />
    }

    return (
      <React.Fragment>
        <AppBar>
          <Toolbar style={{ paddingLeft: 18 }}>
            <img style={{ width: 185 }} alt="App Store Analytics" src="/assets/images/logo-white.png"/>
          </Toolbar>
          <SomethingIsLoadingBar />
        </AppBar>
        <Toolbar />
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { user } = state

  return { user }
}

AnonymousContainer.propTypes = {
  location: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(AnonymousContainer)