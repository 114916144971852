import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { signUp } from '../../actions/actions'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

class SignUpContainer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      password: ''
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
  }

  onInputChange(event) {
    let newState = {}
    newState[event.target.name] = event.target.value
    newState.errors = {}
    this.setState(newState)
  }

  onSubmit(event) {
    event.preventDefault()
    event.stopPropagation()
    this.props.dispatch(signUp(this.state.email, this.state.password, this.state.first_name, this.state.last_name))
  }

  render() {
    const { user } = this.props

    //if (user && user.isAuthenticated === true && user.anonymous !== true) {
    //  return <Redirect to='/l/setup' />
    //}

    return (
      <Dialog open={true} disableBackdropClick={true} transitionDuration={0} fullWidth={true} maxWidth='sm' disableEnforceFocus>
        <DialogTitle disableTypography={true}>
          <img style={{ height: '35px', width: 'auto' }} alt="App Store Analytics" src="/assets/images/logo.png"/>
        </DialogTitle>
        <form onSubmit={this.onSubmit}>
          <DialogContent dividers>
            <Typography variant="h5">
              Start your 7-day free trial, no credit card required
            </Typography>
            <Typography variant="body1" gutterBottom>
              Already signed up? <Link to='/sign-in'>Sign In</Link>
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField 
                  autoFocus
                  value={this.state.first_name}
                  disabled={user && user.isLoading === true}
                  name='first_name'
                  label='First name'
                  onChange={this.onInputChange}
                  fullWidth={true}
                  margin='normal'
                  required={true}
                  error={user && user.errors && user.errors.first_name ? true : false}
                  helperText={user && user.errors && user.errors.first_name ? user.errors.first_name.join(', ') : null}
                />  
              </Grid>
              <Grid item xs={6}>
                <TextField 
                  value={this.state.last_name}
                  disabled={user && user.isLoading === true}
                  name='last_name'
                  label='Last name'
                  onChange={this.onInputChange}
                  fullWidth={true}
                  margin='normal'
                  required={true}
                  error={user && user.errors && user.errors.last_name ? true : false}
                  helperText={user && user.errors && user.errors.last_name ? user.errors.last_name.join(', ') : null}
                />  
              </Grid>
              <Grid item xs={12}>
                <TextField 
                  value={this.state.email}
                  disabled={user && user.isLoading === true}
                  ref='SignUpEmail'
                  name='email'
                  label='Email'
                  onChange={this.onInputChange}
                  fullWidth={true}
                  margin='normal'
                  required={true}
                  error={user && user.errors && user.errors.email ? true : false}
                  helperText={user && user.errors && user.errors.email ? user.errors.email.join(', ') : null}
                />  
              </Grid>
              <Grid item xs={12}>
                <TextField 
                  value={this.state.password}
                  disabled={user && user.isLoading === true}
                  ref='SignUpPassword'
                  name='password'
                  type='password'
                  label='Password'
                  onChange={this.onInputChange}
                  fullWidth={true}
                  margin='normal'
                  required={true}
                  error={user && user.errors && user.errors.password ? true : false}
                  helperText={user && user.errors && user.errors.password ? user.errors.password.join(', ') : null}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' disabled={user && user.isLoading === true} color='primary' type='submit'>
              SIGN UP
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

function mapStateToProps(state) {
  const { user } = state

  return {
    user
  }
}
export default connect(mapStateToProps)(SignUpContainer)