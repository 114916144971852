import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Chip from '@material-ui/core/Chip'
import EditIcon from '@material-ui/icons/Edit'
import SelectToFromDatesDialog from '../../components/googleAnalytics/SelectToFromDatesDialog'

class ToFromDatesChip extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showSelectToFromDatesDialog: false,

      selectedToFromDateFriendly: null,
      selectedBaseDateRangeFriendly: null,
      selectedCompareDateRangeFriendly: null,

      currentDateRangeValue: null,
    }

    this.makeDatesTable = this.makeDatesTable.bind(this)
    this.handleSelectToFromDates = this.handleSelectToFromDates.bind(this)

    this.datesTable = []
  }

  componentDidMount() {
    this.makeDatesTable()

    const currentDateRange = this.currentDateRange(this.props.selectedToDate, this.props.selectedFromDate, this.props.selectedCompareToDate, this.props.selectedCompareFromDate)
    if (this.state.currentDateRangeValue !== currentDateRange.value) {
      this.setState({
        currentDateRangeValue: currentDateRange.value
      })
      if (this.props.onNewDateRangeLabel !== null) {
        this.props.onNewDateRangeLabel(currentDateRange.valueLabel, currentDateRange.compareValueLabel)
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedToDate !== this.props.selectedToDate || prevProps.selectedFromDate !== this.props.selectedFromDate
        || prevProps.selectedCompareToDate !== this.props.selectedCompareToDate || prevProps.selectedCompareFromDate !== this.props.selectedCompareFromDate) {
      const currentDateRange = this.currentDateRange(this.props.selectedToDate, this.props.selectedFromDate, this.props.selectedCompareToDate, this.props.selectedCompareFromDate)
      if (this.state.currentDateRangeValue !== currentDateRange.value) {
        this.setState({
          currentDateRangeValue: currentDateRange.value
        })
        if (this.props.onNewDateRangeLabel !== null) {
          this.props.onNewDateRangeLabel(currentDateRange.valueLabel, currentDateRange.compareValueLabel)
        }
      }
    }
  }

  handleSelectToFromDates(selectedToDate, selectedFromDate, friendlyText, selectedCompareToDate, selectedCompareFromDate) {    
    const dateRange = this.currentDateRange(selectedToDate, selectedFromDate, selectedCompareToDate, selectedCompareFromDate)
    // the to/from dates state is owned by the parent, so passing this back down as a prop will trigger componentDidUpdate, meaning currentDateRangeValue will be calculated at that point
    this.setState({
      selectedToFromDateFriendly: friendlyText,
      showSelectToFromDatesDialog: false
    }, () => this.props.onToFromDatesSelected(selectedToDate, selectedFromDate, selectedCompareToDate, selectedCompareFromDate, dateRange.valueLabel, dateRange.compareValueLabel))
  }

  render() {
    let selectToFromDatesDialog = null
    if (this.state.showSelectToFromDatesDialog === true) {
      selectToFromDatesDialog =
        <SelectToFromDatesDialog
          compare={this.props.compare}

          datesTable={this.datesTable}
          initialSelectedDateRangeValue={this.state.currentDateRangeValue}

          initialSelectedToDate={this.props.selectedToDate}
          initialSelectedFromDate={this.props.selectedFromDate}
          initialSelectedCompareToDate={this.props.selectedCompareToDate}
          initialSelectedCompareFromDate={this.props.selectedCompareFromDate}

          onCancel={() => { this.setState({ showSelectToFromDatesDialog: false }) }}
          onSelect={this.handleSelectToFromDates} />
    }

    const fromDate = moment(this.props.selectedFromDate).format('D MMM YYYY')
    const toDate = moment(this.props.selectedToDate).format('D MMM YYYY')

    return (
      <React.Fragment>
        <Chip label={this.state.selectedToFromDateFriendly !== null ? `Date: ${this.state.selectedToFromDateFriendly}` : `Date: ${fromDate} - ${toDate}`} clickable
          color="primary"
          deleteIcon={<EditIcon />}
          onDelete={() => { this.setState({ showSelectToFromDatesDialog: true }) }}
          onClick={() => { this.setState({ showSelectToFromDatesDialog: true }) }} />
        {selectToFromDatesDialog}
      </React.Fragment>
    )
  }

  makeDatesTable() {
    let dt = this.datesTable
    const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD')
    const oneYearAgo = moment(yesterday).subtract(1, 'year').format('YYYY-MM-DD')

    const sevenDaysAgo = moment(yesterday).subtract(7, 'day').format('YYYY-MM-DD')
    dt.push({ optionType: 'filter', value: 'Last 7 days', valueLabel: 'Last 7 days', compareValueLabel: null, toDate: yesterday, fromDate: sevenDaysAgo, compareToDate: null, compareFromDate: null })
    const eightDaysAgo = moment(sevenDaysAgo).subtract(1, 'day').format('YYYY-MM-DD')
    dt.push({ optionType: 'compare', value: 'Compare last 7 days to previous period', valueLabel: 'Last 7 days', compareValueLabel: 'Previous 7 days', toDate: yesterday, fromDate: sevenDaysAgo, compareToDate: eightDaysAgo, compareFromDate: moment(eightDaysAgo).subtract(7, 'days').format('YYYY-MM-DD') })

    const twentyEightDaysAgo = moment(yesterday).subtract(28, 'days').format('YYYY-MM-DD')
    dt.push({ optionType: 'filter', value: 'Last 28 days', valueLabel: 'Last 28 days', compareValueLabel: null, toDate: yesterday, fromDate: twentyEightDaysAgo, compareToDate: null, compareFromDate: null })
    const twentyNineDaysAgo = moment(twentyEightDaysAgo).subtract(1, 'day').format('YYYY-MM-DD')
    dt.push({ optionType: 'compare', value: 'Compare last 28 days to previous period', valueLabel: 'Last 28 days', compareValueLabel: 'Previous 28 days', toDate: yesterday, fromDate: twentyEightDaysAgo, compareToDate: twentyNineDaysAgo, compareFromDate: moment(twentyNineDaysAgo).subtract(28, 'days').format('YYYY-MM-DD') })

    const threeMonthsAgo = moment(yesterday).subtract(3, 'months').format('YYYY-MM-DD')
    dt.push({ optionType: 'filter', value: 'Last 3 months', valueLabel: 'Last 3 months', compareValueLabel: null, toDate: yesterday, fromDate: threeMonthsAgo, compareToDate: null, compareFromDate: null })
    const threeMonthsAndOneDayAgo = moment(threeMonthsAgo).subtract(1, 'day').format('YYYY-MM-DD')
    dt.push({ optionType: 'compare', value: 'Compare last 3 months to previous period', valueLabel: 'Last 3 months', compareValueLabel: 'Previous 3 months', toDate: yesterday, fromDate: threeMonthsAgo, compareToDate: threeMonthsAndOneDayAgo, compareFromDate: moment(threeMonthsAndOneDayAgo).subtract(3, 'months').format('YYYY-MM-DD') })
    
    const sixMonthsAgo = moment(yesterday).subtract(6, 'months').format('YYYY-MM-DD')
    dt.push({ optionType: 'filter', value: 'Last 6 months', valueLabel: 'Last 6 months', compareValueLabel: null, toDate: yesterday, fromDate: sixMonthsAgo, compareToDate: null, compareFromDate: null })
    const sixMonthsAndOneDayAgo = moment(sixMonthsAgo).subtract(1, 'day').format('YYYY-MM-DD')
    dt.push({ optionType: 'compare', value: 'Compare last 6 months to previous period', valueLabel: 'Last 6 months', compareValueLabel: 'Previous 6 months', toDate: yesterday, fromDate: sixMonthsAgo, compareToDate: sixMonthsAndOneDayAgo, compareFromDate: moment(sixMonthsAndOneDayAgo).subtract(6, 'months').format('YYYY-MM-DD') })

    const twelveMonthsAgo = moment(yesterday).subtract(12, 'months').format('YYYY-MM-DD')
    dt.push({ optionType: 'filter', value: 'Last 12 months', valueLabel: 'Last 12 months', compareValueLabel: null, toDate: yesterday, fromDate: twelveMonthsAgo, compareToDate: null, compareFromDate: null })

    dt.push({ optionType: 'compare', value: 'Compare last 7 days year over year', valueLabel: 'Last 7 days (this year)', compareValueLabel: 'Last 7 days (last year)', toDate: yesterday, fromDate: sevenDaysAgo, compareToDate: oneYearAgo, compareFromDate: moment(oneYearAgo).subtract(7, 'days').format('YYYY-MM-DD') })
    dt.push({ optionType: 'compare', value: 'Compare last 28 days year over year', valueLabel: 'Last 28 days (this year)', compareValueLabel: 'Last 28 days (last year)', toDate: yesterday, fromDate: twentyEightDaysAgo, compareToDate: oneYearAgo, compareFromDate: moment(oneYearAgo).subtract(28, 'days').format('YYYY-MM-DD') })
    dt.push({ optionType: 'compare', value: 'Compare last 3 months year over year', valueLabel: 'Last 3 months (this year)', compareValueLabel: 'Last 3 months (last year)', toDate: yesterday, fromDate: threeMonthsAgo, compareToDate: oneYearAgo, compareFromDate: moment(oneYearAgo).subtract(3, 'months').format('YYYY-MM-DD') })
  }

  currentDateRange(selectedToDate, selectedFromDate, selectedCompareToDate, selectedCompareFromDate) {
    const match =  this.datesTable.find(
      x => x.toDate === selectedToDate
      && x.fromDate === selectedFromDate
      && x.compareToDate === selectedCompareToDate
      && x.compareFromDate === selectedCompareFromDate
    )
    if (match !== undefined) {
      return match
    } else if (selectedCompareToDate !== null) {
      return { optionType: 'compare', value: 'Compare custom', valueLabel: `${selectedFromDate} - ${selectedToDate}`, compareValueLabel: `${selectedCompareFromDate} - ${selectedCompareToDate}`, toDate: selectedToDate, fromDate: selectedFromDate, compareToDate: selectedCompareToDate, compareFromDate: selectedCompareFromDate }
    } else {
      return { optionType: 'filter', value: 'Custom', valueLabel: `${selectedFromDate} - ${selectedToDate}`, compareValueLabel: null, toDate: selectedToDate, fromDate: selectedFromDate, compareToDate: selectedCompareToDate, compareFromDate: selectedCompareFromDate }
    }
  }
}

ToFromDatesChip.defaultProps = {
  selectedCompareToDate: null,
  selectedCompareFromDate: null,
  onNewDateRangeLabel: null,
  compare: true
}

ToFromDatesChip.propTypes = {
  selectedToDate: PropTypes.string.isRequired,
  selectedFromDate: PropTypes.string.isRequired,

  selectedCompareToDate: PropTypes.string,
  selectedCompareFromDate: PropTypes.string,

  onToFromDatesSelected: PropTypes.func.isRequired,
  onNewDateRangeLabel: PropTypes.func,

  compare: PropTypes.bool.isRequired
}

export default ToFromDatesChip