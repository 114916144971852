import PropTypes from 'prop-types'

class ListingKeywordDecorator extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.onMount(this.props.children[0].props.text.toLowerCase())
  }

  componentDidUpdate(prevProps) {
    const prevText = prevProps.children[0].props.text.toLowerCase()
    const text = this.props.children[0].props.text.toLowerCase()
    if (text !== prevText) {
      this.props.onUnmount(prevText)
      this.props.onMount(text)
    }
  }

  componentWillUnmount() {
    this.props.onUnmount(this.props.children[0].props.text.toLowerCase())
  }

  render() {
    return (
      <span style={{ color: '#000' }}>{this.props.children}</span>
    )
  }
}

ListingKeywordDecorator.propTypes = {
  children: PropTypes.node.isRequired,
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired
}

export default ListingKeywordDecorator