import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  title: null,
  body: null
}

const help = createReducer(initialState, {
  SHOW_HELP_STATUS: (state, action) => { 
    state.title = action.title,
    state.body = action.body
  },
  HIDE_HELP_STATUS: (state, action) => {
    state.title = null
    state.body = null
  }
})

export default help