import PropTypes from 'prop-types'
import { LineChart } from 'react-chartkick'
import 'chart.js'
import 'chartjs-plugin-annotation'
import moment from 'moment'

class TimeSeriesChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
  
    }
  }

  // Vertical line on hover: https://jsfiddle.net/hg5873zm/2/
  render() {
    let annotations = []

    this.props.appListingChanges.forEach(change => {
      const dayedDate = moment(change.changeDate).format('YYYY-MM-DD')
      
      annotations.push({
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-0',
        value: dayedDate,
        borderColor: 'rgba(224,224,224,0.5)',
        borderWidth: 3,
        label: {
          enabled: true,
          content: 'CHANGE',
          rotation: 90,
          backgroundColor: 'rgba(224,224,224,0.5)'
        },
        onClick: (e) => { this.props.onAppListingChangeDateClick(dayedDate, change.changeDate) }
      })
    })

    const annotation = {
      events: ['click'],
      annotations: annotations
    }

    return (
      <LineChart
        data={this.props.data}
        library={{
          spanGaps: true,
          legend: { 
            display: false
          },
          annotation: annotation
        }}
        dataset={{
          lineTension: 0.05,
          pointRadius: 0,
          pointHitRadius: 20
        }}
         />
    )
  }
}

TimeSeriesChart.propTypes = {
  data: PropTypes.array.isRequired,
  appListingChanges: PropTypes.array.isRequired,
  onAppListingChangeDateClick: PropTypes.func.isRequired
}

export default TimeSeriesChart