import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: null // []
}

const accountAppKeywords = createReducer(initialState, {
  LOADING_ACCOUNT_APP_KEYWORDS_STATUS: (state, action) => { 
    state.isLoading = true
    state.data = null
  },
  LOADED_ACCOUNT_APP_KEYWORDS_STATUS: (state, action) => {
    state.isLoading = false
    state.data = action.data
  },
  ADDING_ACCOUNT_APP_KEYWORD_STATUS: (state, action) => {
    state.isLoading = true
    if (state.data === null) {
      state.data = []
    }
    let existing = state.data.find(
      x => x.keyword === action.keyword
    )
    if (existing !== undefined) {
      return // copy what the server does
    }
    state.data.push({
      id: action.tempId,
      keyword: action.keyword
    })
    state.data = state.data.sort((a, b) => b.keyword.length - a.keyword.length)
  },
  ADDED_ACCOUNT_APP_KEYWORD_STATUS: (state, action) => {
    state.isLoading = false

    let updated = state.data.find(
      x => x.id === action.data.tempId
    )
    Object.assign(updated, action.data)
  },
  CHECKING_ACCOUNT_APP_KEYWORD_POSITION_STATUS: (state, action) => { 
    if (action.keyword === null) {
      state.data.forEach(keyword => {
        keyword.previous_position = keyword.position
        keyword.position = null
        keyword.checkingPosition = true
      })
    } else {
      let existing = state.data.find(
        x => x.keyword === action.keyword
      )
      if (existing !== undefined) {
        existing.checkingPosition = true
      }
    }
  },
  CHECKED_ACCOUNT_APP_KEYWORD_POSITION_STATUS: (state, action) => {
    if (action.data.length > 1) {
      state.data = action.data
    } else {
      let existing = state.data.find(
        x => x.keyword === action.data[0].keyword
      )
      if (existing !== undefined) {
        existing.checkingPosition = false
        Object.assign(existing, action.data[0])
      }
    }
  },
  CHECKING_ALL_ACCOUNT_APP_KEYWORD_POSITIONS_STATUS: (state, action) => { 
    state.data.forEach(keyword => {
      keyword.previous_position = keyword.position
      keyword.position = null
      keyword.checkingPosition = true
    })
  },
  CHECKED_ALL_ACCOUNT_APP_KEYWORD_POSITIONS_STATUS: (state, action) => { 
    // nothing to do
  },
  DELETING_ACCOUNT_APP_KEYWORD_STATUS: (state, action) => {
    state.isLoading = true
    const newKeywords = state.data.filter(item => item.id !== action.accountAppKeywordId)
    state.data = newKeywords
  },
  DELETED_ACCOUNT_APP_KEYWORD_STATUS: (state, action) => {
    state.isLoading = false
    // assume success
  },
  CLEAR_ACCOUNT_APP_KEYWORDS_STATE_STATUS: (state, action) => {
    state.data = initialState.data
    state.isLoading = initialState.isLoading
  }
})

export default accountAppKeywords