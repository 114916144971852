import { createReducer } from '@reduxjs/toolkit'
import {
  LOADING_TEAM_STATUS,
  LOADED_TEAM_STATUS,
  INVITING_TEAM_MEMBER_STATUS,
  INVITED_TEAM_MEMBER_STATUS
} from '../actions/actions'
import { Enums } from 'utils'

const initialState = {
  isLoading: false,
  members: null,
  errors: null
}

const team = createReducer(initialState, {
  LOADING_TEAM_STATUS: (state, action) => { 
    state.isLoading = true
    state.members = null
    state.errors = null
  },
  LOADED_TEAM_STATUS: (state, action) => {
    state.isLoading = false
    state.members = action.members
  },
  INVITING_TEAM_MEMBER_STATUS: (state, action) => { 
    state.isLoading = true
    state.errors = null
  },
  INVITED_TEAM_MEMBER_STATUS: (state, action) => { 
    state.isLoading = false
    state.errors = action.errors
    if (!action.errors || action.errors === null) {
      state.members.push({ email: action.email })
    }
  },
  REMOVING_TEAM_MEMBER_STATUS: (state, action) => { 
    state.isLoading = true
    state.errors = null
  },
  REMOVED_TEAM_MEMBER_STATUS: (state, action) => { 
    state.isLoading = false
    const index = state.members.findIndex(x => x.email == action.email)
    if (index > -1) {
      state.members.splice(index, 1)
    }
  },
})

export default team