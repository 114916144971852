import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import 'url-search-params-polyfill'
import { createCheckoutSession, switchAccount, loadSubscription, changeSubscription, showBanner, hideBanner, createPortalSession, createOldPortalSession } from '../../actions/actions'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import PlanCard from '../../components/billing/PlanCard'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import Link from '@material-ui/core/Link'
import InfoIcon from '@material-ui/icons/Info'
import Banner from '../../components/banner/Banner'

class BillingContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      justUpgraded: false,
      showStandardPlansForCustomPlan: false
    }
    this.redirectToStripe = this.redirectToStripe.bind(this)
    this.onChangeSubscription = this.onChangeSubscription.bind(this)
    this.onClickPortalLink = this.onClickPortalLink.bind(this)
    this.onClickOldPortalLink = this.onClickOldPortalLink.bind(this)
  }

  componentDidMount() {
    let query = new URLSearchParams(this.props.location.search)
    let returning = query.get('returning')
    let accountId = this.props.accounts.currentAccountId
    if (returning) {
      let result = query.get('result')
      accountId = query.get('accountId')
      this.props.history.replace(this.props.location.pathname)
      if (result === 'success') {
        this.props.dispatch(switchAccount(accountId))
        this.setState({ justUpgraded: true })
      } else {
        // came back from Stripe without a charge (pushed back or cancel)
      }
    }
    if (accountId !== null) {
      this.props.dispatch(loadSubscription(accountId))
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.billing.checkoutSession.isLoading === false && prevProps.billing.checkoutSession.isLoading === true) {
      var stripe = Stripe(CONFIG.stripePublishableKey)
      stripe.redirectToCheckout({
        sessionId: this.props.billing.checkoutSession.id
      }).then(function () {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      })
    } //else if (prevProps.accounts.currentAccount !== null && (prevProps.accounts.currentAccount.stripeSubscription === undefined || prevProps.accounts.currentAccount.stripeSubscription === null) && this.props.accounts.currentAccount.stripeSubscription !== null) {
    //  this.setState({ justUpgraded: true })
    //}

    if (this.props.billing.subscription.isLoading === false && this.props.billing.subscription.activeStripePrice !== null && this.props.billing.subscription.activeStripePrice !== prevProps.billing.subscription.activeStripePrice) {
      const activeStripePrice = this.props.billing.subscription.activeStripePrice

      if (activeStripePrice !== CONFIG.stripeSoloPrice && activeStripePrice !== CONFIG.stripeOptimizerPrice && activeStripePrice !== CONFIG.stripeGrowthPrice && activeStripePrice !== CONFIG.stripeElitePrice) {
        if(this.props.accounts.isMentalUserAccount === false) {
          this.props.dispatch(
            showBanner(
              <React.Fragment>You're on a legacy plan that includes {this.props.accounts.currentAccount.appsLimit} apps and {this.props.accounts.currentAccount.usersLimit} users. This plan is no longer available, but you can change to a different plan.</React.Fragment>,
              <NewReleasesIcon />
            )
          )
        }
      } else {
        this.props.dispatch(hideBanner())
      }
    }

    if (this.props.accounts.currentAccountId !== null && this.props.accounts.currentAccountId !== prevProps.accounts.currentAccountId) {
      this.props.dispatch(loadSubscription(this.props.accounts.currentAccountId))
    }

    if (this.props.billing.portalSession.isLoading === false && prevProps.billing.portalSession.isLoading === true) {
      window.location.assign(this.props.billing.portalSession.portalUrl)
    }

    if(this.props.accounts.isMentalUserAccount === false) {
      this.props.dispatch(showBanner(
        <React.Fragment>
          As part of our recent acquisition, we need to update your billing information. Since Mantle is now a Canadian company, we must transition your billing to a Stripe payments account based in Canada to comply with regulatory requirements.<br /><br />
          Your existing subscription has been canceled. To continue using your App Store Analytics account, please resubscribe. Any remaining days on your previous plan will be added as a trial period to your new subscription.<br /><br />
          Thank you for your understanding and continued support.
        </React.Fragment>,
        <InfoIcon />
      ))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(hideBanner())
  }

  redirectToStripe(stripePrice) {
    this.props.dispatch(createCheckoutSession(this.props.accounts.currentAccount.id, stripePrice))
  }

  onChangeSubscription(newStripePrice) {
    this.props.dispatch(changeSubscription(this.props.accounts.currentAccount.id, newStripePrice))
  }

  onClickPortalLink(e) {
    e.preventDefault()
    this.props.dispatch(createPortalSession(this.props.accounts.currentAccount.id))
  }
  
  onClickOldPortalLink(e) {
    e.preventDefault()
    this.props.dispatch(createOldPortalSession(this.props.accounts.currentAccount.id))
  }

  render() {
    let CTA = null
    let subInfo = null
    let changingSubscription = null

    if (this.props.accounts.isLoading === true || this.props.accounts.data.length < 1 || this.props.billing.subscription.isLoading === true) {
      CTA = null
    } else {
      let headerText = null
      if (this.props.billing.subscription.activeStripePrice !== null && this.props.billing.subscription.isLoading === false) {
        subInfo = (
          <React.Fragment>
            <Typography style={{ textAlign: 'center', marginTop: 24 }}>If you change to a higher plan, the price difference will be pro-rated and charged now</Typography>
            <Typography style={{ textAlign: 'center' }}>If you change to a lower plan, the price difference will be credited and used against future invoices</Typography>
            <Typography style={{ textAlign: 'center', marginTop: 24, marginBottom: 24 }}>
              <Link href="#" onClick={this.onClickPortalLink}>
                Change your card, invoicing information, or cancel
              </Link>
              {(this.props.accounts.currentAccount.oldStripeCustomer !== null && this.props.accounts.currentAccount.oldStripeSubscription !== null ) && (
                <>
                  <br/>
                  <Link href="#" onClick={this.onClickOldPortalLink}>
                    Old Stripe invoices
                  </Link>
                </>
              )}
            </Typography>
          </React.Fragment>
        )
        headerText = 'Change Plan'
        changingSubscription = true
      } else {
        subInfo = (
          <React.Fragment>
            <Typography style={{ textAlign: 'center', marginTop: 24 }}>No extra charges, cancel anytime, billed through Stripe</Typography>
            <Typography style={{ textAlign: 'center', marginTop: 24, marginBottom: 24 }}>
              {(this.props.accounts.currentAccount.oldStripeCustomer !== null && this.props.accounts.currentAccount.oldStripeSubscription !== null ) && (
                <>
                  <br/>
                  <Link href="#" onClick={this.onClickOldPortalLink}>
                    Old Stripe invoices
                  </Link>
                </>
              )}
            </Typography>
          </React.Fragment >
        )
        headerText = 'Choose a Plan'
        changingSubscription = false
      }

      let customPlanSection = null
      // if (this.props.accounts.currentAccount.customPriceId !== null) {
      //   headerText = 'Standard Plans'
      //   customPlanSection = (
      //     <React.Fragment>
      //       <Typography variant="h6" align='center'>
      //         Custom Plan
      //       </Typography>
      //       <Typography style={{ textAlign: 'center', marginBottom: 24 }}>
      //         This custom plan has been created for you.
      //       </Typography>
      //       <Grid container spacing={1} style={{ marginBottom: 24 }}>
      //         <Grid item xs={12} md={6} lg={4}></Grid>
      //         <Grid item xs={12} md={6} lg={4}>
      //           <PlanCard
      //               name={this.props.accounts.currentAccount.customPriceName}
      //               price={this.props.accounts.currentAccount.customPriceMonthly}
      //               description={this.props.accounts.currentAccount.customPriceDescription}
      //               appsLimit='Unlimited'
      //               usersLimit='Unlimited'
      //               stripePrice={this.props.accounts.currentAccount.customPriceId}
      //               onSelectPlan={changingSubscription === true ? this.onChangeSubscription : this.redirectToStripe}
      //               isLoading={this.props.billing.checkoutSession.isLoading}
      //               isCurrentPlan={this.props.billing.subscription.activeStripePrice === this.props.accounts.currentAccount.customPriceId}
      //           />
      //         </Grid>
      //       </Grid>
      //     </React.Fragment>
      //   )
      // }

      CTA = (
        <React.Fragment>
          <Box align='center'>
            <img style={{ height: '45px', width: 'auto' }} alt="App Store Analytics" src="/assets/images/logo.png"/>
          </Box>
          <Typography variant="h6" align='center' style={{ marginBottom: 24 }}>
            Optimize your Shopify App Store listing for
            <br/>more traffic and installs
          </Typography>
          {customPlanSection}
          {customPlanSection !== null ? subInfo : null}
          <Typography variant="h6" align='center'>
            {headerText}
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography style={{ textAlign: 'center', marginBottom: 24 }}>If you would like to discuss the plans or have any questions, please email us <Link href='mailto:hello@appstoreanalytics.io'>hello@appstoreanalytics.io</Link></Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={4}>
              <PlanCard 
                name='Solo' 
                price='29' 
                appsLimit='3' 
                usersLimit={1} 
                stripePrice={CONFIG.stripeSoloPrice} 
                onSelectPlan={changingSubscription === true ? this.onChangeSubscription : this.redirectToStripe} 
                isLoading={this.props.billing.checkoutSession.isLoading} 
                isCurrentPlan={this.props.billing.subscription.activeStripePrice === CONFIG.stripeSoloPrice}
                isMantleUserAccount={ this.props.accounts.currentAccount.isMentalUserAccount }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <PlanCard
                name='Optimizer' 
                price='58' 
                appsLimit='7' 
                usersLimit='Unlimited' 
                stripePrice={CONFIG.stripeOptimizerPrice} 
                onSelectPlan={changingSubscription === true ? this.onChangeSubscription : this.redirectToStripe} 
                isLoading={this.props.billing.checkoutSession.isLoading}
                isCurrentPlan={this.props.billing.subscription.activeStripePrice === CONFIG.stripeOptimizerPrice}
                isMantleUserAccount={ this.props.accounts.currentAccount.isMentalUserAccount }
              />
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <PlanCard
                name='Growth' 
                price='99' 
                appsLimit={ this.props.accounts.currentAccount.isMentalUserAccount ? 'Unlimited' : '15'}
                usersLimit='Unlimited' 
                stripePrice={CONFIG.stripeGrowthPrice} 
                onSelectPlan={changingSubscription === true ? this.onChangeSubscription : this.redirectToStripe} 
                isLoading={this.props.billing.checkoutSession.isLoading}
                isCurrentPlan={this.props.billing.subscription.activeStripePrice === CONFIG.stripeGrowthPrice}
                isMantleUserAccount={ this.props.accounts.currentAccount.isMentalUserAccount }
              />
            </Grid>
            {/* <Grid item xs={12} md={6} lg={3}>
              <PlanCard
                name='Elite'
                price='249'
                appsLimit='40'
                usersLimit='Unlimited'
                stripePrice={CONFIG.stripeElitePrice}
                onSelectPlan={changingSubscription === true ? this.onChangeSubscription : this.redirectToStripe}
                isLoading={this.props.billing.checkoutSession.isLoading}
                isCurrentPlan={this.props.billing.subscription.activeStripePrice === CONFIG.stripeElitePrice}
              />
            </Grid> */}
          </Grid>
          {customPlanSection === null ? subInfo : null}
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Banner />
        <br />
        <Container>
          {CTA}
        </Container>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { billing, accounts } = state

  return { billing, accounts }
}

BillingContainer.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  children: PropTypes.node
}

export default connect(mapStateToProps)(BillingContainer)