import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import 'url-search-params-polyfill'
import { Redirect } from 'react-router-dom'
import { checkGAStatus, loadGAProperties, updateAccount, updateAccountApp } from '../../actions/actions'
import { Enums } from 'utils'
import { GOOGLE_OAUTH2_PATH, API_BASE } from 'consts'
import SelectLinkedProperty from '../../components/googleAnalytics/SelectLinkedProperty'
import SelectGoogleAccount from '../../components/googleAnalytics/SelectGoogleAccount'
import Container from '@material-ui/core/Container'
import SubAppBar from '../../components/navigation/SubAppBar'

// Responsibility: ensure a valid GA auth, ensure all account apps are linked, render children once true
class GoogleAnalyticsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedGoogleAnalyticsProperty: null,
      gaAuthedAndLinked: null,
      startAuth: props.waitToStartAuth === false
    }
    this.onSelectProperty = this.onSelectProperty.bind(this)
    this.startGoogleAuth = this.startGoogleAuth.bind(this)
  }

  componentDidMount() {
    let query = new URLSearchParams(this.props.location.search)
    let returning = query.get('returning')
    if (returning) {
      this.props.dispatch(updateAccount( 
        this.props.accounts.currentAccountId,
        query.get('googleAuthEmail'),
        query.get('googleAuthToken'),
        query.get('googleAuthRefreshToken'),
        null,
        null
      ))
      this.props.history.replace(this.props.location.pathname)
    } else {
      this.props.dispatch(checkGAStatus(this.props.accounts.currentAccountId))
    }
    //this.props.dispatch(checkGAGoalStatus(this.props.accountApps.currentAccountAppId))
  }

  componentDidUpdate(prevProps) {
    if (this.props.googleAnalytics.status === Enums.GAStatus.Authed && prevProps.googleAnalytics.status !== Enums.GAStatus.Authed) {
      // just authed
      this.props.dispatch(loadGAProperties(this.props.accounts.currentAccountId))
    //} else if (prevProps.user.googleAnalyticsProperty !== this.props.user.googleAnalyticsProperty) {
    //  // changed auth
    //  this.props.dispatch(clearGAReports())
    //  this.props.dispatch(checkGAStatus(this.props.accounts.currentAccountId))
    //} else if (this.props.user.googleAuthEmail !== prevProps.user.googleAuthEmail) {
      // just authorized / changed authorization
    //  this.props.dispatch(checkGAStatus(this.props.accounts.currentAccountId))
    //} else if (this.props.user.googleAuthToken !== prevProps.user.googleAuthToken || this.props.user.googleAuthRefreshToken !== prevProps.user.googleAuthRefreshToken) {
      // changed token: likely re-authorizing or scope change
    //  this.props.dispatch(checkGAStatus(this.props.accounts.currentAccountId))
    }
/*
    if (this.props.accountApps.currentAccountAppId !== prevProps.accountApps.currentAccountAppId
        || this.props.accountApps.currentAccountApp.googleAnalyticsProperty !== prevProps.accountApps.currentAccountApp.googleAnalyticsProperty) {
      this.props.dispatch(checkGAGoalStatus(this.props.accountApps.currentAccountAppId))
    }
*/
  }

  onSelectProperty(selectedProperty, selectedPropertyGA4, enteredBigQueryProjectId, enteredShopifyAppId, accountAppId) {
    this.props.dispatch(
        updateAccountApp(accountAppId,
            { googleAnalyticsProperty: selectedProperty, googleAnalyticsPropertyGA4: selectedPropertyGA4, bigqueryProjectId: enteredBigQueryProjectId, shopifyAppId: enteredShopifyAppId }))
  }

  startGoogleAuth() {
    this.setState({ startAuth: true })
  }

  render() {
    if (this.props.children !== undefined && this.props.accountApps.currentAccountApp !== null && this.props.accountApps.currentAccountApp.appType !== Enums.AppType.Own) {
      // a child component expects GA data, but the currently selected app is not eligible for GA data
      return <Redirect to={`/l/apps/${this.props.accountApps.currentAccountApp.urlPath}/listing-history`} />
    }

    // waitToStartAuth has been selected, so wait until a child calls startGoogleAuth
    if (this.state.startAuth === false) {
      return (
        <div>
            {this.renderChildren()}
        </div>
      )
    }

    const isLoading = this.props.user.isLoading === true || this.props.googleAnalytics.properties.isLoading === true || this.props.accountApps.isLoading === true || this.props.accounts.isLoading === true || this.props.googleAnalytics.goalStatus.isLoading

    if (isLoading === true) {
      return (
        <React.Fragment>
          <SubAppBar title={this.props.title} />
          <Container>
          </Container>
        </React.Fragment>
      )
    }

    // Not authed OR on the settings page
    let selectGoogleAccount = null
    if (this.props.googleAnalytics.status !== Enums.GAStatus.Authed || this.props.children === undefined) {
      selectGoogleAccount = (
        <SelectGoogleAccount
          googleAuthEmail={this.props.accounts.currentAccount.googleAuthEmail}
          gaStatus={this.props.googleAnalytics.status}
          oAuthUrl={`${API_BASE}${GOOGLE_OAUTH2_PATH}?state=${encodeURIComponent(this.props.location.pathname + '||')}${this.props.accounts.currentAccountId}`} />
      )
    }

    // Authed with children
    let selectLinkedProperty = null
    if (this.props.googleAnalytics.status === Enums.GAStatus.Authed && this.props.children !== undefined) {
      const currentAccountApp = this.props.accountApps.currentAccountApp
      selectLinkedProperty = (
        <SelectLinkedProperty
          currentPropertyId={currentAccountApp.googleAnalyticsProperty}
          currentPropertyIdGA4={currentAccountApp.googleAnalyticsPropertyGA4}
          currentBigQueryProjectId={currentAccountApp.bigqueryProjectId}
          currentShopifyAppId={currentAccountApp.shopifyAppId}
          accountAppId={currentAccountApp.id}
          appStoreUrl={currentAccountApp.url}
          properties={this.props.googleAnalytics.properties}
          onPropertySelected={ this.onSelectProperty }
          hideIfLinked={this.props.hideIfLinked} />
      )
    }

    // Authed and all account apps linked
    let children = null
    let goalStatus = null
    if (this.props.googleAnalytics.status === Enums.GAStatus.Authed && (this.props.accountApps.currentAccountApp === null || (this.props.accountApps.currentAccountApp.googleAnalyticsProperty !== null || this.props.accountApps.currentAccountApp.googleAnalyticsPropertyGA4 !== null))) {
      children = this.renderChildren()
      /*
      const currentGoalStatus = this.props.googleAnalytics.goalStatus.data.find(x => x.accountAppId === this.props.accountApps.currentAccountAppId)
      if (currentGoalStatus !== undefined) {
        goalStatus = (
          <GoalStatus accountAppId={this.props.accountApps.currentAccountAppId} setUp={currentGoalStatus.setUp} />
        )
      }
      */
    }

    return (
      <React.Fragment>
        <SubAppBar title={this.props.title} />
        <Container>
          {selectGoogleAccount}
          {selectLinkedProperty}
          {goalStatus}
          {children}
        </Container>
      </React.Fragment>
    )
  }

  renderChildren() {
    if (this.props.children !== undefined) {
      return React.cloneElement(this.props.children, { startGoogleAuth: this.startGoogleAuth })
    } else {
      return null
    }
  }
}

function mapStateToProps(state) {
  const { user, accounts, accountApps, googleAnalytics } = state

  return { user, accounts, accountApps, googleAnalytics }
}

GoogleAnalyticsContainer.defaultProps = {
  waitToStartAuth: false,
  hideIfLinked: false
}

GoogleAnalyticsContainer.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  children: PropTypes.node,
  waitToStartAuth: PropTypes.bool.isRequired,
  hideIfLinked: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(GoogleAnalyticsContainer)