import { connect } from 'react-redux'
import Container from '@material-ui/core/Container'
import SubAppBar from '../../components/navigation/SubAppBar'
import {
  loadReviewNumbersTotals,
  loadReviewNumbersIncrease,
  showHelp
} from '../../actions/actions'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import PositionsChart from '../../components/positions/PositionsChart'
import ToFromDatesChip from '../../components/googleAnalytics/ToFromDatesChip'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { LineChart, ColumnChart } from 'react-chartkick'
import 'chart.js'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import HelpIcon from '@material-ui/icons/Help'

const styles = theme => ({
  rangeFilter: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(.5),
    },
  }
})

class ReviewsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedFromDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
      selectedToDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      selectedTimeGrouping: 'Week',
    }
  }

  componentDidMount() {
    this.props.dispatch(loadReviewNumbersTotals(this.props.accountApps.currentAccountAppId, this.state.selectedFromDate, this.state.selectedToDate))
    this.props.dispatch(loadReviewNumbersIncrease(this.props.accountApps.currentAccountAppId, this.state.selectedFromDate, this.state.selectedToDate, this.state.selectedTimeGrouping))
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedFromDate !== this.state.selectedFromDate || prevState.selectedToDate !== this.state.selectedToDate || prevState.selectedTimeGrouping !== this.state.selectedTimeGrouping) {
      this.props.dispatch(loadReviewNumbersTotals(this.props.accountApps.currentAccountAppId, this.state.selectedFromDate, this.state.selectedToDate))
      this.props.dispatch(loadReviewNumbersIncrease(this.props.accountApps.currentAccountAppId, this.state.selectedFromDate, this.state.selectedToDate, this.state.selectedTimeGrouping))
    }
  }

  render() {
    const { classes } = this.props

    let totalsChart = null
    if (this.props.reviewNumbers.totals.isLoading === false && this.props.reviewNumbers.totals.data !== null && this.props.reviewNumbers.totals.data.length > 0) {
      totalsChart = (
        <LineChart
        data={this.props.reviewNumbers.totals.data}
        library={{
          scales: {
            yAxes: [{
                stacked: true
            }]
          }
        }}
        dataset={{
          lineTension: 0.05,
          pointRadius: 0,
          pointHitRadius: 20,
          fill: 'origin'
        }}
         />
      )
    } 

    let increaseChart = null
    if (this.props.reviewNumbers.increase.isLoading === false && this.props.reviewNumbers.increase.data !== null && this.props.reviewNumbers.increase.data.length > 0) {
      increaseChart = (
        <ColumnChart
        data={this.props.reviewNumbers.increase.data}
        stacked={true}
        library={{
          scales: {
            yAxes: [{
                id: 'rating-y-axis',
                type: 'linear',
                position: 'right',
                ticks: {
                  min: 0,
                  max: 5,
                  stepSize: .25,
                  precision: 0.5
                },
                gridLines: {
                  display: false
                }
            },{
              id: 'reviews-y-axis',
              type: 'linear',
              stacked: true,
              ticks: {
                stepSize: 1
              },
            }]
          }
        }}
        dataset={{
          lineTension: 0.05,
          pointRadius: 1.5,
          pointHitRadius: 20
        }}
         />
      )
    } 
/*
            <Grid item md={12}>
              {totalsChart}
            </Grid>

            <IconButton onClick={this.props.onShowHelp}>
                        <HelpIcon />
                      </IconButton>
*/
    return (
      <React.Fragment>
        <SubAppBar title='Reviews' />
        <Container>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <ToFromDatesChip
                compare={false}
                selectedFromDate={this.state.selectedFromDate}
                selectedToDate={this.state.selectedToDate}
                onToFromDatesSelected={(selectedToDate, selectedFromDate) => { this.setState({ selectedToDate, selectedFromDate }) }}
              />
            </Grid>
            <Grid item md={12}>
              <div className={classes.rangeFilter}>
                <Chip label="Day"
                  size="small"
                  color={this.state.selectedTimeGrouping === 'Day' ? 'primary' : 'default'}
                  onClick={() =>  this.setState({ selectedTimeGrouping: 'Day' })} />
                <Chip label="Week"
                  size="small"
                  color={this.state.selectedTimeGrouping === 'Week' ? 'primary' : 'default'}
                  onClick={() =>  this.setState({ selectedTimeGrouping: 'Week' })} />
                <Chip label="Month"
                  size="small"
                  color={this.state.selectedTimeGrouping === 'Month' ? 'primary' : 'default'}
                  onClick={() =>  this.setState({ selectedTimeGrouping: 'Month' })} />
              </div>
            </Grid>

            <Grid item md={12}>

              <Paper style={{ padding: 24 }}>
                <Grid container spacing={2}>
                  <Grid container item xs={12} alignItems="center">
                    <Grid item xs>
                      <Typography variant='h6'>
                        Rating & Review Changes
                      </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                      
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    {increaseChart} 
                  </Grid>
                </Grid>
              </Paper>

            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { reviewNumbers, accountApps } = state

  return { reviewNumbers, accountApps }
}

export default connect(mapStateToProps)(withStyles(styles)(ReviewsContainer))