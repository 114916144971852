import { createReducer } from '@reduxjs/toolkit'
import {
  LOADING_ACCOUNT_USER_SETTINGS_STATUS,
  LOADED_ACCOUNT_USER_SETTINGS_STATUS,
  UPDATING_ACCOUNT_USER_SETTINGS_STATUS,
  UPDATED_ACCOUNT_USER_SETTINGS_STATUS
} from '../actions/actions'
import { Enums } from 'utils'

const initialState = {
  isLoading: false,
  emailUpdatesFrequencyDays: null
}

const accountUserSettings = createReducer(initialState, {
  LOADING_ACCOUNT_USER_SETTINGS_STATUS: (state, action) => { 
    state.isLoading = true
    state.emailUpdatesFrequencyDays = null
  },
  LOADED_ACCOUNT_USER_SETTINGS_STATUS: (state, action) => {
    state.isLoading = false
    state.emailUpdatesFrequencyDays = action.emailUpdatesFrequencyDays
  },
  UPDATING_ACCOUNT_USER_SETTINGS_STATUS: (state, action) => {
    state.isLoading = true
    state.emailUpdatesFrequencyDays = action.emailUpdatesFrequencyDays
  },
  UPDATED_ACCOUNT_USER_SETTINGS_STATUS: (state, action) => {
    state.isLoading = false
    state.emailUpdatesFrequencyDays = action.emailUpdatesFrequencyDays
  }
})

export default accountUserSettings