import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import EnhancedTableHead from '../googleAnalytics/EnhancedTableHead'
import Checkbox from '@material-ui/core/Checkbox'
import EnhancedTableToolbar from '../keywords/EnhancedTableToolbar'
import AttributedInstallsTable from '../attributedInstalls/AttributedInstallsTable'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import {ExpandMore, ExpandLess} from '@material-ui/icons'
import {Collapse} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import React from 'react'

class SurfacesTableNew extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
      order: 'desc',
      orderBy: 'page_views',
      selectedRows: [],
      expandedRowId: null,
      expandedSurfaceId: null,
      expandedLocale: null
    }
    this.onChangePage = this.onChangePage.bind(this)
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this)
    this.onRequestSort = this.onRequestSort.bind(this)
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this)
    this.onAdd = this.onAdd.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.expandedRowId !== this.state.expandedRowId && this.state.expandedRowId !== null) {
      this.props.onExpandRow(this.state.expandedSurfaceId, this.state.expandedLocale)
    }
  }

  onChangePage(e, newPage) {
    this.setState({
      page: newPage
    })
  }

  onChangeRowsPerPage(e) {
    this.setState({
      rowsPerPage: parseInt(e.target.value, 10),
      page: 0
    })
  }

  onRequestSort(e, property) {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc'
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property
    })
  }

  onSelectRow(id) {
    const selectedIndex = this.state.selectedRows.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selectedRows, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selectedRows.slice(1))
    } else if (selectedIndex === this.state.selectedRows.length - 1) {
      newSelected = newSelected.concat(this.state.selectedRows.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selectedRows.slice(0, selectedIndex),
        this.state.selectedRows.slice(selectedIndex + 1),
      )
    }

    this.setState({
      selectedRows: newSelected
    })
  }

  handleSelectAllClick(e) {
    if (e.target.checked) {
      const newSelecteds = this.props.data.map((row) => this.rowIdFromRow(row))
      this.setState({
        selectedRows: newSelecteds
      })
      return
    }
    this.setState({
      selectedRows: []
    })
  }

  onAdd() {
    this.state.selectedRows.forEach((rowId) => {
      this.props.onAddKeyword(this.surfaceDetailFromRowId(rowId))
    })
    this.setState({
      selectedRows: []
    })
  }

  rowIdFromRow(row) {
    return `${row.surface_detail}~~${row.surface_type}${row.locale}`
  }

  surfaceDetailFromRowId(rowId) {
    return rowId.split('~~')[0]
  }

  render() {
    const comparing = this.props.headerCompareTitleLabel !== undefined && this.props.headerCompareTitleLabel !== null
    const showPageViews = this.props.hideTimeSeriesDatasets === undefined || this.props.hideTimeSeriesDatasets.findIndex(x => x === 'page_views') === -1
    const showInstalls = this.props.hideTimeSeriesDatasets === undefined || this.props.hideTimeSeriesDatasets.findIndex(x => x === 'installs') === -1
    const showInstallRate = this.props.hideTimeSeriesDatasets === undefined || this.props.hideTimeSeriesDatasets.findIndex(x => x === 'install_rate') === -1
    const showAveragePosition = this.props.hideTimeSeriesDatasets === undefined || this.props.hideTimeSeriesDatasets.findIndex(x => x === 'average_position') === -1
    const showShopCount = this.props.enableAttribution && (this.props.hideTimeSeriesDatasets === undefined || this.props.hideTimeSeriesDatasets.findIndex(x => x === 'shop_count') === -1)

    let headCells = null
    if (comparing === true) {
      headCells = [
        { id: 'surface_type', numeric: false, disablePadding: false, label: 'Surface Type' },
        { id: 'surface_detail', numeric: false, disablePadding: false, label: 'Surface Detail' },
        { id: 'locale', numeric: false, disablePadding: false, label: 'Locale' },
      ]
      if (showPageViews === true) {
        headCells.push(
          { id: 'page_views', numeric: true, disablePadding: false, label: <div>{this.props.headerTitleLabel}<br/>Page Views</div> },
          { id: 'compare_page_views', numeric: true, disablePadding: false, label: <div>{this.props.headerCompareTitleLabel}<br/>Page Views</div> },
        )
      }
      if (showInstalls === true) {
        headCells.push(
          { id: 'installs', numeric: true, disablePadding: false, label: <div>{this.props.headerTitleLabel}<br/>Install Clicks</div> },
          { id: 'compare_installs', numeric: true, disablePadding: false, label: <div>{this.props.headerCompareTitleLabel}<br/>Install Clicks</div> },
        )
      }
      if (showInstallRate === true) {
        headCells.push(
          { id: 'install_rate', numeric: true, disablePadding: false, label: <div>{this.props.headerTitleLabel}<br/>Install Rate</div> },
          { id: 'compare_install_rate', numeric: true, disablePadding: false, label: <div>{this.props.headerCompareTitleLabel}<br/>Install Rate</div> },
        )
      }
      if (showAveragePosition === true) {
        headCells.push(
          { id: 'average_position', numeric: true, disablePadding: false, label: <div>{this.props.headerTitleLabel}<br/>Average Position</div> },
          { id: 'compare_average_position', numeric: true, disablePadding: false, label: <div>{this.props.headerCompareTitleLabel}<br/>Average Position</div> },
        )
      }
    } else {
      headCells = [
        { id: 'surface_type', numeric: false, disablePadding: false, label: 'Surface Type' },
        { id: 'surface_detail', numeric: false, disablePadding: false, label: 'Surface Detail' },
        { id: 'locale', numeric: false, disablePadding: false, label: 'Locale' },
      ]
      if (showPageViews === true) {
        headCells.push(
          { id: 'page_views', numeric: true, disablePadding: false, label: 'Page Views' },
        )
      }
      if (showInstalls === true) {
        headCells.push(
          { id: 'installs', numeric: true, disablePadding: false, label: 'Install Clicks' },
        )
      }
      if (showInstallRate === true) {
        headCells.push(
          { id: 'install_rate', numeric: true, disablePadding: false, label: 'Install Rate' },
        )
      }
      if (showAveragePosition === true) {
        headCells.push(
          { id: 'average_position', numeric: true, disablePadding: false, label: 'Average Position' },
        )
      }
      if (showShopCount === true) {
        headCells.push(
            { id: 'shop_count', numeric: true, disablePadding: false, label: 'Shops' },
        )
        headCells.push(
            { id: 'revenue_to_date', numeric: true, disablePadding: false, label: 'Revenue to Date' },
        )
        headCells.push(
            { id: 'shop_count_expand', numeric: false, disablePadding: false, label: '' },
        )
      }
    }

   const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.props.data.length - this.state.page * this.state.rowsPerPage)

   return (
     <React.Fragment>
        <TableContainer>
          {
            this.props.canSelect === true &&
              <EnhancedTableToolbar
                numSelected={this.state.selectedRows.length}
                selectedComponent={
                  (
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <Typography color="inherit" variant="subtitle1" component="div">
                          {this.state.selectedRows.length} selected
                        </Typography>
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <Tooltip title="Add">
                          <Button color='secondary' variant='outlined' onClick={this.onAdd}>Add</Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )
                }
                noneSelectedComponent={
                  (
                    <Typography>Select rows to add to Target Keywords</Typography>
                  )
                } />
          }
          <Table>
              <EnhancedTableHead
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.onRequestSort}
                rowCount={this.props.data.length}
                numSelected={this.state.selectedRows.length}
                headCells={headCells}
                { ...( this.props.canSelect === true && { onSelectAllClick: this.handleSelectAllClick } )}
              />
              <TableBody>
                {this.stableSort(this.props.data, this.getComparator(this.state.order, this.state.orderBy))
                  .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                  .map((row, index) => {
                    const rowId = this.rowIdFromRow(row)
                    const isItemSelected = this.state.selectedRows.indexOf(rowId) !== -1
                    const isRowExpanded = this.state.expandedRowId === rowId
                    return (
                      <React.Fragment>
                        <TableRow key={index} hover style={{ cursor: 'pointer' }} { ...( this.props.canSelect === false ? { onClick: (e) => this.props.onRowClick(e, row) } : { onClick: () => this.onSelectRow(rowId) } ) } selected={isItemSelected}>
                          {
                            this.props.canSelect === true &&
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                />
                              </TableCell>
                          }
                          <TableCell component="th" scope="row">{row.surface_type}</TableCell>
                          <TableCell>{row.surface_detail}</TableCell>
                          <TableCell>{row.locale}</TableCell>
                          {showPageViews === true ? <TableCell style={{ color: '#4285f4' }}>{row.page_views}</TableCell> : null }
                          { comparing === true && showPageViews === true ? <TableCell style={{ color: '#4285f4' }}>{row.compare_page_views}</TableCell> : null }
                          { showInstalls === true ? <TableCell style={{ color: '#5e35b1' }}>{row.installs}</TableCell> : null }
                          { comparing === true && showInstalls === true ? <TableCell style={{ color: '#5e35b1' }}>{row.compare_installs}</TableCell> : null }
                          { showInstallRate === true ? <TableCell style={{ color: '#e8710a' }}>{row.install_rate}%</TableCell> : null }
                          { comparing === true && showInstallRate === true ? <TableCell style={{ color: '#e8710a' }}>{row.compare_install_rate}%</TableCell> : null }
                          { showAveragePosition === true ? <TableCell style={{ color: '#00897b' }}>{row.average_position}</TableCell> : null }
                          { comparing === true && showAveragePosition === true ? <TableCell style={{ color: '#00897b' }}>{row.compare_average_position}</TableCell> : null }
                          { showShopCount === true ? <TableCell style={{ color: '#000' }}>{row.shop_count}</TableCell> : null }
                          { showShopCount === true ? <TableCell style={{ color: '#000' }}>{ row.revenue_to_date > 0 ? `$${row.revenue_to_date}` : null }</TableCell> : null }
                          <TableCell onClick={(e) => e.stopPropagation()}>
                            <IconButton aria-label="expand row" size="small" style={{ display: row.shop_count > 0 ? 'block' : 'none' }}>
                              {isRowExpanded ?
                                  <ExpandLess onClick={() => this.setState({ expandedRowId: null, expandedSurfaceId: null, expandedLocale: null })} />
                                  : <ExpandMore onClick={() => this.setState({ expandedRowId: rowId, expandedSurfaceId: row.surface_id, expandedLocale: row.locale })} /> }
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9999}>
                            <Collapse in={isRowExpanded} timeout="auto" unmountOnExit>
                              <AttributedInstallsTable data={this.props.attributedInstallsData} partnerApiOrganizationId={this.props.partnerApiOrganizationId} />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 1000]}
            component="div"
            count={this.props.data.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.onChangePage}
            onChangeRowsPerPage={this.onChangeRowsPerPage}
          />  
      </React.Fragment>
    )
  }

  descendingComparator(a, b, orderBy) {
    let a_for_comparison
    let b_for_comparison
    if (orderBy === 'install_rate' || orderBy === 'average_position' || orderBy === 'revenue_to_date') {
      a_for_comparison = Number(a[orderBy])
      b_for_comparison = Number(b[orderBy])
    } else if (orderBy === 'locale') {
      a_for_comparison = a[orderBy] === null ? '' : a[orderBy]
      b_for_comparison = b[orderBy] === null ? '' : b[orderBy]
    } else {
      a_for_comparison = a[orderBy]
      b_for_comparison = b[orderBy]
    }
    if (b_for_comparison < a_for_comparison) {
      return -1
    }
    if (b_for_comparison > a_for_comparison) {
      return 1
    }
    return 0
  }
  
  getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy)
  }
  
  stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }
}

SurfacesTableNew.defaultProps = {
  canSelect: false,
  enableAttribution: false
}

SurfacesTableNew.propTypes = {
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  headerTitleLabel: PropTypes.string,
  headerCompareTitleLabel: PropTypes.string,
  hiddenTimeSeriesDatasets: PropTypes.array,
  canSelect: PropTypes.bool.isRequired,
  onAddKeyword: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  enableAttribution: PropTypes.bool.isRequired,
  onExpandRow: PropTypes.func.isRequired,
  attributedInstallsData: PropTypes.array,
  partnerApiOrganizationId: PropTypes.string,
}

export default SurfacesTableNew