import PropTypes from 'prop-types'
import { Editor, EditorState, ContentState, CompositeDecorator } from 'draft-js'
import ListingKeywordDecorator from './ListingKeywordDecorator'

class ListingTextField extends React.Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.setEditor = this.setEditor.bind(this)
    this.focusEditor = this.focusEditor.bind(this)
    this.createDecorator = this.createDecorator.bind(this)
    this.handleStrategy = this.handleStrategy.bind(this)
    this.findWithRegex = this.findWithRegex.bind(this)

    this.state = {
      editorState: null,
      characterCount: 0
    }

    this.editorKeywords = []
  }

  componentDidMount() {
    this.setEditorKeywords()

    const initialText = this.props.initialText !== null && this.props.initialText !== undefined ? this.props.initialText : ''

    this.setState({
      editorState: EditorState.createWithContent(
        ContentState.createFromText(initialText),
        this.createDecorator()
      ),
      characterCount: initialText.length
    })
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.initialText &&
      prevProps.initialText !== this.props.initialText
    ) {
      const initialText = this.props.initialText !== null && this.props.initialText !== undefined ? this.props.initialText : ''
      this.setState({
        editorState: EditorState.createWithContent(
          ContentState.createFromText(initialText),
          this.createDecorator()
        ),
        characterCount: initialText.length
      })
    }

    if (prevProps.keywords?.length !== this.props.keywords?.length) {
      this.setEditorKeywords()
      this.setState({
        editorState: EditorState.set(this.state.editorState, {
          decorator: this.createDecorator()
        })
      })
    }
  }

  setEditorKeywords() {
    if (this.props.keywords?.length) {
      this.editorKeywords = this.props.keywords.map(
        (item) => new RegExp(`${item.keyword}`, 'gi') // [/hello/gi, /world/gi]
      )
    }
  }

  createDecorator() {
    return new CompositeDecorator([
      {
        strategy: this.handleStrategy,
        component: ({ children }) => {
          return (
            <ListingKeywordDecorator
              onMount={this.props.onKeywordMatch}
              onUnmount={this.props.onKeywordUnmatch}
            >
              {children}
            </ListingKeywordDecorator>
          )
        }
      }
    ])
  }

  handleStrategy(contentBlock, callback) {
    this.findWithRegex(this.editorKeywords, contentBlock, callback)
  }

  findWithRegex(words, contentBlock, callback) {
    const text = contentBlock.getText()

    words.forEach((word) => {
      const matches = [...text.matchAll(word)]
      matches.forEach((match) =>
        callback(match.index, match.index + match[0].length)
      )
    })
  }

  onChange(editorState) {
    const newCharacterCount = editorState
      .getCurrentContent()
      .getPlainText('').length
    this.setState({
      editorState,
      characterCount: newCharacterCount
    })
    if (this.state.characterCount !== newCharacterCount) {
      this.props.onChanged(editorState.getCurrentContent().getPlainText()) // Used to pass the value of the changed text
    }
  }

  setEditor(editor) {
    this.editor = editor
  }

  focusEditor() {
    if (this.editor) {
      this.editor.focus()
    }
  }

  render() {
    if (this.state.editorState === null) {
      return null
    }

    return (
      <div>
        <div>{this.props.label}</div>
        <div
          style={{
            border: '1px solid rgba(0, 0, 0, 0.42)',
            fontSize: '1rem',
            color: 'rgba(0, 0, 0, 0.54)',
            padding: '8px'
          }}
          onClick={this.focusEditor}
        >
          <Editor
            ref={this.setEditor}
            editorState={this.state.editorState}
            onChange={this.onChange}
            /*readOnly={this.props.readOnly}*/
          />
        </div>
        <div>
          {this.state.characterCount}/{this.props.maxChars}
        </div>
      </div>
    )
  }
}

ListingTextField.propTypes = {
  label: PropTypes.string.isRequired,
  keywords: PropTypes.array.isRequired,
  initialText: PropTypes.string,
  maxChars: PropTypes.number.isRequired,
  onChanged: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onKeywordMatch: PropTypes.func.isRequired,
  onKeywordUnmatch: PropTypes.func.isRequired
}

export default ListingTextField