import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Avatar from "@material-ui/core/Avatar"
import Divider from "@material-ui/core/Divider"
import SignalWifiOffIcon from "@material-ui/icons/SignalWifiOff"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { Fade } from "@material-ui/core"
import Box from '@material-ui/core/Box'

const styles = theme => ({
  paper: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`,
    marginLeft: `-${theme.spacing(3)}px`,
    marginRight: `-${theme.spacing(3)}px`,
    marginTop: `-${theme.spacing(3)}px`,
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    marginRight: `${theme.spacing(2)}px`
  },
  text: {
    marginRight: `${theme.spacing(1)}px`
  }
})

function Banner(props) {
  const { classes } = props

  if (props.banner.text === null) {
    return null
  }

  let icon = null
  if (props.banner.icon !== null) {
    icon = (
      <Box>
        <Avatar className={classes.avatar}>
          { props.banner.icon }
        </Avatar>
      </Box>
    )
  }
// <Divider style={{ marginBottom: '18px' }} />
  return (
    <React.Fragment>
      <Paper elevation={1} className={classes.paper} square>
        <Box display="flex" alignItems="center">
          { icon }
          <Box flexGrow={1}>
            <Typography className={classes.text}>
              { props.banner.text }
            </Typography>
          </Box>
          <Box>
            { props.banner.button }
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  const { banner } = state

  return { banner }
}

Banner.propTypes = {
  classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(withStyles(styles)(Banner))