import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import 'url-search-params-polyfill'
import { loadAccounts } from '../actions/actions'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import SomethingIsLoadingBar from '../components/navigation/SomethingIsLoadingBar'

// Responsibility: ensure accounts and accountApps loaded, render children once true
class AuthenticatedBaseContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    if (this.props.accounts.data === null && this.props.accounts.isLoading === false) {
      this.props.dispatch(loadAccounts())
    }
  }

  render() {
    // be conservative here, since we don't want lots of 'flashing' of the app and unmounting of components when data is loading
    if (this.props.accounts.data === null ||  this.props.accountApps.forAllAccounts === null) {
      return (
        <React.Fragment>
          <AppBar>
            <Toolbar style={{ paddingLeft: 18 }}>
              <img style={{ width: 185 }} alt="App Store Analytics" src="/assets/images/logo-white.png"/>
            </Toolbar>
            <SomethingIsLoadingBar />
          </AppBar>
          <Toolbar />
        </React.Fragment>
      )
    } else if (this.props.accounts.data.length < 1) {
      return 'You are currently not on the team of any account. Get invited to an account or sign up with a different email address.'
    } else {
      return this.props.children
    }
  }
}

function mapStateToProps(state) {
  const { accounts, accountApps } = state

  return { accounts, accountApps }
}

AuthenticatedBaseContainer.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  match: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(AuthenticatedBaseContainer)