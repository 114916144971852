import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import PersonIcon from '@material-ui/icons/Person'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Divider from '@material-ui/core/Divider'
import MenuIcon from '@material-ui/icons/Menu'
import SomethingIsLoadingBar from './SomethingIsLoadingBar'
import SettingsIcon from '@material-ui/icons/Settings'
import HelpIcon from '@material-ui/icons/Help'

class AuthenticatedAppBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        userMenuOpen: false,
        userMenuAnchorElement: null,
        settingsMenuOpen: false,
        settingsMenuAnchorElement: null,
    }
    this.closeUserMenu = this.closeUserMenu.bind(this)
    this.openUserMenu = this.openUserMenu.bind(this)
    this.closeSettingsMenu = this.closeSettingsMenu.bind(this)
    this.openSettingsMenu = this.openSettingsMenu.bind(this)
    this.renderLoggedInMenus = this.renderLoggedInMenus.bind(this)
    this.renderAnonMenus = this.renderAnonMenus.bind(this)
  }

  openUserMenu(event) {
    this.setState({ userMenuOpen: true, userMenuAnchorElement: event.currentTarget })
  }

  closeUserMenu() {
    this.setState({ userMenuOpen: false })
  }

  openSettingsMenu(event) {
    this.setState({ settingsMenuOpen: true, settingsMenuAnchorElement: event.currentTarget })
  }

  closeSettingsMenu() {
    this.setState({ settingsMenuOpen: false })
  }

  render() {
    return (
      <React.Fragment>
      <AppBar position="fixed" className={this.props.classes.appBar}>
        <Toolbar>

          <IconButton
            style={{ color: '#fff' }}
            aria-label="open drawer"
            edge="start"
            onClick={this.props.handleDrawerToggle}
            className={this.props.classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h5" noWrap>{ this.props.title ? this.props.title : null }</Typography>

          <Typography style={{ flexGrow: 1 }}></Typography>

          {/*<IconButton onClick={() => { Beacon('toggle') }} style={{ color: '#fff' }}>*/}
            {/*<HelpIcon />*/}
          {/*</IconButton>*/}

          {this.props.user.anonymous === true ? this.renderAnonMenus() : this.renderLoggedInMenus()}

        </Toolbar>
        <SomethingIsLoadingBar/>
      </AppBar>
      </React.Fragment>
    )
  }

  renderAnonMenus() {
    return (
      <React.Fragment>
        <Button component={Link} to='/sign-up' style={{ color: '#fff', borderColor: '#fff' }} variant='outlined'>Start Free Trial</Button>
        <Button component={Link} to='/sign-in' style={{ color: '#fff' }}>Sign In</Button>
      </React.Fragment>
    )
  }

  renderLoggedInMenus() {
    let accountsMenu = null
    if (this.props.accounts.isLoading === false && this.props.accounts.data && this.props.accounts.data.length > 1) {
      accountsMenu = [
        <MenuItem key='accountsMenuMenuItem' onClick={this.closeAvatarMenu} component={Link} to='/l/accounts'>Switch Account</MenuItem>,
        <Divider key='accountsMenuDivider' />
      ]
    }

    return (
      <React.Fragment>
        <IconButton onClick={this.openSettingsMenu} style={{ color: '#fff' }}>
            <SettingsIcon />
          </IconButton>
          <Menu disableEnforceFocus
            anchorEl={this.state.settingsMenuAnchorElement}
            keepMounted
            open={this.state.settingsMenuOpen}
            onClose={this.closeSettingsMenu}>
            <MenuItem onClick={this.closeSettingsMenu} component={Link} to='/l/google-account'>Google Account</MenuItem>
            <MenuItem onClick={this.closeSettingsMenu} component={Link} to='/l/partner-api'>Partner API</MenuItem>
            <MenuItem onClick={this.closeSettingsMenu} component={Link} to='/l/billing'>Billing</MenuItem>
            <MenuItem onClick={this.closeSettingsMenu} component={Link} to='/l/team'>Team</MenuItem>
            <MenuItem onClick={this.closeSettingsMenu}><a href='mailto:hello@appstoreanalytics.io' className='MuiButtonBase-root'>Support</a></MenuItem>
          </Menu>

          <IconButton onClick={this.openUserMenu} style={{ color: '#fff' }}>
            <PersonIcon />
          </IconButton>
          <Menu disableEnforceFocus
            anchorEl={this.state.userMenuAnchorElement}
            keepMounted
            open={this.state.userMenuOpen}
            onClose={this.closeUserMenu}>
            {accountsMenu}
            <MenuItem onClick={this.closeUserMenu} component={Link} to='/sign-out'>Sign Out</MenuItem>
          </Menu>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { user, googleAnalytics, team, accounts, accountApps, appListing } = state

  return { user, googleAnalytics, team, accounts, accountApps, appListing }
}

AuthenticatedAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  title: PropTypes.string
}

export default connect(mapStateToProps)(AuthenticatedAppBar)