import PropTypes from 'prop-types'
import 'url-search-params-polyfill'
import { Link, Redirect } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import moment from 'moment'
import 'url-search-params-polyfill'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'

class VersionTimelineItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    return (
      <Card>
        <CardContent>
          <Typography>
            {moment(this.props.change.changeDate).format('DD MMMM YYYY')}
            {
              this.props.currentVersion === true &&
              <Chip size="small" label="current version" style={{ marginLeft: 7 }} />
            }
            {
              this.props.firstVersion === true &&
              <Chip size="small" label="first recorded version" style={{ marginLeft: 7 }} />
            }
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" variant="outlined" color="secondary" onClick={() => { this.props.onChangeDateClick(this.props.change.changeDate) }}>
            View Changes
          </Button>
        </CardActions>
      </Card>
    )
  }
}

VersionTimelineItem.propTypes = {
  change: PropTypes.object.isRequired,
  currentVersion: PropTypes.bool.isRequired,
  firstVersion: PropTypes.bool.isRequired,
  ownApp: PropTypes.bool.isRequired,
  onChangeDateClick: PropTypes.func.isRequired
}

export default VersionTimelineItem