import { createReducer } from '@reduxjs/toolkit'
import {
  LOADING_ACCOUNTS_STATUS,
  LOADED_ACCOUNTS_STATUS,
  UPDATING_ACCOUNT_STATUS,
  UPDATED_ACCOUNT_STATUS,
  SWITCHING_ACCOUNT_STATUS,
  SWITCHED_ACCOUNT_STATUS
} from '../actions/actions'
import { Enums } from 'utils'

const initialState = {
  isLoading: false,
  currentAccount: null,
  currentAccountId: null,
  data: null
}

const accounts = createReducer(initialState, {
  LOADING_ACCOUNTS_STATUS: (state, action) => { 
    state.isLoading = true
    state.currentAccount = null
    // don't blat the currentAccountId as this should only be changed when switching accounts
    state.data = null
  },
  LOADED_ACCOUNTS_STATUS: (state, action) => {
    state.isLoading = false
    state.data = action.data
    let currentAccount = null
    if (action.currentAccountId !== null) {
      currentAccount = state.data.find(
        x => x.id === action.currentAccountId
      )
      state.currentAccount = currentAccount
      state.currentAccountId = action.currentAccountId
    } else if (state.currentAccountId === null) {
      currentAccount = state.data.find(
        x => x.isDefaultForUser === true
      )
      state.currentAccount = currentAccount
      state.currentAccountId = currentAccount.id
    } else {
      currentAccount = state.data.find(
        x => x.id === state.currentAccountId
      )
      state.currentAccount = currentAccount
    }
  },
  UPDATING_ACCOUNT_STATUS: (state, action) => {
    state.isLoading = true
  },
  UPDATED_ACCOUNT_STATUS: (state, action) => {
    state.isLoading = false
    let updatedAccount = state.data.find(
      x => x.id === action.accountId
    )
    if (action.googleAuthEmail !== null) {
      updatedAccount.googleAuthEmail = action.googleAuthEmail
      updatedAccount.googleAuthToken = action.googleAuthToken
      updatedAccount.googleAuthRefreshToken = action.googleAuthRefreshToken
    }
    if (action.partnerApiAccessToken !== null) {
      updatedAccount.partnerApiAccessToken = action.partnerApiAccessToken
      updatedAccount.partnerApiOrganizationId = action.partnerApiOrganizationId
    }
  },
  SWITCHED_ACCOUNT_STATUS: (state, action) => {
    state.isLoading = false
    state.currentAccount = state.data.find(
      x => x.id === action.accountId
    )
    state.currentAccountId = action.accountId
  },
})

export default accounts