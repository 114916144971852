import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem';
import {
  loadCompetitorRankings,
} from '../../actions/actions'

const styles = theme => ({
  rangeFilter: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(.5),
    },
  }
})



class PositionCompetitorDropdown extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        competitors: [],
        selected: '',
        selectedUrl: ''
      }
    }

    componentDidMount(){
        if(this.props.accountApps){
            const competitors = this.props.accountApps.forCurrentAccount.filter((accountApp) => accountApp.appType === "competitor")
            this.setState({ competitors: competitors })
        }
   
    }

    handleChange(event){
      const competitor = this.state.competitors.find((competitor) => competitor.id === event.target.value)
      this.setState({
        selected: event.target.value,
        selectedUrl: competitor.url
       }, () => {
        this.props.dispatch(loadCompetitorRankings(this.props.currentAccountAppId, this.state.selectedUrl, 'category', this.props.fromDate, this.props.toDate, this.props.groupBy))
        this.props.dispatch(loadCompetitorRankings(this.props.currentAccountAppId, this.state.selectedUrl, 'collection', this.props.fromDate, this.props.toDate, this.props.groupBy))
        this.props.dispatch(loadCompetitorRankings(this.props.currentAccountAppId, this.state.selectedUrl, 'search', this.props.fromDate, this.props.toDate, this.props.groupBy))
      })
      
    }

    render() {
      return (
        <FormControl fullWidth>
            <InputLabel id="drop-down-select-label">Competitors</InputLabel>
            <Select
                disabled={!this.state.competitors?.length}
                labelId="drop-down-select-label"
                id="simple-select"
                value={this.state.selected}
                label="Competitor"
                onChange={(event) => this.handleChange(event)}
            >
              {this.state.competitors.map((competitor, index) =>
                <MenuItem key={index} value={competitor.id}>{competitor.name}</MenuItem>
              )}
            </Select>
        </FormControl>
      )
        
    }
}


function mapStateToProps(state) {
    const { accountApps, rankings } = state
  
    return { accountApps, rankings }
}
  
export default connect(mapStateToProps)(withStyles(styles)(PositionCompetitorDropdown))