import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

class AccountUserSettings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        
    }
  }

  render() {
    return (
            <Typography variant="body1" component='div' style={{
            display: 'flex',
            alignItems: 'center'
        }}>
                Send an email&nbsp;{this.renderSelect()} when their app listings change
            </Typography>
    )
  }

  renderSelect() {
      if (this.props.isLoading === true && this.props.emailUpdatesFrequencyDays === null) {
        return (
          <Select MenuProps={{ disableEnforceFocus: true }}
          value={0}
          disabled={true}
          >
              <MenuItem value={0}></MenuItem>
          </Select>
        )
      }

      return (
            <Select MenuProps={{ disableEnforceFocus: true }}
            value={this.props.emailUpdatesFrequencyDays === null ? '' : this.props.emailUpdatesFrequencyDays}
            onChange={this.props.onSelectFrequency}
            disabled={this.props.isLoading === true}
            >
                <MenuItem value={-1}>Never</MenuItem>
                <MenuItem value={1}>Daily</MenuItem>
                <MenuItem value={7}>Weekly</MenuItem>
                <MenuItem value={28}>Monthly</MenuItem>
            </Select>
      )
  }
}

AccountUserSettings.propTypes = {
    emailUpdatesFrequencyDays: PropTypes.number,
    isLoading: PropTypes.bool.isRequired,
    onSelectFrequency: PropTypes.func.isRequired
}

export default AccountUserSettings