import { createReducer } from '@reduxjs/toolkit'
import {
  CHECKING_GA_STATUS,
  CHECKED_GA_STATUS,
  CHECKING_GA_GOAL_STATUS,
  CHECKED_GA_GOAL_STATUS,
  LOADING_GA_PROPERTIES_STATUS,
  LOADED_GA_PROPERTIES_STATUS,
  LOADING_SURFACE_TYPES_STATUS,
  LOADED_SURFACE_TYPES_STATUS,
  PERMISSIONS_ERROR_STATUS,
  UNKNOWN_ERROR_STATUS,
  CLEAR_GA_REPORTS_STATUS,
  LOADING_GA_REPORT_STATUS,
  LOADED_GA_REPORT_STATUS,
  LOADING_GA_CURRENT_TIME_SERIES_CHART_REPORT_STATUS,
  LOADED_GA_CURRENT_TIME_SERIES_CHART_REPORT_STATUS
} from '../actions/actions'
import { Enums } from 'utils'

const initialState = {
  status: Enums.GAStatus.NotChecked,
  goalStatus: {
    isLoading: false,
    data: [
      {
        accountAppId: null,
        setUp: false
      }
    ]
  },
  properties: {
    isLoading: false,
    data: [

    ]
  },
  surfaceTypes: {
    isLoading: false,
    data: [

    ]
  },
  currentTimeSeriesChartReport: {
    isLoading: false,
    errors: null,
    dateRangeStatus: null,
    data: null
  },
  currentTableReport: {

  },
  currentTotalsReport: {

  },
  reports: [
    {
      isLoading: false,
      errors: null,
      groupBy: null,
      surfaceType: 'all',
      locale: 'DUMMY... to design the state shape',
      surfaceDetail: null,
      group: 'all',
      fromDate: null,
      toDate: null,
      compareFromDate: null,
      compareToDate: null,
      dateRangeStatus: null,
      data: null
    }
  ]
}

const googleAnalytics = createReducer(initialState, {
  CHECKING_GA_STATUS: (state, action) => { 
    state.status = Enums.GAStatus.Checking
  },
  CHECKED_GA_STATUS: (state, action) => {
    state.status = action.googleAnalytics.status
  },
  CHECKING_GA_GOAL_STATUS: (state, action) => { 
    state.goalStatus.isLoading = true
  },
  CHECKED_GA_GOAL_STATUS: (state, action) => {
    state.goalStatus.isLoading = false
    let existingStatus = state.goalStatus.data.find(x => x.accountAppId === action.accountAppId)
    if (existingStatus === undefined) {
      state.goalStatus.data.push({
        accountAppId: action.accountAppId,
        setUp: action.setUp
      })
    } else {
      existingStatus.setUp = action.setUp
    }
  },
  LOADING_GA_PROPERTIES_STATUS: (state, action) => {
    state.properties.isLoading = true
    state.properties.data = []
  },
  LOADED_GA_PROPERTIES_STATUS: (state, action) => {
    state.properties.isLoading = false
    state.properties.data = action.googleAnalytics.data
  },
  LOADING_SURFACE_TYPES_STATUS: (state, action) => {
    state.surfaceTypes.isLoading = true
    state.surfaceTypes.data = []
  },
  LOADED_SURFACE_TYPES_STATUS: (state, action) => {
    state.surfaceTypes.isLoading = false
    state.surfaceTypes.data = action.surfaceTypes.data
  },
  PERMISSIONS_ERROR_STATUS: (state, action) => {
    state.properties.isLoading = false
    state.properties.data = []
    state.status = Enums.GAStatus.PermissionsError
  },
  UNKNOWN_ERROR_STATUS: (state, action) => {
    state.properties.isLoading = false
    state.properties.data = []
    state.status = Enums.GAStatus.UnknownError
  },
  CLEAR_GA_REPORTS_STATUS: (state, action) => {
    state.reports = []
  },
  LOADING_GA_REPORT_STATUS: (state, action) => {
    upsertReportInState(state, action.googleAnalytics.report, true)
  },
  LOADED_GA_REPORT_STATUS: (state, action) => {
    upsertReportInState(state, action.googleAnalytics.report, false)
  },
  LOADING_GA_CURRENT_TIME_SERIES_CHART_REPORT_STATUS: (state, action) => {
    state.currentTimeSeriesChartReport = {
      isLoading: true,
      errors: null,
      dateRangeStatus: null,
      data: null
    }
    upsertReportInState(state, action.googleAnalytics.report, true)
  },
  LOADED_GA_CURRENT_TIME_SERIES_CHART_REPORT_STATUS: (state, action) => {
    let stateReport = upsertReportInState(state, action.googleAnalytics.report, false)
    state.currentTimeSeriesChartReport = stateReport

    const allDatasets = ['page_views','installs','average_position','install_rate','page_views_compare','installs_compare','average_position_compare','install_rate_compare']
    allDatasets.forEach(d => {
      const dataIndex = state.currentTimeSeriesChartReport.data.findIndex(x => x.name == d)
      if (dataIndex !== -1 && action.googleAnalytics.report.groupBy !== 'Century') {
        const hidden = action.googleAnalytics.report.hideDatasets.length > 0 && action.googleAnalytics.report.hideDatasets.indexOf(d) !== -1
        state.currentTimeSeriesChartReport.data[dataIndex].library.hidden = hidden
      }
    })
  }
})

function colorize(groupBy, data, name, color, dashed = false) {
  let dataIndex = data.findIndex(x => x.name == name)
  if (dataIndex !== -1 && groupBy !== 'Century' && data[dataIndex].library == undefined) {
     data[dataIndex].library = {
      borderColor: color,
      borderDash: dashed === true ? [7,3] : [100,0],
      backgroundColor: color,
      pointBackgroundColor: color,
      pointBorderColor: color,
      pointHoverBackgroundColor: color,
      pointHoverBorderColor: color
     }
  }
}

function upsertReportInState(state, report, isLoading) {
  let stateReport = getReportFromState(state, report)

  if (isLoading === false) {
    // somewhat strange we're dealing with colors in the store, but this is to avoid cloning/mutating data since the chart component blends these in its properties
    colorize(report.groupBy, report.data, 'page_views', '#4285f4')
    colorize(report.groupBy, report.data, 'installs', '#5e35b1')
    colorize(report.groupBy, report.data, 'average_position', '#00897b')
    colorize(report.groupBy, report.data, 'install_rate', '#e8710a')
    colorize(report.groupBy, report.data, 'page_views_compare', '#4285f4', true)
    colorize(report.groupBy, report.data, 'installs_compare', '#5e35b1', true)
    colorize(report.groupBy, report.data, 'average_position_compare', '#00897b', true)
    colorize(report.groupBy, report.data, 'install_rate_compare', '#e8710a', true)
  }

  if (stateReport === undefined) {
    stateReport = {
      isLoading: isLoading,
      errors: isLoading === true ? null : report.errors,
      accountAppId: report.accountAppId,
      groupBy: report.groupBy,
      surfaceType: report.surfaceType,
      locale: report.locale,
      surfaceDetail: report.surfaceDetail,
      group: report.group,
      fromDate: report.fromDate,
      toDate: report.toDate,
      compareFromDate: report.compareFromDate,
      compareToDate: report.compareToDate,
      dateRangeStatus: isLoading === true ? null : report.dateRangeStatus,
      data: isLoading === true ? null : report.data
    }
    state.reports.push(stateReport)
  } else {
    stateReport.isLoading = isLoading
    stateReport.errors = isLoading === true ? null : report.errors
    stateReport.dateRangeStatus = isLoading === true ? null : report.dateRangeStatus
    stateReport.data = isLoading === true ? null : report.data
  }
  return stateReport
}

function getReportFromState(state, actionReport) {
  return state.reports.find(
    x => x.accountAppId === actionReport.accountAppId
    && x.groupBy === actionReport.groupBy
    && x.surfaceType === actionReport.surfaceType
    && x.locale === actionReport.locale
    && x.surfaceDetail === actionReport.surfaceDetail
    && x.group === actionReport.group
    && x.fromDate === actionReport.fromDate
    && x.toDate === actionReport.toDate
    && x.compareFromDate === actionReport.compareFromDate
    && x.compareToDate === actionReport.compareToDate)
}

export default googleAnalytics