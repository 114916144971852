import { createReducer } from '@reduxjs/toolkit'
import {
  CREATING_CHECKOUT_SESSION_STATUS,
  CREATED_CHECKOUT_SESSION_STATUS,
  LOADING_SUBSCRIPTION_STATUS,
  LOADED_SUBSCRIPTION_STATUS,
  CHANGING_SUBSCRIPTION_STATUS,
  CHANGED_SUBSCRIPTION_STATUS
} from '../actions/actions'
import { Enums } from 'utils'

const initialState = {
  checkoutSession: {
    isLoading: false,
    id: null
  },
  subscription: {
    isLoading: false,
    activeStripePrice: null
  },
  portalSession: {
    isLoading: false,
    portalUrl: null
  }
}

const billing = createReducer(initialState, {
  CREATING_CHECKOUT_SESSION_STATUS: (state, action) => { 
    state.checkoutSession = {
      isLoading: true,
      id: null
    }
  },
  CREATED_CHECKOUT_SESSION_STATUS: (state, action) => {
    state.checkoutSession = {
      isLoading: false,
      id: action.sessionId
    }
  },
  CREATING_PORTAL_SESSION_STATUS: (state, action) => { 
    state.portalSession = {
      isLoading: true,
      portalUrl: null
    }
  },
  CREATED_PORTAL_SESSION_STATUS: (state, action) => {
    state.portalSession = {
      isLoading: false,
      portalUrl: action.portalUrl
    }
  },
  LOADING_SUBSCRIPTION_STATUS: (state, action) => {
    state.subscription = {
      isLoading: true,
      activeStripePrice: null
    }
  },
  LOADED_SUBSCRIPTION_STATUS: (state, action) => {
    state.subscription = {
      isLoading: false,
      activeStripePrice: action.activeStripePrice
    }
  },
  CHANGING_SUBSCRIPTION_STATUS: (state, action) => {
    state.subscription = {
      isLoading: true,
      activeStripePrice: null
    }
  },
  CHANGED_SUBSCRIPTION_STATUS: (state, action) => {
    state.subscription = {
      isLoading: false,
      activeStripePrice: action.activeStripePrice
    }
  }
})

export default billing