import PropTypes from 'prop-types'
import { showHelp } from '../../actions/actions'
import { LineChart } from 'react-chartkick'
import 'chart.js'
import 'chartjs-plugin-annotation'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import HelpIcon from '@material-ui/icons/Help'

class PositionsChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <Paper style={{ padding: 24 }}>
        <Grid container spacing={2}>
          <Grid container item xs={12} alignItems="center">
            <Grid item xs>
              <Typography variant='h6'>
                {this.props.title}
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'right' }}>
              <IconButton onClick={this.props.onShowHelp}>
                <HelpIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <LineChart
              library={{
                spanGaps: true,
                scales: {
                  yAxes: [{
                    ticks: {
                      min: 1,
                      reverse: true,
                    }
                  }]
                }
              }}
              data={this.props.data}
              dataset={{
                lineTension: 0.05,
                pointRadius: 3,
                pointHitRadius: 20
              }}
            />
          </Grid>
          <Grid container item md={12} alignItems="center">
            <Grid item xs>
            </Grid>
            <Grid item xs={8} m={4} style={{ textAlign: 'right' }}>
              {this.props.actionButton}
            </Grid> 
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

PositionsChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onShowHelp: PropTypes.func.isRequired,
  actionButton: PropTypes.element
}

export default PositionsChart