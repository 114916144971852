import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { signOut } from '../../actions/actions'

class SignOutContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.dispatch(signOut())
  }

  render() {
    return this.props.user.isAuthenticated === false ? <Redirect to='/sign-in' /> : null
  }
}

function mapStateToProps(state) {
  const { user } = state

  return {
    user
  }
}
export default connect(mapStateToProps)(SignOutContainer)