import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { switchAccountApp, loadAllApps, addAccountApp } from '../../actions/actions'
import { ApiUtils, Enums } from 'utils'
import AddBoxIcon from '@material-ui/icons/AddBox'
import Avatar from '@material-ui/core/Avatar'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import HistoryIcon from '@material-ui/icons/History'
import SearchIcon from '@material-ui/icons/Search'
import AppSearch from '../apps/AppSearch'

class SelectAccountApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        accountAppsMenuOpen: false,
        accountAppsMenuAnchorElement: null,
        quickLookupOpen: false,
    }
    this.closeAccountAppsMenu = this.closeAccountAppsMenu.bind(this)
    this.openAccountAppsMenu = this.openAccountAppsMenu.bind(this)
    this.onQuickLookup = this.onQuickLookup.bind(this)
  }

  componentDidMount() {
    if (this.state.accountAppsMenuOpen === true && this.props.apps.all.data === null && this.props.apps.all.isLoading === false) {
      this.props.dispatch(loadAllApps())
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.accountAppsMenuOpen === true && prevState.accountAppsMenuOpen === false && this.props.apps.all.data === null && this.props.apps.all.isLoading === false) {
      this.props.dispatch(loadAllApps())
    }
  }

  openAccountAppsMenu(event) {
    this.setState({ accountAppsMenuOpen: true, quickLookupOpen: true, accountAppsMenuAnchorElement: event.currentTarget })
  }

  closeAccountAppsMenu() {
    this.setState({ accountAppsMenuOpen: false, quickLookupOpen: false })
  }

  onQuickLookup(selectedApp) {
    this.props.dispatch(addAccountApp(
        this.props.accounts.currentAccountId,
        selectedApp.apps_id,
        Enums.AppType.Lookup,
        null,
        null,
        null,
        null,
        selectedApp.url,
        selectedApp.app_name,
        true))

    this.setState({ quickLookupOpen: false, accountAppsMenuOpen: false }, () => {
      this.props.onNavigate()
      //this.props.dispatch(quickLookupAccountApp(this.props.accounts.currentAccountId, selectedApp))
      
    })
  }

  render() {
    let accountAppsMenuOwnApps = []
    let accountAppsMenuCompetitors = []

    if (this.props.accountApps.forCurrentAccount && this.props.accountApps.forCurrentAccount.length > 0) {
      this.props.accountApps.forCurrentAccount.filter(x => x.appType !== Enums.AppType.Lookup).forEach(accountApp => {
        const primaryText = accountApp.name === accountApp.url ? accountApp.url : accountApp.name
        const secondaryText = accountApp.name === accountApp.url ? null : accountApp.url
        const target = accountApp.appType === Enums.AppType.Competitor ? accountAppsMenuCompetitors : accountAppsMenuOwnApps
        target.push(
          <MenuItem key={accountApp.id} onClick={ () => {
            this.props.dispatch(switchAccountApp(accountApp.id))
            this.closeAccountAppsMenu()
           } }>
            <ListItemAvatar>
              <Avatar alt={ accountApp.urlPath }
              variant="rounded"
              src={ ApiUtils.apiUrl(`/app_listing/icon?path=${ accountApp.urlPath }`) } />
              </ListItemAvatar>
            <ListItemText primary={primaryText} secondary={secondaryText} />
          </MenuItem>
        )
      })
    }

    let accountAppsMenuButton = null
    if (this.props.accountApps.currentAccountApp) {
      if (accountAppsMenuOwnApps.length > 0) {
        accountAppsMenuOwnApps.unshift(<ListSubheader key='aamoaSubA'>Own Apps</ListSubheader>)
      }
      if (accountAppsMenuCompetitors.length > 0) {
        accountAppsMenuCompetitors.unshift(<ListSubheader key='aamoaSubC'>Competitors</ListSubheader>)
      }

      accountAppsMenuButton = [
        this.renderMenuButton(),
        <Menu disableEnforceFocus
          key='accountAppsMenu'
          anchorEl={this.state.accountAppsMenuAnchorElement}
          keepMounted
          open={this.state.accountAppsMenuOpen}
          onClose={this.closeAccountAppsMenu}>
          {accountAppsMenuOwnApps}
          <MenuItem key='accountAppsMenuAddAppOwn' onClick={this.closeAccountAppsMenu} component={Link} to='/l/home?add'>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary='Track Another' />
          </MenuItem>
          {accountAppsMenuCompetitors}
          <MenuItem key='accountAppsMenuAddAppCompetitor' onClick={this.closeAccountAppsMenu} component={Link} to='/l/home?add'>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary='Track Another' />
          </MenuItem>
          <Divider style={{ marginTop: 8, marginBottom: 8 }} />
          {this.renderQuickLookup()}
        </Menu>
      ]
      if (this.props.accountApps.currentAccountApp.appType === Enums.AppType.Own) {
        accountAppsMenuButton.push(
          <ListItem key='sapDash' button component={Link} to='/l/app-listing/traffic' onClick={this.props.onNavigate} selected={this.props.location.pathname === '/l/app-listing/traffic'}>
            <ListItemIcon>
              <ShowChartIcon />
            </ListItemIcon>
            <ListItemText primary='App Listing Traffic' />
          </ListItem>
        )
      }
      accountAppsMenuButton.push(
        <ListItem key='sapHist' button component={Link} to={`/l/app-listing/history`} onClick={this.props.onNavigate} selected={this.props.location.pathname === '/l/app-listing/history'}>
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText primary='App Listing History' />
        </ListItem>
      )
    }
    return accountAppsMenuButton
  }

  renderMenuButton() {
    let currentApp = this.props.accountApps.currentAccountApp

    const primaryText = currentApp.name === currentApp.url ? currentApp.url : currentApp.name
    return (
      <ListItem key='accountAppsMenuButtonListItem' button onClick={this.openAccountAppsMenu}>
            <ListItemAvatar>
                <Avatar
                    alt={ currentApp.urlPath }
                    variant="rounded"
                    src={ ApiUtils.apiUrl(`/app_listing/icon?path=${ currentApp.urlPath }`) }
                />
            </ListItemAvatar>
            <ListItemText secondary={primaryText} />
            <ExpandMoreIcon edge="end" />
        </ListItem>
    )
  }

  renderQuickLookup() {
    let littleAppSearch = null
    if (this.state.quickLookupOpen === true) {
      littleAppSearch = (
        <ListItem key='littleAppSearch'>
          <AppSearch isLoading={this.props.apps.all.isLoading || this.props.apps.all.data === null} allApps={this.props.apps.all.data} onChange={this.onQuickLookup} appType={Enums.AppType.Lookup} />
        </ListItem>
      )
    }
    // onClick={() => this.setState({ quickLookupOpen: !this.state.quickLookupOpen })}
    return ([
      <MenuItem key='accountAppsMenuQuickLookup' disabled style={{ opacity: 1 }} >
        <ListItemIcon>
          <SearchIcon />
        </ListItemIcon>
        <ListItemText primary='Quick Lookup'/>
      </MenuItem>,
      littleAppSearch
    ])
  }
}

function mapStateToProps(state) {
  const { accounts, accountApps, apps } = state

  return { accounts, accountApps, apps }
}

SelectAccountApp.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(SelectAccountApp)