import PropTypes from 'prop-types'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Link } from 'react-router-dom'

class CompareInAnalyticsDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isToday: null,
      isFirstRecordedVersion: null,
      unequalDaysRunning: null,

      listingFromDateFriendly: null,
      listingToDateFriendly: null,
      listingDaysRunning: null,

      listingPreviousFromDateFriendly: null,
      listingPreviousToDateFriendly: null,
      listingPreviousDaysRunning: null,

      selectedFromDate: null,
      selectedToDate: null,
      selectedFromToTotalDays: null,

      selectedPreviousFromDate: null,
      selectedPreviousToDate: null,
      selectedPreviousFromToTotalDays: null,
    }
  }

  componentDidMount() {
    this.initState()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedFromDate !== prevState.selectedFromDate || this.state.selectedToDate !== prevState.selectedToDate) {
      const selectedFromToTotalDays = moment(this.state.selectedToDate).diff(moment(this.state.selectedFromDate), 'days') + 1
      this.setState({
        selectedFromToTotalDays
      })
    }

    if (this.state.selectedPreviousFromDate !== prevState.selectedPreviousFromDate || this.state.selectedPreviousToDate !== prevState.selectedPreviousToDate) {
      const selectedPreviousFromToTotalDays = moment(this.state.selectedPreviousToDate).diff(moment(this.state.selectedPreviousFromDate), 'days') + 1
      this.setState({
        selectedPreviousFromToTotalDays
      })
    }
  }

  initState() {
    const targetSnapshot = this.props.targetSnapshot

    if (targetSnapshot.previousListingStartDate === null) {
      this.setState({
        isFirstRecordedVersion: true
      })
      return
    }

    const today = moment()

    const listingFromDate = moment(targetSnapshot.currentListingStartDate)
    const listingToDate = targetSnapshot.currentListingEndDate === null ? today : moment(targetSnapshot.currentListingEndDate)
    const listingPreviousFromDate = moment(targetSnapshot.previousListingStartDate)
    const listingPreviousToDate = moment(targetSnapshot.previousListingEndDate)

    if (listingFromDate.format('YYYY-MM-DD') === today.format('YYYY-MM-DD')) {
      this.setState({
        isFirstRecordedVersion: false,
        isToday: true
      })
      return
    }

    const listingDaysRunning = listingToDate.diff(listingFromDate, 'days') + (listingToDate === today ? 0 : 1)
    const listingPreviousDaysRunning = listingPreviousToDate.diff(listingPreviousFromDate, 'days') + 1

    let selectedFromDate = listingFromDate
    let selectedToDate = listingToDate === today ? listingToDate.subtract(1, 'day') : listingToDate
    let selectedFromToTotalDays = selectedToDate.diff(selectedFromDate, 'days') + 1

    let selectedPreviousFromDate = listingPreviousFromDate
    let selectedPreviousToDate = listingPreviousToDate
    let selectedPreviousFromToTotalDays = selectedPreviousToDate.diff(selectedPreviousFromDate, 'days') + 1

    let unequalDaysRunning = false
    if (selectedFromToTotalDays === 1 && listingToDate === today) {
      selectedPreviousToDate = selectedFromDate.clone().subtract(1, 'day')
      selectedPreviousFromDate = selectedPreviousToDate
      selectedPreviousFromToTotalDays = 1
      unequalDaysRunning = true
    } else if (selectedFromToTotalDays > selectedPreviousFromToTotalDays) {
      selectedToDate = selectedFromDate.clone().add(selectedPreviousFromToTotalDays - 1, 'days')
      selectedFromToTotalDays = selectedPreviousFromToTotalDays
      unequalDaysRunning = true
    } else if (selectedFromToTotalDays < selectedPreviousFromToTotalDays) {
      selectedPreviousFromDate = selectedPreviousToDate.clone().subtract(selectedFromToTotalDays - 1, 'days')
      selectedPreviousFromToTotalDays = selectedFromToTotalDays
      unequalDaysRunning = true
    }

    this.setState({
      isFirstRecordedVersion: false,
      isToday: false,

      listingFromDateFriendly: listingFromDate.format('DD MMMM YYYY'),
      listingToDateFriendly: listingToDate === today ? 'present' : listingToDate.format('DD MMMM YYYY'),
      listingDaysRunning,

      listingPreviousFromDateFriendly: listingPreviousFromDate.format('DD MMMM YYYY'),
      listingPreviousToDateFriendly: listingPreviousToDate.format('DD MMMM YYYY'),
      listingPreviousDaysRunning,

      unequalDaysRunning,

      selectedFromDate,
      selectedToDate,
      selectedFromToTotalDays,

      selectedPreviousFromDate,
      selectedPreviousToDate,
      selectedPreviousFromToTotalDays
    })
  }

  render() {
    if (this.props.open === false || this.state.isFirstRecordedVersion === null) {
      return null
    }

    let compareDisabled = false

    let content = null
    if (this.state.isFirstRecordedVersion === true) {
      content = (
        <Grid item xs={12}>
          <Alert severity="info">This is the first recorded version of your App Listing. There are no previous versions to compare it to. Go back and select a later App Listing.</Alert>
        </Grid>
      )
      compareDisabled = true
    } else if (this.state.isToday === true) {
      content = (
        <Grid item xs={12}>
          <Alert severity="info">This App Listing has been created today. Check back tomorrow when Analytics will be available.</Alert>
        </Grid>
      )
      compareDisabled = true
    } else {
      content = (
        <React.Fragment>
          <Grid>
            { this.state.unequalDaysRunning === true && (
              <Grid item xs={12}>
                <Alert severity="info">These App Listings were running for a different number of days.<br/>The start and end dates have been adjusted to create an equal number of days for comparison.</Alert>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' style={{ textAlign: 'center' }}>This App Listing</Typography>
            <Typography style={{ textAlign: 'center' }}>Running {this.state.listingFromDateFriendly} – {this.state.listingToDateFriendly} ({this.state.listingDaysRunning > 1 ? `${this.state.listingDaysRunning} days` : `${this.state.listingDaysRunning} day`})</Typography>
            <Grid container xs={12} direction="row" alignItems="flex-end">
              <Grid item xs={5}>
                <KeyboardDatePicker
                  PopoverProps={{ disableEnforceFocus: true }}
                  disableToolbar
                  variant="inline"
                  format="DD MMMM YYYY"
                  margin="normal"
                  label="Start date"
                  value={this.state.selectedFromDate}
                  autoOk={true}
                  onChange={(date) => { this.setState({ selectedFromDate: date }) }}
                />
              </Grid>
              <Grid item xs={5}>
                <KeyboardDatePicker
                  PopoverProps={{ disableEnforceFocus: true }}
                  disableToolbar
                  variant="inline"
                  format="DD MMMM YYYY"
                  margin="normal"
                  label="End date"
                  value={this.state.selectedToDate}
                  autoOk={true}
                  onChange={(date) => { this.setState({ selectedToDate: date }) }}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ marginBottom: 12, textAlign: 'right' }}>{this.state.selectedFromToTotalDays > 1 ? `${this.state.selectedFromToTotalDays} days` : `${this.state.selectedFromToTotalDays} day`}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5' style={{ textAlign: 'center' }}>vs.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' style={{ textAlign: 'center' }}>Previous App Listing</Typography>
            <Typography style={{ textAlign: 'center' }}>Running {this.state.listingPreviousFromDateFriendly} – {this.state.listingPreviousToDateFriendly} ({this.state.listingPreviousDaysRunning > 1 ? `${this.state.listingPreviousDaysRunning} days` : `${this.state.listingPreviousDaysRunning} day`})</Typography>
            <Grid container xs={12} direction="row" alignItems="flex-end">
              <Grid item xs={5}>
                <KeyboardDatePicker
                  PopoverProps={{ disableEnforceFocus: true }}
                  disableToolbar
                  variant="inline"
                  format="DD MMMM YYYY"
                  margin="normal"
                  label="Start date"
                  value={this.state.selectedPreviousFromDate}
                  autoOk={true}
                  onChange={(date) => { this.setState({ selectedPreviousFromDate: date }) }}
                />
              </Grid>
              <Grid item xs={5}>
                <KeyboardDatePicker
                  PopoverProps={{ disableEnforceFocus: true }}
                  disableToolbar
                  variant="inline"
                  format="DD MMMM YYYY"
                  margin="normal"
                  label="End date"
                  value={this.state.selectedPreviousToDate}
                  autoOk={true}
                  onChange={(date) => { this.setState({ selectedPreviousToDate: date }) }}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ marginBottom: 12, textAlign: 'right' }}>{this.state.selectedPreviousFromToTotalDays > 1 ? `${this.state.selectedPreviousFromToTotalDays} days` : `${this.state.selectedPreviousFromToTotalDays} day`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )
    }

    return (
      <Dialog open={true}>
        <DialogTitle >Compare in Analytics</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {content}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>
            Cancel
          </Button>
          {
            compareDisabled === false &&
              <Button color="secondary" variant="outlined" disabled={compareDisabled} component={Link} to={`./listing-traffic?fd=${this.state.selectedFromDate.format('YYYY-MM-DD')}&td=${this.state.selectedToDate.format('YYYY-MM-DD')}&cfd=${this.state.selectedPreviousFromDate.format('YYYY-MM-DD')}&ctd=${this.state.selectedPreviousToDate.format('YYYY-MM-DD')}`}>
                Compare in Analytics
              </Button>
          }
          {
            compareDisabled === true &&
              <Button color="secondary" variant="outlined" disabled={compareDisabled}>
                Compare in Analytics
              </Button>
          }
        </DialogActions>
      </Dialog>
    )
  }
}

CompareInAnalyticsDialog.propTypes = {
  targetSnapshot: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default CompareInAnalyticsDialog