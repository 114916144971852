import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

class PlanCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isMantleUserPlan: this.props.isMantleUserAccount && this.props.stripePrice === CONFIG.stripeGrowthPrice
    }
  }


  render() {
    let description = null
    if (this.props.description !== undefined) {
      description = <Typography style={{ textAlign: 'center' }}>{this.props.description}</Typography>
    } else {
      description = (
        <React.Fragment>
          <Typography style={{ textAlign: 'center' }}>{this.props.appsLimit} Apps</Typography>
          <Typography style={{ textAlign: 'center' }}>{this.props.usersLimit} {this.props.usersLimit === 1 ? 'User' : 'Users'}</Typography>
          <Typography style={{ textAlign: 'center' }}><span style={{ textDecoration: (this.props.appsLimit > 10 || this.state.isMantleUserPlan) ? 'none' : 'line-through' }}>Attribution</span></Typography>
        </React.Fragment>
      )
    }
    return (
      <Card variant='outlined'>
        <CardContent>
          <Typography variant='h5' gutterBottom style={{ textAlign: 'center' }}>{this.props.name}</Typography>
          <Typography variant='h6' gutterBottom style={{ textAlign: 'center', color: '#8BC34A' }}>
            { this.state.isMantleUserPlan ? 'Free for MANTLE users' : '' }
          </Typography>

          <Typography variant='h6' gutterBottom style={{ textAlign: 'center', textDecoration: this.state.isMantleUserPlan ? 'line-through' : 'none' }}>
              ${this.props.price}/month
          </Typography>
          
          {description}

          {
            this.props.isMantleUserAccount && (this.props.stripePrice !== CONFIG.stripeGrowthPrice)  ? (
              <Typography variant='h6' gutterBottom>
                <br/>
              </Typography>
            ) : ''
          }
          

        </CardContent>
        {
          !this.props.isMantleUserAccount && (
            <CardActions>
              <Button variant='contained' color='primary' fullWidth={true} onClick={ () => this.props.onSelectPlan(this.props.stripePrice) } disabled={this.props.isLoading === true || this.props.isCurrentPlan === true || this.props.isMantleUserAccount == true}>
                { ( this.props.isCurrentPlan ) ? 'Your Current Plan' : 'Choose Plan' }
              </Button>
            </CardActions>
          )
        }
    </Card>
    )
  }
}

PlanCard.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  description: PropTypes.string,
  appsLimit: PropTypes.number.isRequired,
  usersLimit: PropTypes.number.isRequired,
  stripePrice: PropTypes.string.isRequired,
  onSelectPlan: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isCurrentPlan: PropTypes.bool.isRequired,
  isMantleUserAccount: PropTypes.bool.isRequired
}

export default PlanCard