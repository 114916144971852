import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { Link } from 'react-router-dom'
import {
  loadGAReport,
  loadGACurrentTimeSeriesChartReport,
  loadSurfaceTypes,
  loadAppListingHistory,
  showBanner,
  hideBanner,
  checkGAGoalStatus,
  loadAttributedInstalls
} from '../../actions/actions'
import TimeSeriesChart from '../../components/googleAnalytics/TimeSeriesChart'
import SurfacesTableNew from '../../components/googleAnalytics/SurfacesTableNew'
import TotalCard from '../../components/googleAnalytics/TotalCard'
import SelectSurfaceTypeDialog from '../../components/googleAnalytics/SelectSurfaceTypeDialog'
import ToFromDatesChip from '../../components/googleAnalytics/ToFromDatesChip'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import EditIcon from '@material-ui/icons/Edit'
import UpdateIcon from '@material-ui/icons/Update'
import ErrorIcon from '@material-ui/icons/Error'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import { Enums } from 'utils'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import 'react-diff-view/style/index.css'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import WarningIcon from '@material-ui/icons/Warning'
import {PriorityHigh} from '@material-ui/icons'

const styles = theme => ({
  rangeFilter: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(.5),
    }
  },
  paper: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    marginRight: `${theme.spacing(2)}px`
  },
  text: {
    marginRight: `${theme.spacing(1)}px`
  }
})

// Responsibility: load report data, refresh data when required (changing linked property), render errors loading report data
class AppListingTrafficContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedSurface: 'all',
      selectedSurfaceDetail: null,
      selectedTimeGrouping: 'Day',
      selectedLocale: 'all',
      selectedGroup: 'all',

      selectedFromDate: null,
      selectedToDate: null,

      selectedCompareFromDate: null,
      selectedCompareToDate: null,

      currentDateRangeValueLabel: null,
      currentDateRangeCompareValueLabel: null,
      
      showSelectSurfaceTypeDialog: false,

      hideTimeSeriesDatasets: []
    }

    this.handleSelectSurface = this.handleSelectSurface.bind(this)
    this.handleSelectToFromDates = this.handleSelectToFromDates.bind(this)
    this.handleNewDateRangeLabel = this.handleNewDateRangeLabel.bind(this)
    this.handleSelectSurfaceDetail = this.handleSelectSurfaceDetail.bind(this)
    this.handleSelectTimeGrouping = this.handleSelectTimeGrouping.bind(this)
    this.lazyLoadData = this.lazyLoadData.bind(this)
    this.handleRowClick = this.handleRowClick.bind(this)
    this.toggleHideDataset = this.toggleHideDataset.bind(this)
    this.handleAppListingChangeDateClick = this.handleAppListingChangeDateClick.bind(this)
    this.handleExpandRow = this.handleExpandRow.bind(this)
  }

  componentDidMount() {
    const sfq = this.getSelectionsFromQuery()
    this.setState({ 
      selectedSurface: sfq.querySurface,
      selectedSurfaceDetail: sfq.querySurfaceDetail,
      selectedTimeGrouping: sfq.queryTimeGrouping,
      selectedLocale: sfq.queryLocale,
      selectedGroup: sfq.queryGroup,
      selectedFromDate: sfq.queryFromDate,
      selectedToDate: sfq.queryToDate,
      selectedCompareFromDate: sfq.queryCompareFromDate,
      selectedCompareToDate: sfq.queryCompareToDate
    }, this.lazyLoadData)

    this.props.dispatch(showBanner(
        <React.Fragment>
          Some banner shown on componentDidMount
        </React.Fragment>,
        <UpdateIcon />
    ))
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.accountApps.currentAccountApp.googleAnalyticsProperty !== prevProps.accountApps.currentAccountApp.googleAnalyticsProperty || this.props.accountApps.currentAccountAppId !== prevProps.accountApps.currentAccountAppId) {
      this.lazyLoadData()
    }

    const sfq = this.getSelectionsFromQuery()
    if (prevProps.location.search !== this.props.location.search) {
      this.setState({ 
        selectedSurface: sfq.querySurface,
        selectedSurfaceDetail: sfq.querySurfaceDetail,
        selectedTimeGrouping: sfq.queryTimeGrouping,
        selectedLocale: sfq.queryLocale,
        selectedGroup: sfq.queryGroup,
        selectedFromDate: sfq.queryFromDate,
        selectedToDate: sfq.queryToDate,
        selectedCompareFromDate: sfq.queryCompareFromDate,
        selectedCompareToDate: sfq.queryCompareToDate
      }, this.lazyLoadData)
    } else if (
      (prevState.selectedSurface !== this.state.selectedSurface && this.state.selectedSurface !== sfq.querySurface)
      || (prevState.selectedSurfaceDetail !== this.state.selectedSurfaceDetail && this.state.selectedSurfaceDetail !== sfq.querySurfaceDetail)
      || (prevState.selectedTimeGrouping !== this.state.selectedTimeGrouping && this.state.selectedTimeGrouping !== sfq.queryTimeGrouping)
      || (prevState.selectedLocale !== this.state.selectedLocale && this.state.selectedLocale !== sfq.queryLocale)
      || (prevState.selectedGroup !== this.state.selectedGroup && this.state.selectedGroup !== sfq.queryGroup)
      || (prevState.selectedFromDate !== this.state.selectedFromDate && this.state.selectedFromDate !== sfq.queryFromDate)
      || (prevState.selectedToDate !== this.state.selectedToDate && this.state.selectedToDate !== sfq.queryToDate)
      || (prevState.selectedCompareFromDate !== this.state.selectedCompareFromDate && this.state.selectedCompareFromDate !== sfq.queryCompareFromDate)
      || (prevState.selectedCompareToDate !== this.state.selectedCompareToDate && this.state.selectedCompareToDate !== sfq.queryCompareToDate)
    ) {
      this.props.history.push(`${this.props.location.pathname}?s=${this.state.selectedSurface}&sd=${this.state.selectedSurfaceDetail === null ? '' : this.state.selectedSurfaceDetail}&tg=${this.state.selectedTimeGrouping}&l=${this.state.selectedLocale}&g=${this.state.selectedGroup}&fd=${this.state.selectedFromDate}&td=${this.state.selectedToDate}&cfd=${this.state.selectedCompareFromDate === null ? '' : this.state.selectedCompareFromDate}&ctd=${this.state.selectedCompareToDate === null ? '' : this.state.selectedCompareToDate}`)
    }

    // since the date range applies to all reports, just use the time series report to simplify the code
    const currentTimeSeriesChartReport = this.props.googleAnalytics.currentTimeSeriesChartReport
    const prevTimeSeriesChartReport = prevProps.googleAnalytics.currentTimeSeriesChartReport
    const goalStatus = this.props.googleAnalytics.goalStatus.data.find(x => x.accountAppId === this.props.accountApps.currentAccountAppId)
    if (currentTimeSeriesChartReport.isLoading !== prevTimeSeriesChartReport.isLoading && currentTimeSeriesChartReport.isLoading === true) {
      this.props.dispatch(showBanner(
          <React.Fragment>
            Some banner shown on componentDidUpdate
          </React.Fragment>,
          <UpdateIcon />
      ))

      if (goalStatus !== undefined && goalStatus.setUp === false) {
        this.showGoalNotSetUpBanner()
      } else {
        this.props.dispatch(hideBanner())
      }
    } else {
      if (currentTimeSeriesChartReport.dateRangeStatus === Enums.GADateRangeStatus.FullyLoaded) {
        if (goalStatus !== undefined && goalStatus.setUp === false) {
          this.showGoalNotSetUpBanner()
        } else {
          this.props.dispatch(hideBanner())
        }
      } else if (currentTimeSeriesChartReport.dateRangeStatus === Enums.GADateRangeStatus.Loading) {
        this.props.dispatch(showBanner(
          <React.Fragment>
            Some data for this date range is still loading. <strong>The data is incomplete and metrics will change once fully loaded.</strong>
            <br/>It typically takes around 15 minutes to load one month. Refresh in around 15 minutes.
          </React.Fragment>,
          <UpdateIcon />
        ))
      } else if (currentTimeSeriesChartReport.dateRangeStatus === Enums.GADateRangeStatus.Future) {
        this.props.dispatch(showBanner(
          <React.Fragment>
            Some of this date range is in the future. Only data up until yesterday is available.
          </React.Fragment>,
          <EventBusyIcon />
        ))
      } else {
        // unexpected condition, just hide the banner
        if (goalStatus !== undefined && goalStatus.setUp === false) {
          this.showGoalNotSetUpBanner()
        } else {
          this.props.dispatch(hideBanner())
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(hideBanner())
  }

  showGoalNotSetUpBanner() {
    this.props.dispatch(showBanner(
      <React.Fragment>
        <strong>Install data is not available.</strong> <a href="https://www.appstoreanalytics.io/post/track-your-shopify-app-listing-using-google-analytics" target="_blank">Set up the 'Get App button' goal in Google Analytics</a>.
        <br/>Once you've done that, installs will start showing, starting from tomorrow.
      </React.Fragment>,
      <ErrorIcon />
    ))
  }

  getSelectionsFromQuery() {
    const query = new URLSearchParams(this.props.location.search)
    let querySurface = query.get('s')
    querySurface = querySurface === null || querySurface === '' ? 'all' : querySurface
    let querySurfaceDetail = query.get('sd')
    querySurfaceDetail = querySurfaceDetail === null || querySurfaceDetail === '' ? null : querySurfaceDetail
    let queryTimeGrouping = query.get('tg')
    queryTimeGrouping = queryTimeGrouping === null || queryTimeGrouping === '' ? 'Day' : queryTimeGrouping
    let queryLocale = query.get('l')
    queryLocale = queryLocale === null || queryLocale === '' ? 'all' : queryLocale
    let queryGroup = query.get('g')
    queryGroup = queryGroup === null || queryGroup === '' ? 'all' : queryGroup
    let queryFromDate = query.get('fd')
    queryFromDate = queryFromDate === null || queryFromDate === '' ? moment().subtract(30, 'days').format('YYYY-MM-DD') : queryFromDate
    let queryToDate = query.get('td')
    queryToDate = queryToDate === null || queryToDate === '' ? moment().subtract(1, 'days').format('YYYY-MM-DD') : queryToDate
    let queryCompareFromDate = query.get('cfd')
    queryCompareFromDate = queryCompareFromDate === null || queryCompareFromDate === '' ? null : queryCompareFromDate
    let queryCompareToDate = query.get('ctd')
    queryCompareToDate = queryCompareToDate === null || queryCompareToDate === '' ? null : queryCompareToDate

    return {
      querySurface, querySurfaceDetail, queryTimeGrouping, queryLocale, queryGroup, queryFromDate, queryToDate, queryCompareFromDate, queryCompareToDate
    }
  }

  handleSelectSurface(selectedSurface) {
    this.setState({ selectedSurface, showSelectSurfaceTypeDialog: false }, this.lazyLoadData)
  }

  handleSelectToFromDates(selectedToDate, selectedFromDate, selectedCompareToDate, selectedCompareFromDate, currentDateRangeValueLabel, currentDateRangeCompareValueLabel) {
    this.setState({ selectedToDate, selectedFromDate, selectedCompareToDate, selectedCompareFromDate, currentDateRangeValueLabel, currentDateRangeCompareValueLabel }, this.lazyLoadData)
  }

  handleNewDateRangeLabel(currentDateRangeValueLabel, currentDateRangeCompareValueLabel) {
    this.setState({ currentDateRangeValueLabel, currentDateRangeCompareValueLabel })
  }

  handleSelectSurfaceDetail(selectedSurfaceDetail) {
    this.setState({ selectedSurfaceDetail }, this.lazyLoadData)
  }

  handleRowClick(event, rowData) {
    this.setState({
      selectedSurface: rowData.surface_type,
      selectedSurfaceDetail: rowData.surface_detail,
      showSelectSurfaceTypeDialog: false
    }, this.lazyLoadData)
  }

  handleSelectTimeGrouping(selectedTimeGrouping) {
    this.setState({ selectedTimeGrouping }, this.lazyLoadData)
  }

  handleAppListingChangeDateClick(fullDate) {
    this.props.history.push(`/l/apps/${this.props.accountApps.currentAccountApp.urlPath}/listing-history?d=${encodeURIComponent(fullDate)}`)
  }

  handleExpandRow(surfaceId, locale) {
    this.props.dispatch(loadAttributedInstalls(this.props.accountApps.currentAccountAppId, surfaceId, this.state.selectedFromDate, this.state.selectedToDate, locale))
  }

  lazyLoadData() {
    if (this.props.googleAnalytics.surfaceTypes.data.length < 1) {
      this.props.dispatch(loadSurfaceTypes(this.props.accountApps.currentAccountAppId))
    }

    if (this.props.appListing.history.changeDates == null) {
      this.props.dispatch(loadAppListingHistory(this.props.accountApps.currentAccountApp.url, this.props.accounts.currentAccountId))
    }

    this.props.dispatch(loadGACurrentTimeSeriesChartReport(
      this.props.accountApps.currentAccountAppId,
      this.state.selectedTimeGrouping,
      this.state.selectedSurface,
      this.state.selectedLocale,
      this.state.selectedSurfaceDetail,
      this.state.selectedGroup,
      this.state.selectedFromDate,
      this.state.selectedToDate,
      this.state.hideTimeSeriesDatasets,
      this.state.selectedCompareFromDate,
      this.state.selectedCompareToDate
      )
    )

    const tableReport = this.currentTableReport()
    if (tableReport === undefined) {
      this.props.dispatch(loadGAReport(
        this.props.accountApps.currentAccountAppId,
        'surface_type,surface_detail,locale',
        this.state.selectedSurface,
        this.state.selectedLocale,
        this.state.selectedSurfaceDetail,
        this.state.selectedGroup,
        this.state.selectedFromDate,
        this.state.selectedToDate,
        this.state.selectedCompareFromDate,
        this.state.selectedCompareToDate
        )
      )
    }

    const totalsReport = this.currentTotalsReport()
    if (totalsReport === undefined) {
      this.props.dispatch(loadGAReport(
        this.props.accountApps.currentAccountAppId,
        'Century',
        this.state.selectedSurface,
        this.state.selectedLocale,
        this.state.selectedSurfaceDetail,
        this.state.selectedGroup,
        this.state.selectedFromDate,
        this.state.selectedToDate,
        this.state.selectedCompareFromDate,
        this.state.selectedCompareToDate
        )
      )
    }

    const goalStatus = this.props.googleAnalytics.goalStatus.data.find(x => x.accountAppId === this.props.accountApps.currentAccountAppId)
    if (goalStatus === undefined) {
      this.props.dispatch(checkGAGoalStatus(this.props.accountApps.currentAccountAppId))
    }
  }

  toggleHideDataset(dataset) {
    let hideTimeSeriesDatasets = this.state.hideTimeSeriesDatasets
    let dataIndex = hideTimeSeriesDatasets.findIndex(x => x === dataset)
    const compareDatasetName = `${dataset}_compare`

    if (dataIndex === -1) {
      hideTimeSeriesDatasets.push(dataset)
      hideTimeSeriesDatasets.push(compareDatasetName)
    } else {
      hideTimeSeriesDatasets.splice(dataIndex, 1)
      dataIndex = hideTimeSeriesDatasets.findIndex(x => x === compareDatasetName)
      hideTimeSeriesDatasets.splice(dataIndex, 1)
    }

    this.setState({hideTimeSeriesDatasets},
      () => this.props.dispatch(loadGACurrentTimeSeriesChartReport(
        this.props.accountApps.currentAccountAppId,
        this.state.selectedTimeGrouping,
        this.state.selectedSurface,
        this.state.selectedLocale,
        this.state.selectedSurfaceDetail,
        this.state.selectedGroup,
        this.state.selectedFromDate,
        this.state.selectedToDate,
        hideTimeSeriesDatasets,
        this.state.selectedCompareFromDate,
        this.state.selectedCompareToDate
        )
      )
    )
  }

  render() {
    //const currentDateRange = this.currentDateRange()
    const tableReport = this.currentTableReport()
    let table = null
    if (tableReport !== undefined) {
      if (tableReport.isLoading === true) {
        table = null
      } else if (tableReport.errors !== null) {
        return this.renderErrors(tableReport.errors)
      } else {
        const enableAttribution = this.props.accounts.currentAccount.appsLimit > 10
        table = <SurfacesTableNew
          data={tableReport.data}
          onRowClick={this.handleRowClick}
          headerTitleLabel={this.state.currentDateRangeValueLabel}
          headerCompareTitleLabel={this.state.currentDateRangeCompareValueLabel}
          hideTimeSeriesDatasets={this.state.hideTimeSeriesDatasets}
          canSelect={this.props.canSelectTableRows}
          onSelectAllClick={this.props.onSelectAllClick}
          onAddKeyword={this.props.onAddKeyword}
          enableAttribution={enableAttribution}
          partnerApiOrganizationId={this.props.accounts.currentAccount.partnerApiOrganizationId}
          onExpandRow={this.handleExpandRow}
          attributedInstallsData={this.props.attributedInstalls !== undefined ? this.props.attributedInstalls.data : null}
        />
      }
    }

    const totalsReport = this.currentTotalsReport()
    let totalsReportCards = null
    if (totalsReport !== undefined && this.props.displayCards === true) {
      if (totalsReport.isLoading === true || totalsReport.data.some(x => !x.name.includes('_compare') && x.data === null)) {
        totalsReportCards = null
      } else if (totalsReport.errors !== null) {
        return this.renderErrors(totalsReport.errors)
      } else {
        const pageViewsCompare = totalsReport.data.find(x => x.name === 'page_views_compare').data === null ? null : totalsReport.data.find(x => x.name === 'page_views_compare').data["1"]
        const installsCompare = totalsReport.data.find(x => x.name === 'installs_compare').data === null ? null : totalsReport.data.find(x => x.name === 'installs_compare').data["1"]
        const averagePositionCompare = totalsReport.data.find(x => x.name === 'average_position_compare').data === null ? null : totalsReport.data.find(x => x.name === 'average_position_compare').data["1"]
        const installRateCompare = totalsReport.data.find(x => x.name === 'install_rate_compare').data === null ? null : totalsReport.data.find(x => x.name === 'install_rate_compare').data["1"]

        const wtfPageViews = totalsReport.data.find(x => x.name === 'page_views').data["1"] !== undefined ? totalsReport.data.find(x => x.name === 'page_views').data["1"] : totalsReport.data.find(x => x.name === 'page_views').data["21.0"]
        const wtfInstalls = totalsReport.data.find(x => x.name === 'installs').data["1"] !== undefined ? totalsReport.data.find(x => x.name === 'installs').data["1"] : totalsReport.data.find(x => x.name === 'installs').data["21.0"]
        const wtfAveragePosition = totalsReport.data.find(x => x.name === 'average_position').data["1"] !== undefined ? totalsReport.data.find(x => x.name === 'average_position').data["1"] : totalsReport.data.find(x => x.name === 'average_position').data["21.0"]
        const wtfInstallRate = totalsReport.data.find(x => x.name === 'install_rate').data["1"] !== undefined ? totalsReport.data.find(x => x.name === 'install_rate').data["1"] : totalsReport.data.find(x => x.name === 'install_rate').data["21.0"]

        totalsReportCards = (
          <Grid container spacing={0}>
            <Grid item>
              <TotalCard label='Total page views' format='average' backgroundColor='#4285f4' color='#fff'
                value={wtfPageViews}
                valueLabel={this.state.currentDateRangeValueLabel}
                compareValue={pageViewsCompare}
                compareValueLabel={this.state.currentDateRangeCompareValueLabel}
                onClick={() => this.toggleHideDataset('page_views')}
                active={this.state.hideTimeSeriesDatasets.findIndex(x => x === 'page_views') === -1}
              />
            </Grid>
            <Grid item>
              <TotalCard label='Total installs' format='average' backgroundColor='#5e35b1' color='#fff'
                value={wtfInstalls}
                valueLabel={this.state.currentDateRangeValueLabel}
                compareValue={installsCompare}
                compareValueLabel={this.state.currentDateRangeCompareValueLabel}
                onClick={() => this.toggleHideDataset('installs')}
                active={this.state.hideTimeSeriesDatasets.findIndex(x => x === 'installs') === -1}
              />
            </Grid>
            <Grid item>
              <TotalCard label='Average install rate' format='percent' backgroundColor='#e8710a' color='#000'
                value={wtfInstallRate}
                valueLabel={this.state.currentDateRangeValueLabel}
                compareValue={installRateCompare}
                compareValueLabel={this.state.currentDateRangeCompareValueLabel}
                onClick={() => this.toggleHideDataset('install_rate')}
                active={this.state.hideTimeSeriesDatasets.findIndex(x => x === 'install_rate') === -1}
              />
            </Grid>
            <Grid item>
              <TotalCard label='Average position' backgroundColor='#00897b' color='#fff'
                value={wtfAveragePosition}
                valueLabel={this.state.currentDateRangeValueLabel}
                compareValue={averagePositionCompare}
                compareValueLabel={this.state.currentDateRangeCompareValueLabel}
                onClick={() => this.toggleHideDataset('average_position')}
                active={this.state.hideTimeSeriesDatasets.findIndex(x => x === 'average_position') === -1}
              />
            </Grid>
          </Grid>
        )
      }
    }

    const timeSeriesChartReport = this.props.googleAnalytics.currentTimeSeriesChartReport
    const appListingHistory = this.props.appListing.history
    let timeSeriesChart = null
    if (timeSeriesChartReport.data !== null && appListingHistory.changes !== null && this.props.displayChart === true) {
      if (timeSeriesChartReport.isLoading === true || appListingHistory.isLoading === true) {
        timeSeriesChart = null
      } else if (timeSeriesChartReport.errors !== null) {
        return this.renderErrors(timeSeriesChartReport.errors)
      } else {
        timeSeriesChart =
          <TimeSeriesChart
            data={timeSeriesChartReport.data}
            appListingChanges={appListingHistory.changes}
            onAppListingChangeDateClick={this.handleAppListingChangeDateClick}
          />             
      }
    }

    let selectSurfaceTypeDialog = null
    if (this.state.showSelectSurfaceTypeDialog === true) {
      selectSurfaceTypeDialog =
        <SelectSurfaceTypeDialog
          surfaceTypes={this.props.googleAnalytics.surfaceTypes.data}
          initialSelectedSurfaceType={this.state.selectedSurface}
          onCancel={() => { this.setState({ showSelectSurfaceTypeDialog: false }) }}
          onSelect={this.handleSelectSurface} />
    }

    let selectSurfaceDetailChip = null
    if (this.state.selectedSurfaceDetail !== null) {
      selectSurfaceDetailChip = (
        <Chip label={`Surface detail: ${this.state.selectedSurfaceDetail}`}
          color="primary"
          onDelete={() => { this.handleSelectSurfaceDetail(null) }} />
      )
    }

    let toFromDatesChip = null
    if (this.state.selectedFromDate !== null && this.state.selectedToDate !== null) {
      toFromDatesChip = (
        <ToFromDatesChip
          onToFromDatesSelected={this.handleSelectToFromDates}
          onNewDateRangeLabel={this.handleNewDateRangeLabel}
          selectedToDate={this.state.selectedToDate}
          selectedFromDate={this.state.selectedFromDate}
          selectedCompareToDate={this.state.selectedCompareToDate}
          selectedCompareFromDate={this.state.selectedCompareFromDate} />
      )
    }

    return (
      <Grid container spacing={2}>
        <Grid container item spacing={1}>
          <Grid item>
            <Chip label={`Surface type: ${this.state.selectedSurface}`} clickable
              color="primary"
              deleteIcon={<EditIcon />}
              onDelete={() => { this.setState({ showSelectSurfaceTypeDialog: true }) }}
              onClick={() => { this.setState({ showSelectSurfaceTypeDialog: true }) }} />
          </Grid>
          <Grid item>
            {toFromDatesChip}
          </Grid>
          <Grid item>
            {selectSurfaceDetailChip}
          </Grid>
        </Grid>
        {
          this.props.displayChart === true &&
            <Grid container item>
              <Paper style={{ width: '100%' }}>
                <Grid container item>
                  {totalsReportCards}
                </Grid>
                <Grid container item style={{ marginTop: 16 }}>
                  {timeSeriesChart}
                </Grid>
                <Grid container justify='center' style={{ marginBottom: 16 }}>
                  { this.renderSelectTimeGrouping() }
                </Grid>
              </Paper>
            </Grid>
        }
        {this.renderAttributionSetup()}
        <Grid container item>
          <Paper style={{ width: '100%', marginBottom: '40px' }} elevation={this.props.displayChart === false ? 0 : 2}>
              {table}
          </Paper>
        </Grid>
        {selectSurfaceTypeDialog}
      </Grid>
    )
  }

  renderAttributionSetup() {
    const { classes } = this.props
    const currentAccount = this.props.accounts.currentAccount
    const currentAccountApp = this.props.accountApps.currentAccountApp

    let bannerText = null
    let bannerButton = null

    // Trial users get 99999 apps, so this covers them too
    const eligibleForAttribution = currentAccount.appsLimit > 10
    if (eligibleForAttribution === false) {
      return
    }

    const notLinked = currentAccount.partnerApiOrganizationId === null || currentAccount.partnerApiOrganizationId === '' || currentAccount.partnerApiAccessToken === null || currentAccount.partnerApiAccessToken === ''
    const noShopifyAppId = currentAccountApp.shopifyAppId === null || currentAccountApp.shopifyAppId === ''
    if (notLinked === false && noShopifyAppId === false) {
      return
    }

    if (notLinked === true) {
      bannerText = (
          <React.Fragment>
            Not linked to the Shopify Partner API.
            <br/><strong>To see Shop and Revenue details (attribution)</strong>, enter Partner API credentials.
          </React.Fragment>
      )
      bannerButton = (
          <Button variant="outlined" color="secondary" component={Link} to='/l/partner-api'>
            Enter Partner API Credentials
          </Button>
      )
    } else if (noShopifyAppId === true) {
      bannerText = (
        <React.Fragment>
          Linked to the Partner API, but there is no Shopify App ID entered for this app.
          <br/><strong>To see Shop and Revenue details (attribution), enter a Shopify App ID by pushing the 'Change' link next to 'Linked to Google Analytics' above.</strong>
        </React.Fragment>
      )
    }
    return (
        <Grid container item>
          <Paper style={{ width: '100%' }} elevation={1} className={classes.paper} square>
            <Box display="flex" alignItems="center">
              <Box>
                <Avatar className={classes.avatar}>
                  <PriorityHigh />
                </Avatar>
              </Box>
              <Box flexGrow={1}>
                <Typography className={classes.text}>
                  {bannerText}
                </Typography>
              </Box>
              <Box>
                {bannerButton}
              </Box>
            </Box>
          </Paper>
        </Grid>
    )
  }

  renderSelectTimeGrouping() {
    const { classes } = this.props
    return (
      <div className={classes.rangeFilter}>
        <Chip label="Day"
          size="small"
          color={this.state.selectedTimeGrouping === 'Day' ? 'primary' : 'default'}
          onClick={() => this.handleSelectTimeGrouping('Day')} />
        <Chip label="Week"
          size="small"
          color={this.state.selectedTimeGrouping === 'Week' ? 'primary' : 'default'}
          onClick={() => this.handleSelectTimeGrouping('Week')} />
        <Chip label="Month"
          size="small"
          color={this.state.selectedTimeGrouping === 'Month' ? 'primary' : 'default'}
          onClick={() => this.handleSelectTimeGrouping('Month')} />
      </div>
    )
  }

  renderErrors(errors) {
    return (
      <Dialog open={true} disableEnforceFocus>
        <DialogTitle>Error: Couldn't load report data</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Report data couldn't be loaded. This is usually because the Google Analytics account and profile you linked to doesn't include any traffic data for your Shopify App Store URL.
          </DialogContentText>
          <DialogContentText>
            Go to Settings, check that your Shopify App Store URL is correct and the linked Google Analytics account and profile contains traffic for that URL.
          </DialogContentText>
          <DialogContentText>
            Details: {errors}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" component={Link} to='/l/google-account' autoFocus>
            Go to Settings
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  currentTableReport() {
    return this.props.googleAnalytics.reports.find(
      x => x.accountAppId === this.props.accountApps.currentAccountAppId
      && x.groupBy === 'surface_type,surface_detail,locale'
      && x.surfaceType === this.state.selectedSurface
      && x.locale === this.state.selectedLocale
      && x.surfaceDetail === this.state.selectedSurfaceDetail
      && x.group === this.state.selectedGroup
      && x.fromDate === this.state.selectedFromDate
      && x.toDate === this.state.selectedToDate
      && x.compareFromDate === this.state.selectedCompareFromDate
      && x.compareToDate === this.state.selectedCompareToDate)
  }

  currentTotalsReport() {
    return this.props.googleAnalytics.reports.find(
      x => x.accountAppId === this.props.accountApps.currentAccountAppId
      && x.groupBy === 'Century'
      && x.surfaceType === this.state.selectedSurface
      && x.locale === this.state.selectedLocale
      && x.surfaceDetail === this.state.selectedSurfaceDetail
      && x.group === this.state.selectedGroup
      && x.fromDate === this.state.selectedFromDate
      && x.toDate === this.state.selectedToDate
      && x.compareFromDate === this.state.selectedCompareFromDate
      && x.compareToDate === this.state.selectedCompareToDate)
  }
}

function mapStateToProps(state) {
  const { googleAnalytics, accounts, accountApps, appListing, attributedInstalls } = state

  return { googleAnalytics, accounts, accountApps, appListing, attributedInstalls }
}

AppListingTrafficContainer.defaultProps = {
  displayCards: true,
  displayChart: true,
  canSelectTableRows: false
}

AppListingTrafficContainer.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  displayCards: PropTypes.bool.isRequired,
  displayChart: PropTypes.bool.isRequired,
  canSelectTableRows: PropTypes.bool.isRequired,
  onSelectAllClick: PropTypes.func,
  onAddKeyword: PropTypes.func,
  classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(withStyles(styles)(AppListingTrafficContainer))