import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

class SelectToFromDatesDialog extends React.Component {
  constructor(props) {
    super(props)
    const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD')

    this.state = {
        currentSelectedToDate: props.initialSelectedToDate,
        currentSelectedFromDate: props.initialSelectedFromDate,
        maxDate: yesterday,
        currentSelectedRadio: props.initialSelectedDateRangeValue,
        selectedTab: props.initialSelectedCompareToDate !== null && props.initialSelectedCompareToDate.length > 0 ? 1 : 0,
        currentSelectedCompareToDate: props.initialSelectedCompareToDate !== null && props.initialSelectedCompareToDate.length > 0 ? props.initialSelectedCompareToDate : null,
        currentSelectedCompareFromDate: props.initialSelectedCompareFromDate !== null && props.initialSelectedCompareFromDate.length > 0 ? props.initialSelectedCompareFromDate : null
    }
    this.onCurrentSelectedToDateChange = this.onCurrentSelectedToDateChange.bind(this)
    this.onCurrentSelectedFromDateChange = this.onCurrentSelectedFromDateChange.bind(this)
    this.onChangeRadio = this.onChangeRadio.bind(this)
    this.onChangeTab = this.onChangeTab.bind(this)
  }

  onCurrentSelectedToDateChange(event) {
    this.setState({ currentSelectedToDate: event.target.value })
  }

  onCurrentSelectedFromDateChange(event) {
    this.setState({ currentSelectedFromDate: event.target.value })
  }

  onChangeRadio(e) {
    const matchedOption = this.props.datesTable.find(x => x.value === e.target.value)
    if (matchedOption !== undefined) {
      this.setState({
        currentSelectedRadio: matchedOption.value,
        currentSelectedToDate: matchedOption.toDate,
        currentSelectedFromDate: matchedOption.fromDate,
        currentSelectedCompareToDate: matchedOption.compareToDate,
        currentSelectedCompareFromDate: matchedOption.compareFromDate
      })
      return
    }

    const currentSelectedRadio = e.target.value

    if (currentSelectedRadio === 'Custom') {
      this.setState({
        currentSelectedRadio: e.target.value,
        currentSelectedCompareToDate: null,
        currentSelectedCompareFromDate: null
      })
      return
    }

    if (currentSelectedRadio === 'Compare custom') {
      let newCompareToDate = this.state.currentSelectedCompareToDate
      let newCompareFromDate = this.state.currentSelectedCompareFromDate
      if (newCompareToDate === null || newCompareFromDate === null) {
        const daysDiff = moment(this.state.currentSelectedToDate).diff(moment(this.state.currentSelectedFromDate), 'days')
        newCompareToDate = moment(this.state.currentSelectedFromDate).subtract(1, 'days')
        newCompareFromDate = moment(newCompareToDate).subtract(daysDiff, 'days')
        newCompareToDate = newCompareToDate.format('YYYY-MM-DD')
        newCompareFromDate = newCompareFromDate.format('YYYY-MM-DD')
      }
      this.setState({
        currentSelectedRadio: e.target.value,
        currentSelectedCompareToDate: newCompareToDate,
        currentSelectedCompareFromDate: newCompareFromDate
      })
      return
    }
  }

  onChangeTab(e, newValue) {
    this.setState({ selectedTab: newValue })
  }

  render() {
    const controls = this.state.selectedTab === 0 ? this.renderFilterControls() : this.renderCompareControls() 

    return (
      <Dialog open={true} fullWidth={true} disableEnforceFocus>
        <DialogTitle>Date range</DialogTitle>
        <DialogContent>
          { this.props.compare === true &&
              <Tabs value={this.state.selectedTab} onChange={this.onChangeTab} style={{ marginBottom: 18 }}>
                <Tab label="Filter" />
                <Tab label="Compare" />
              </Tabs>
          }
          {controls}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel}>
            Cancel
          </Button>
          <Button color="secondary"
            onClick={ () => {
              const formattedTo = moment(this.state.currentSelectedToDate).format('YYYY-MM-DD')
              const formattedFrom = moment(this.state.currentSelectedFromDate).format('YYYY-MM-DD')
              const formattedCompareTo = this.state.currentSelectedCompareToDate === null ? null : moment(this.state.currentSelectedCompareToDate).format('YYYY-MM-DD')
              const formattedCompareFrom = this.state.currentSelectedCompareFromDate === null ? null : moment(this.state.currentSelectedCompareFromDate).format('YYYY-MM-DD')
              const friendlyText = this.state.currentSelectedRadio === 'custom' ? `${formattedFrom} - ${formattedTo}` : this.state.currentSelectedRadio.includes('compare') ? 'Compare' : this.state.currentSelectedRadio.charAt(0).toUpperCase() + this.state.currentSelectedRadio.slice(1) 
              this.props.onSelect(formattedTo, formattedFrom, friendlyText, formattedCompareTo, formattedCompareFrom)
            }}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderFilterControls() {
    return (
      <React.Fragment>
        <FormControl component="fieldset">
          <RadioGroup value={this.state.currentSelectedRadio} onChange={this.onChangeRadio}>
            {this.props.datesTable.filter(x => x.optionType === 'filter').map(filterOption => {
              return <FormControlLabel key={filterOption.value} value={filterOption.value} control={<Radio />} label={filterOption.value} />
            })}
            <FormControlLabel value="Custom" control={<Radio />} label="Custom" />
          </RadioGroup>
        </FormControl>
        {this.renderBasePeriodDatePickers()}
      </React.Fragment>
    )
  }

  renderCompareControls() {
    return (
      <React.Fragment>
        <FormControl component="fieldset">
          <RadioGroup value={this.state.currentSelectedRadio} onChange={this.onChangeRadio}>
            {this.props.datesTable.filter(x => x.optionType === 'compare').map(compareOption => {
              return <FormControlLabel key={compareOption.value} value={compareOption.value} control={<Radio />} label={compareOption.value} />
            })}
            <FormControlLabel value="Compare custom" control={<Radio />} label="Compare custom" />
          </RadioGroup>
        </FormControl>
        {this.renderBasePeriodDatePickers()}
      </React.Fragment>
    )
  }

  renderBasePeriodDatePickers() {
    let comparePickers = null
    if (this.state.selectedTab === 1) {
      comparePickers = (
        <React.Fragment>
          <Grid item xs={12} style={{ marginTop: 18 }}>
            <Typography variant='h5' style={{ textAlign: 'center' }}>vs.</Typography>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={6}>
              <KeyboardDatePicker
                PopoverProps={{ disableEnforceFocus: true }}
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="normal"
                label="Start date"
                value={this.state.currentSelectedCompareFromDate}
                autoOk={true}
                onChange={(date) => { this.setState({ currentSelectedCompareFromDate: date, currentSelectedRadio: 'Compare custom' }) }}
                maxDate={this.state.currentSelectedCompareToDate === null ? '' : this.state.currentSelectedCompareToDate}
                maxDateMessage="Select a start date before the end date."
              />
            </Grid>
            <Grid item xs={5}>
              <KeyboardDatePicker
                PopoverProps={{ disableEnforceFocus: true }}
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="normal"
                label="End date"
                value={this.state.currentSelectedCompareToDate}
                autoOk={true}
                onChange={(date) => { this.setState({ currentSelectedCompareToDate: date, currentSelectedRadio: 'Compare custom' }) }}
                minDate={this.state.currentSelectedCompareFromDate === null ? '' : this.state.currentSelectedCompareFromDate}
                maxDate={this.state.maxDate}
                maxDateMessage="Data is loaded up until yesterday. Select a date before yesterday."
              />
            </Grid>
        </React.Fragment>
      )
    }

    return (
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid item xs={6}>
            <KeyboardDatePicker
              PopoverProps={{ disableEnforceFocus: true }}
              disableToolbar
              variant="inline"
              format="YYYY-MM-DD"
              margin="normal"
              label="Start date"
              value={this.state.currentSelectedFromDate}
              autoOk={true}
              onChange={(date) => { this.setState({ currentSelectedFromDate: date, currentSelectedRadio: this.state.selectedTab === 1 ? 'Compare custom' : 'Custom' }) }}
              maxDate={this.state.currentSelectedToDate}
              maxDateMessage="Select a start date before the end date."
            />
          </Grid>
          <Grid item xs={5}>
            <KeyboardDatePicker
              PopoverProps={{ disableEnforceFocus: true }}
              disableToolbar
              variant="inline"
              format="YYYY-MM-DD"
              margin="normal"
              label="End date"
              value={this.state.currentSelectedToDate}
              autoOk={true}
              onChange={(date) => { this.setState({ currentSelectedToDate: date, currentSelectedRadio: this.state.selectedTab === 1 ? 'Compare custom' : 'Custom' }) }}
              minDate={this.state.currentSelectedFromDate}
              maxDate={this.state.maxDate}
              maxDateMessage="Data is loaded up until yesterday. Select a date before yesterday."
            />
          </Grid>
          {comparePickers}
      </Grid>
    )
  }
}

SelectToFromDatesDialog.defaultProps = {
  initialSelectedCompareToDate: null,
  initialSelectedCompareFromDate: null,
  compare: true
}

SelectToFromDatesDialog.propTypes = {
  datesTable: PropTypes.array.isRequired,
  initialSelectedDateRangeValue: PropTypes.string.isRequired,
  initialSelectedToDate: PropTypes.string.isRequired,
  initialSelectedFromDate: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialSelectedCompareToDate: PropTypes.string,
  initialSelectedCompareFromDate: PropTypes.string,
  compare: PropTypes.bool.isRequired
}

export default SelectToFromDatesDialog