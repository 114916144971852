import fetch from 'cross-fetch'

export default class ApiUtils {
  static fetchWithBody (verb, path, body) {
    const storedCurrentUser = localStorage.getItem('currentUser')
    const currentUser = JSON.parse(storedCurrentUser)

    return fetch(ApiUtils.apiUrl(path), {
      method: verb,
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': currentUser && currentUser.accessToken ? currentUser.accessToken : null,
          'Access-Control-Allow-Origin': '*'
          },
          body: JSON.stringify(body)
      }).then(res => {
        if (res.status === 402) {
          window.location.replace('/l/billing') // not using react-router here: an exception to handle payment required responses
        } else {
          return res
        }
      }).catch(error => {
        this.displayFetchError()
      })
  }

  static get(path) {
    const storedCurrentUser = localStorage.getItem('currentUser')
    const currentUser = JSON.parse(storedCurrentUser)

    return fetch(ApiUtils.apiUrl(path), {
      headers: {
        'Authorization': currentUser.accessToken,
        'Access-Control-Allow-Origin': '*'
      }
    }).then(res => {
      if (res.status === 402) {
        window.location.replace('/l/billing') // not using react-router here: an exception to handle payment required responses
      } else {
        return res
      }
    }).catch(error => {
      this.displayFetchError()
    })
  }

  static put(path, body) {
    return this.fetchWithBody('put', path, body)
  }

  static post(path, body) {
    return this.fetchWithBody('post', path, body)
  }

  static delete(path, body) {
    return this.fetchWithBody('delete', path, body)
  }

  static apiUrl(endpoint) {
    return `${ CONFIG.apiUrl }/${ endpoint }`
  }

  static displayFetchError() {
    alert("An error occurred fetching data\n\nIf your internet connection is fine, this is usually caused by an ad blocker\n\nApp Store Analytics connects to your Google Analytics, which is prevented by ad blockers\n\nPlease disable your ad blocker and refresh this page to use the app\n\nEmail help@appstoreanalytics.io if you need help")
  }
}
