import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  reports: [],
  competitorReports: []
}

const rankings = createReducer(initialState, {
  CLEAR_RANKINGS_STATUS: (state, action) => {
    state.reports = []
    state.competitorReports = []
  },
  LOADING_RANKINGS_STATUS: (state, action) => {
    let report = state.reports.find(x => x.surfaceType === action.surfaceType)
    if (report === undefined) {
      state.reports.push({
        surfaceType: action.surfaceType,
        isLoading: true
      })
    } else {
      report.isLoading = true
    }
  },
  LOADED_RANKINGS_STATUS: (state, action) => {
    let report = state.reports.find(x => x.surfaceType === action.surfaceType)
    report.isLoading = false
    report.data = action.data
  },
  LOADING_COMPETITOR_RANKINGS_STATUS: (state, action) => {
    let competitorReports = state.competitorReports.find(x => x.surfaceType === action.surfaceType)
    if (competitorReports === undefined) {
      state.competitorReports.push({
        surfaceType: action.surfaceType,
        isLoading: true
      })
    } else {
      competitorReports.isLoading = true
    }
  },
  LOADED_COMPETITOR_RANKINGS_STATUS: (state, action) => {
    let competitorReports = state.competitorReports.find(x => x.surfaceType === action.surfaceType)
    competitorReports.isLoading = false
    competitorReports.data = action.data
  },
})

export default rankings