import React from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import LinearProgress from '@material-ui/core/LinearProgress'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import TablePagination from '@material-ui/core/TablePagination'
import EnhancedTableHead from '../googleAnalytics/EnhancedTableHead'

class AttributedInstallsTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            rowsPerPage: 5,
            order: 'desc',
            orderBy: 'revenue_to_date',
        }
        this.onChangePage = this.onChangePage.bind(this)
        this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this)
        this.onRequestSort = this.onRequestSort.bind(this)
    }

    onChangePage(e, newPage) {
        this.setState({
            page: newPage
        })
    }

    onChangeRowsPerPage(e) {
        this.setState({
            rowsPerPage: parseInt(e.target.value, 10),
            page: 0
        })
    }

    onRequestSort(e, property) {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc'
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property
        })
    }

    render() {
        if (this.props.data === undefined || this.props.data === null) {
            return <LinearProgress />
        } else {
            return (
                <React.Fragment>
                <Table>
                    <EnhancedTableHead
                        order={this.state.order}
                        orderBy={this.state.orderBy}
                        onRequestSort={this.onRequestSort}
                        rowCount={this.props.data.data.length}
                        numSelected={0}
                        headCells={[
                            { id: 'name', numeric: false, disablePadding: false, label: 'Shop' },
                            { id: 'revenue_to_date', numeric: true, disablePadding: false, label: 'Revenue to Date' },
                            { id: 'shop_id', numeric: true, disablePadding: false, label: 'Partner Admin' },
                        ]}
                    />
                    <TableBody>
                        {this.stableSort(this.props.data.data, this.getComparator(this.state.order, this.state.orderBy))
                            .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                            .map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    { row.myshopify_domain === null ?
                                        (<Tooltip title="Shop name and domain are loaded from the Partner API, usually within a few hours. If it has been several hours, check your Partner API credentials have been correctly entered in settings."><div>{row.name}</div></Tooltip>)
                                        : (<a href={`https://${row.myshopify_domain}`} target="_blank">{row.name}</a>)
                                    }
                                </TableCell>
                                <TableCell>{ row.revenue_to_date > 0 ? `$${row.revenue_to_date}` : null }</TableCell>
                                <TableCell>
                                    <IconButton href={`https://partners.shopify.com/${this.props.partnerApiOrganizationId}/stores/${row.shop_id}`} target="_blank">
                                        <OpenInNewIcon fontSize='small' />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
                    component="div"
                    count={this.props.data.data.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.onChangePage}
                    onChangeRowsPerPage={this.onChangeRowsPerPage}
                />
                </React.Fragment>
            )
        }
    }

    descendingComparator(a, b, orderBy) {
        let a_for_comparison
        let b_for_comparison
        if (orderBy === 'revenue_to_date' || orderBy === 'shop_id') {
            a_for_comparison = Number(a[orderBy])
            b_for_comparison = Number(b[orderBy])
        } else if (orderBy === 'locale') {
            a_for_comparison = a[orderBy] === null ? '' : a[orderBy]
            b_for_comparison = b[orderBy] === null ? '' : b[orderBy]
        } else {
            a_for_comparison = a[orderBy]
            b_for_comparison = b[orderBy]
        }
        if (b_for_comparison < a_for_comparison) {
            return -1
        }
        if (b_for_comparison > a_for_comparison) {
            return 1
        }
        return 0
    }

    getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => this.descendingComparator(a, b, orderBy)
            : (a, b) => -this.descendingComparator(a, b, orderBy)
    }

    stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index])
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0])
            if (order !== 0) return order
            return a[1] - b[1]
        })
        return stabilizedThis.map((el) => el[0])
    }
}

AttributedInstallsTable.propTypes = {
    data: PropTypes.array,
    partnerApiOrganizationId: PropTypes.string,
}

export default AttributedInstallsTable