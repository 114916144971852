import PropTypes from 'prop-types'
import clsx from 'clsx'
import { lighten, makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}))

function EnhancedTableToolbar(props) {
    const classes = useToolbarStyles()
    const { numSelected, selectedComponent, noneSelectedComponent, readOnly } = props

    if (readOnly === true) {
      return null
    }

    const control = numSelected > 0 ? selectedComponent : noneSelectedComponent

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {control}
      </Toolbar>
    )
  }

  EnhancedTableToolbar.defaultProps = {
    readOnly: false
  }
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    readOnly: PropTypes.bool.isRequired,
    selectedComponent: PropTypes.node.isRequired,
    noneSelectedComponent: PropTypes.node.isRequired
  }
  
  export default EnhancedTableToolbar