import React from 'react'
import PropTypes from 'prop-types'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import HelpIcon from '@material-ui/icons/Help'

class SelectSurfaceTypeDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        currentSelectedSurfaceType: props.initialSelectedSurfaceType,
        showHelp: false
    }
    this.onCurrentSelectedSurfaceTypeChange = this.onCurrentSelectedSurfaceTypeChange.bind(this)
  }

  onCurrentSelectedSurfaceTypeChange(event) {
    this.setState({ currentSelectedSurfaceType: event.target.value })
  }

  render() {
    const surfaceTypes = this.props.surfaceTypes.map(surfaceType =>
        <FormControlLabel
          key={surfaceType}
          value={surfaceType}
          label={surfaceType}
          control={<Radio />} />
      )

      let helpContent = null
      if (this.state.showHelp === true) {
        helpContent = (
          <React.Fragment>
            <Typography gutterBottom>
              Surface type is the surface where your app is shown before the user pushes to visit your app listing.
            </Typography>
            <Typography gutterBottom>
              App Store Analytics shows both surfaces inside the Shopify App Store and external links.
            </Typography>
            <Typography gutterBottom>
              The surfaces available inside the app store are <a href='https://shopify.dev/concepts/app-store/being-successful-in-the-app-store/marketing-internally/tracking-with-google-analytics' target='_blank'>detailed here</a>. These are prefixed with 'app store' in App Store Analytics, like 'app store search' for the search surface type.
            </Typography>
            <Typography gutterBottom>
              External link surfaces are anything outside of the app store. These include retargeting, email, blogs, search engines and more. App Store Analytics does a simple mapping of the Google Analytics medium = surface type, source = surface detail. <a href='https://support.google.com/analytics/answer/1033173' target='_blank'>Find more details here</a>.
            </Typography>
          </React.Fragment>
        )
      }
  
      return (
        <Dialog open={true} fullWidth={true} disableEnforceFocus>
          <DialogTitle>
          <Typography variant="h6">Surface type</Typography>
            <IconButton style={{ position: 'absolute', right: 6, top: 6 }} onClick={ () => this.setState({ showHelp: !this.state.showHelp }) }>
              <HelpIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            {helpContent}
            <FormControl component="fieldset">
              <RadioGroup value={this.state.currentSelectedSurfaceType} onChange={this.onCurrentSelectedSurfaceTypeChange}>
                {surfaceTypes}
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onCancel}>
              Cancel
            </Button>
            <Button color="primary" onClick={ () => { this.props.onSelect(this.state.currentSelectedSurfaceType) }}>
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      )
  }
}

SelectSurfaceTypeDialog.propTypes = {
    surfaceTypes: PropTypes.array.isRequired,
    initialSelectedSurfaceType: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

export default SelectSurfaceTypeDialog