import React from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Radio from '@material-ui/core/Radio'

class PropertiesList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    if (this.props.properties.data.length === 0) {
        return null
    }
    return this.props.properties.data.account_summaries.items.map(a => {
        return (
        <span key={ `${a.id}-span` }>
        <ListSubheader disableSticky={ true } key={ a.id }>{ a.name } { a.id }</ListSubheader>
        { a.web_properties.map(p => {
            return (
            <span key={ `${p.id}-span` }>
            <ListItem key={ p.id }>
                <ListItemText key={ `${a.id}-text` } primary={ p.name } secondary={ p.id } />
            </ListItem>
            { p.profiles.map(prf => {
                return (
                <List key={ prf.id } component="div" disablePadding>
                    <ListItem button style={{ paddingLeft: 40 }} onClick={ () => this.props.onPropertySelected(prf.id) }>
                    <ListItemIcon>
                        <Radio checked={ this.props.selectedProperty === prf.id } />
                    </ListItemIcon>
                    <ListItemText primary={ prf.name } secondary={ prf.id } />
                    </ListItem>
                </List>
                )
            })}
            </span>
            )
        })}
        </span>
        )
    })        
  }
}

PropertiesList.propTypes = {
    selectedProperty: PropTypes.string,
    properties: PropTypes.object.isRequired,
    onPropertySelected: PropTypes.func.isRequired
}

export default PropertiesList