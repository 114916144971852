var Enums = { }

Enums.Periods = {
  Day: 'Day',
  Week: 'Week',
  Month: 'Month'
}

// these map to Ruby modules
Enums.GAStatus = {
  NotChecked: 'Not checked',
  Checking: 'Checking',
  NotAuthed: 'Not authed',
  Authed: 'Authed',
  UnknownError: 'Unknown error',
  PermissionsError: 'Permissions error'
}

Enums.GADateRangeStatus = {
  FullyLoaded: 'Fully loaded',
  Loading: 'Loading',
  Future: 'Future'
}

Enums.AppType = {
  Own: 'own',
  Competitor: 'competitor',
  Lookup: 'lookup'
}

module.exports = Enums
