import { connect } from 'react-redux'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { switchAccount } from '../../actions/actions'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'

class AccountsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    let currentAccount = null
    let availableAccountsList = []
    if (this.props.accounts.isLoading === false && this.props.accounts.data) {
      this.props.accounts.data.forEach(account => {
        if (this.props.accounts.currentAccount.id !== null && account.id === this.props.accounts.currentAccount.id) {
          currentAccount = (
            <ListItem key={account.id}>
              <ListItemText
                primary={account.name}
              />
            </ListItem>
          )
        } else if (this.props.accounts.currentAccount.id === null && account.default === true) {
          currentAccount = (
            <ListItem key={account.id}>
              <ListItemText
                primary={account.name}
              />
            </ListItem>
          )
        } else {
          availableAccountsList.push(
            <ListItem key={account.id} button onClick={ () => this.props.dispatch(switchAccount(account.id)) }>
              <ListItemText
                primary={account.name}
              />
            </ListItem>
          )
        }
      })
    }

    let list = null
    if (this.props.accounts.isLoading === false) {
      list = (
        <React.Fragment>
          <Typography>
            Select the account you want to switch to
          </Typography>
          <List>
            <ListSubheader>
              You're currently using account
            </ListSubheader>
            {currentAccount}
            <ListSubheader>
              Available accounts
            </ListSubheader>
            {availableAccountsList}
          </List>
        </React.Fragment>
      )
    }

    return (
        <Container maxWidth='xs' spacing={2}>
          <Grid container>
            <Grid item>
              <Box my={4}>
                <Typography component='h1' variant='h5' gutterBottom>
                  Switch Account
                </Typography>
                {list}
              </Box>
            </Grid>
          </Grid>
        </Container>
    )
  }
}

function mapStateToProps(state) {
  const { accounts } = state

  return {
    accounts
  }
}

export default connect(mapStateToProps)(AccountsContainer)