import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { loadAllApps } from '../../actions/actions'
import AppSearch from '../../components/apps/AppSearch'
import Typography from '@material-ui/core/Typography'
import { Enums } from 'utils'

class AnonymousHomeContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    this.onQuickLookup = this.onQuickLookup.bind(this)
  }

  componentDidMount() {
    if (this.props.apps.all.data === null && this.props.apps.all.isLoading === false) {
      this.props.dispatch(loadAllApps())
    }
  }

  onQuickLookup(e, selectedApp) {
    this.props.history.push(`/l/apps/${selectedApp.url.replace('https://','').replace('http://', '').replace('apps.shopify.com/', '')}/listing-history`)
  }

  render() {
    return (
      <React.Fragment>
        <Typography variant='h5' gutterBottom style={{ textAlign: 'center', margin: '16px 0px' }}>Discover the app listing history of any app in the Shopify App Store</Typography>
        <AppSearch isLoading={this.props.apps.all.isLoading === true || this.props.apps.all.data === null} allApps={this.props.apps.all.data} onChange={this.onQuickLookup} appType={Enums.AppType.Lookup} />
        <Typography style={{ textAlign: 'center', marginTop: 16 }}>3 Quick Lookups/day free<br/><Link to='/sign-up'>Start a free trial</Link> for more</Typography>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { apps } = state

  return { apps }
}

AnonymousHomeContainer.propTypes = {
  history: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(AnonymousHomeContainer)