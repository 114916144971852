import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Banner from '../banner/Banner'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import SomethingIsLoadingBar from '../navigation/SomethingIsLoadingBar'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton'
import HelpDialog from '../banner/HelpDialog'

const drawerWidth = 250

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    
  },
  appBar: {
    top: 56,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      top: 64
    },
    backgroundColor: theme.palette.primary.main,
  },
  pusher: {
    marginTop: 56,
    [theme.breakpoints.up('sm')]: {
      marginTop: 64
    },
  },
  menuButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.default,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
}))

function SubAppBar(props) {
  const classes = useStyles()

  let backButton = null
  if (props.onBack !== undefined && props.onBack !== null) {
    backButton = (
      <IconButton
          edge="start"
          onClick={props.onBack}
          className={classes.menuButton}
        >
          <ArrowBackIcon />
      </IconButton>
    )
  }

  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.appBar} style={{ backgroundColor: '#fff' }} elevation={3}>
        <Toolbar>
          {backButton}
          <Typography variant="h6" style={{ color: '#000' }} noWrap>{ props.title }</Typography>
          <Typography style={{ flexGrow: 1 }}></Typography>
          {props.rightComponent}
        </Toolbar>
        <SomethingIsLoadingBar/>
      </AppBar>
      <div className={classes.pusher}></div>
      <Banner />
      <HelpDialog />
    </React.Fragment>
  )
}

SubAppBar.propTypes = {
  title: PropTypes.string.isRequired,
  onBack: PropTypes.func,
  rightComponent: PropTypes.element
}

export default SubAppBar
