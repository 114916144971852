import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { loadAccountAppKeywords, addAccountAppKeyword, deleteAccountAppKeyword, checkAccountAppKeywordPosition, loadKeywords, showHelp } from '../../actions/actions'
import Paper from '@material-ui/core/Paper'
import AccountAppKeywordsTable from '../../components/keywords/AccountAppKeywordsTable'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AppListingTrafficContainer from '../appListing/AppListingTrafficContainer'
import KeywordsTable from '../../components/keywords/KeywordsTable'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import HelpIcon from '@material-ui/icons/Help'

class KeywordsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: 0
    }
  }

  componentDidMount() {
    if (this.props.accountAppKeywords.data === null && this.props.accountAppKeywords.isLoading === false) {
      this.props.dispatch(loadAccountAppKeywords(this.props.accountApps.currentAccountApp.id))
    }
    if (this.props.keywords.data === null && this.props.keywords.isLoading === false) {
      this.props.dispatch(loadKeywords())
    }
  }

  render() {
    let researchComponent = null
    if (this.state.selectedTab === 0) {
      if (this.props.keywords.data !== null && this.props.keywords.isLoading === false) {
        researchComponent = (
          <KeywordsTable
            keywords={this.props.keywords.data}
            addAccountAppKeyword={(keyword) => this.props.dispatch(addAccountAppKeyword(this.props.accountApps.currentAccountApp.id, keyword))}
          />
        )
      }
    } else {
      researchComponent = (
        <AppListingTrafficContainer
              location={this.props.location}
              history={this.props.history}
              displayCards={false}
              displayChart={false}
              canSelectTableRows={true}
              onAddKeyword={(keyword) => this.props.dispatch(addAccountAppKeyword(this.props.accountApps.currentAccountApp.id, keyword))} />
      )
    }

    return (
      <Grid container spacing={2}>
        <Grid container item md={4} xs={12}>
          <Paper style={{ width: '100%' }} style={{ padding: 24 }}>
            <Grid container item xs={12} alignItems="center">
              <Grid item xs>
                <Typography variant='h6'>Target Keywords</Typography>
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'right' }}>
                <IconButton onClick={
                  () => {
                    this.props.dispatch(showHelp(
                      'Target Keywords',
                      (
                        <React.Fragment>
                          <Typography gutterBottom>
                            Enter around 20 Target Keywords. Think about the keywords and phrases merchants type into App Store search.
                          </Typography>
                          <Typography gutterBottom>
                            You can then track your position for these keywords over time and use them in the app listing editor to improve your positions.
                          </Typography>
                        </React.Fragment>
                      )
                    ))
                  }
                }>
                  <HelpIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              {
                this.props.accountAppKeywords.data !== null &&
                  <AccountAppKeywordsTable
                    positionLabel='Current Position'
                    displayPreviousPosition={false}
                    displayPosition={true}
                    readOnly={false}
                    accountAppKeywords={this.props.accountAppKeywords}
                    deleteAccountAppKeyword={ (id) => this.props.dispatch(deleteAccountAppKeyword(this.props.accountApps.currentAccountApp.id, id)) }
                    checkAccountAppKeywordPosition={ (keyword) => this.props.dispatch(checkAccountAppKeywordPosition(this.props.accountApps.currentAccountApp.id, keyword)) }
                    addAccountAppKeyword={ (keyword) => this.props.dispatch(addAccountAppKeyword(this.props.accountApps.currentAccountApp.id, keyword)) }
                  />
              }
            </Grid>
          </Paper>
        </Grid>
        <Grid container item md={8} xs={12}>
          <Paper style={{ width: '100%' }} style={{ padding: 24 }}>
            <Grid container item xs={12} alignItems="center">
              <Grid item xs>
                <Typography variant='h6'>
                  Research
                  <Chip label="Beta" variant="outlined" style={{ marginLeft: '1em' }} />
                </Typography>
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'right' }}>
                <IconButton onClick={
                  () => {
                    this.props.dispatch(showHelp(
                      'Keyword Research',
                      (
                        <React.Fragment>
                          <Typography gutterBottom>
                            This beta feature shows you the most popular keywords on the app store: the autocomplete search suggestions.
                          </Typography>
                          <Typography gutterBottom>
                            The app store has a relatively small number of keywords that account for the majority of searches. Add those most relevant to your target keywords to track your position over time and optimize your app listing.
                          </Typography>
                        </React.Fragment>
                      )
                    ))
                  }
                }>
                  <HelpIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              {researchComponent}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  const { accountApps, accountAppKeywords, keywords } = state

  return { accountApps, accountAppKeywords, keywords }
}

KeywordsContainer.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(KeywordsContainer)