import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import Typography from '@material-ui/core/Typography'
import numbro from 'numbro'

class TotalCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
    this.formatValue = this.formatValue.bind(this)
  }

  render() {
    if (this.props.valueLabel === undefined || this.props.valueLabel === null) {
      return null
    }

    let formattedValue =  this.formatValue(this.props.value)
    let formattedCompareValue = this.props.compareValueLabel === undefined || this.props.compareValueLabel === null ? null : this.formatValue(this.props.compareValue)

    let styles = {
      width: 200,
      backgroundColor: this.props.active ? this.props.backgroundColor : 'initial',
      color: this.props.active ? this.props.color : 'initial'
    }
    if (this.props.active) {
      styles.borderColor = this.props.backgroundColor
    }

    let metrics = null
    if (formattedCompareValue !== null) {
      metrics = (
        <React.Fragment>
          <Typography variant="h5" component="h2" title={this.props.value}>
            {formattedValue}
          </Typography>
          <Typography variant="subtitle2" title={this.props.valueLabel}>
            {this.props.valueLabel}
          </Typography>
          <Typography variant="h5" component="h2" title={this.props.compareValue}>
            {formattedCompareValue}
          </Typography>
          <Typography variant="subtitle2" title={this.props.compareValueLabel}>
            {this.props.compareValueLabel}
          </Typography>
        </React.Fragment>
      )
    } else {
      metrics = (
        <Typography variant="h4" component="h2" title={this.props.value}>
          {formattedValue}
        </Typography>
      )
    }

    return (
      <Card
        style={styles}
        elevation={0}
        variant='outlined'
        square={true}>
        <CardActionArea onClick={this.props.onClick}>
          <CardContent>
            <Typography variant="body2" gutterBottom>
              {this.props.label}
            </Typography>
            {metrics}
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }

  formatValue(value) {
    if (this.props.format === 'percent') {
      return `${value}%`
    } else if (this.props.format === 'average') {
      return numbro(value)
        .format({
          average: true,
          mantissa: 1,
          optionalMantissa: true
        })
        .toLocaleUpperCase()
    } else if (value === null) {
      return '–'
    } else {
      return value
    }
  }
}

TotalCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  valueLabel: PropTypes.string,
  compareValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  compareValueLabel: PropTypes.string,
  active: PropTypes.bool.isRequired,
  format: PropTypes.oneOf(['percent','average','none']),
  backgroundColor: PropTypes.string,
  color: PropTypes.string
}

export default TotalCard