import { createReducer } from '@reduxjs/toolkit'
import {
  LOADING_ATTRIBUTED_INSTALLS_STATUS,
  LOADED_ATTRIBUTED_INSTALLS_STATUS,
} from '../actions/actions'
import { Enums } from 'utils'

const initialState = {
  isLoading: false,
  accountAppId: null,
  surfaceId: null,
  fromDate: null,
  toDate: null,
  locale: null,
  data: null
}

const attributedInstalls = createReducer(initialState, {
  LOADING_ATTRIBUTED_INSTALLS_STATUS: (state, action) => {
    state.isLoading = true
    state.accountAppId = action.accountAppId
    state.surfaceId = action.surfaceId
    state.fromDate = action.fromDate
    state.toDate = action.toDate
    state.locale = action.locale
    state.data = null
  },
  LOADED_ATTRIBUTED_INSTALLS_STATUS: (state, action) => {
    state.isLoading = false
    state.accountAppId = action.accountAppId
    state.surfaceId = action.surfaceId
    state.fromDate = action.fromDate
    state.toDate = action.toDate
    state.locale = action.locale
    state.data = action.data
  },
})

export default attributedInstalls