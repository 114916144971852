import React from 'react'
import { connect } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'

class SomethingIsLoadingBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoader: false,
      timerId: null
    }
  }

  componentDidMount() {
    if (this.isLoading(this.props) === true) {
      this.showLoader()
    } else {
      this.hideLoader()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const currentIsLoading = this.isLoading(this.props)
    if (this.isLoading(prevProps) !== currentIsLoading) {
      if (currentIsLoading === true) {
        this.showLoader()
      } else {
        this.hideLoader()
      }
    }
  }

  componentWillUnmount() {
    if (this.state.timerId !== null) {
      clearTimeout(this.state.timerId)
    }
  }

  showLoader() {
    clearTimeout(this.state.timerId)
    const timerId = setTimeout(() => {
      this.setState({ showLoader: true })
    }, 800)
    this.setState({ timerId })
  }

  hideLoader() {
    clearTimeout(this.state.timerId)
    this.setState({ showLoader: false })
  }

  isLoading(props) {
    // || (props.accountAppKeywords.data !== null && props.accountAppKeywords.data.some((row) => row.checkingPosition === true))
    return props.user.isLoading === true
    || props.googleAnalytics.properties.isLoading === true
    || props.googleAnalytics.reports.some((report) => report.isLoading === true)
    || props.googleAnalytics.goalStatus.isLoading === true
    || props.team.isLoading === true
    || props.accounts.isLoading === true
    || props.accountApps.isLoading === true
    || props.appListing.history.isLoading === true
    || props.appListing.current.isLoading === true
    || props.appListing.snapshots.some((report) => report.isLoading === true)
    || props.billing.subscription.isLoading === true
    || props.billing.checkoutSession.isLoading === true
    || props.billing.portalSession.isLoading === true
    || props.apps.all.isLoading === true
    || props.apps.search.isLoading === true
    || props.accountAppKeywords.isLoading === true
    || props.rankings.reports.some((report) => report.isLoading === true)
    || props.keywords.isLoading === true
    || props.reviewNumbers.totals.isLoading === true
    || props.reviewNumbers.increase.isLoading === true
  }

    render() {
      return <LinearProgress style={{ display: this.state.showLoader === true ? 'block' : 'none' }} />
    }
}

function mapStateToProps(state) {
  const { user, googleAnalytics, team, accounts, accountApps, appListing, billing, apps, accountAppKeywords, rankings, keywords, reviewNumbers } = state

  return { user, googleAnalytics, team, accounts, accountApps, appListing, billing, apps, accountAppKeywords, rankings, keywords, reviewNumbers }
}

export default connect(mapStateToProps)(SomethingIsLoadingBar)