import { createStore, applyMiddleware } from 'redux'
import rootReducer from '../reducers/rootReducer'
import thunk from 'redux-thunk'

const middleware = __DEV__ ?
  [require('redux-immutable-state-invariant').default(), thunk] :
  [thunk]

// The User is persisted in localStorage. If you save data on the user it will appear inconsistent across devices. Instead, use a reducer that is loaded at least every time the app is loaded.
let localStorageUser = localStorage.getItem('currentUser')
let currentUser = {
  isAuthenticated: false,
  isLoading: false,
  errors: null,
  id: null,
  email: null,
  accessToken: null,
  anonymous: null,
  ahoyVisitorToken: null
}

if (localStorageUser !== null) {
  currentUser = JSON.parse(localStorageUser)
}

const store = createStore(rootReducer, { user: currentUser }, applyMiddleware(...middleware))

export default store