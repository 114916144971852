import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AuthenticatedAppBar from './AuthenticatedAppBar'
import SelectAccountApp from './SelectAccountApp'
import AccountAppsMenu from './AccountAppsMenu'

const drawerWidth = 250

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: theme.palette.primary.main,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.default,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100% - ${drawerWidth}px)`
  },
}))

function ResponsiveNavigation(props) {
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleNavigate = () => {
    if (mobileOpen === true) {
      setMobileOpen(false)
    }
  }

  let settings = (
    <React.Fragment>
      <Divider />
      <List>
        <ListSubheader>
          Settings
        </ListSubheader>
        <ListItem button component={Link} to='/l/home' onClick={handleNavigate} selected={props.location.pathname === '/l/home'}>
          <ListItemText primary='Tracked Apps' />
        </ListItem>
        <ListItem button component={Link} to='/l/google-account' onClick={handleNavigate} selected={props.location.pathname === '/l/google-account'}>
          <ListItemText primary='Google Account' />
        </ListItem>
        <ListItem button component={Link} to='/l/billing' onClick={handleNavigate} selected={props.location.pathname === '/l/billing'}>
          <ListItemText primary='Billing' />
        </ListItem>
        <ListItem button component={Link} to='/l/team' onClick={handleNavigate} selected={props.location.pathname === '/l/team'}>
          <ListItemText primary='Team' />
        </ListItem>
      </List>
    </React.Fragment>
  )
  settings = null

  const drawer = (
    <div>
      <div className={classes.toolbar} style={{ display: 'flex', alignItems: 'center', backgroundColor: theme.palette.primary.main }}>
        <Link to='/l/home'><img style={{ width: 185, marginLeft: '18px' }} alt="App Store Analytics" src="/assets/images/logo-white.png"/></Link>
      </div>
      <Divider />
      <List>
        <AccountAppsMenu onNavigate={handleNavigate} location={props.location} history={props.history} />
      </List>
      {settings}
    </div>
  )

  return (
    <div className={classes.root}>
      <AuthenticatedAppBar classes={classes} handleDrawerToggle={handleDrawerToggle} />
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
        
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div>
            {props.children}
        </div>
      </main>
    </div>
  )
}

ResponsiveNavigation.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default ResponsiveNavigation
