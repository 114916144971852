import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { showBanner, hideBanner, loadTeam, inviteTeamMember, removeTeamMember } from '../../actions/actions'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import InviteTeamMemberDialog from '../../components/team/InviteTeamMemberDialog'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import Divider from '@material-ui/core/Divider'
import AddIcon from '@material-ui/icons/Add'
import SubAppBar from '../../components/navigation/SubAppBar'

class TeamContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inviteTeamMemberDialogOpen: false,
      inviteSentSnackbarOpen: false
    }
    this.onInvite = this.onInvite.bind(this)
    this.onDelete = this.onDelete.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(loadTeam(this.props.accounts.currentAccountId))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.team.isLoading === true && this.props.team.isLoading === false && (!this.props.team.errors || this.props.team.errors === null) && this.state.inviteTeamMemberDialogOpen === true) {
      this.setState({ inviteTeamMemberDialogOpen: false, inviteSentSnackbarOpen: true })
    }
    if (prevProps.accounts.currentAccountId !== null && prevProps.accounts.currentAccountId !== this.props.accounts.currentAccountId) {

    }
    if (this.hasHitLimit(prevProps) !== this.hasHitLimit(this.props)) {
      if (this.hasHitLimit(this.props) === true) {
        this.showLimitBanner()
      } else {
        this.props.dispatch(hideBanner())
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(hideBanner())
  }

  onInvite(email, first_name, last_name) {
    this.props.dispatch(inviteTeamMember(this.props.accounts.currentAccountId, email, first_name, last_name))
  }

  onDelete(email) {
    this.props.dispatch(removeTeamMember(this.props.accounts.currentAccountId, email))
  }

  showLimitBanner() {
    this.props.dispatch(
      showBanner(
        <React.Fragment>Your plan includes {this.props.accounts.currentAccount.usersLimit} users (including you). Upgrade your plan if you'd like to add more users.</React.Fragment>,
        null,
        <Button variant="outlined" color="secondary" component={Link} to='/l/billing' style={{ whiteSpace: 'nowrap' }}>
          Upgrade your plan
        </Button>
      ))
  }

  hasHitLimit(props) {
    if (props.team.members === null) {
      return false
    }
    return props.team.members.length + 1 >= this.props.accounts.currentAccount.usersLimit // current user is not included here, but does count towards the usersLimit
  }

  render() {
    let teamMembersList
    if (this.props.team.isLoading === true || this.props.team.members === null) {
      
    } else if (this.props.team.isLoading === false) {
      const teamMembers = this.props.team.members.map(member =>
        <ListItem key={member.email}>
          <ListItemIcon>
            <IconButton edge="start" aria-label="delete" onClick={ () => this.onDelete(member.email) }>
              <DeleteIcon />
            </IconButton>
          </ListItemIcon>
          <ListItemText
            primary={member.email}
          />
        </ListItem>
      )

      teamMembersList = (
        <List>
          {teamMembers}
          <Divider />
          <ListItem button onClick={ () => this.setState({ inviteTeamMemberDialogOpen: true }) } disabled={this.hasHitLimit(this.props) === true}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="Invite User" />
          </ListItem>
        </List>
      )
    }

    let teamInviteTeamMemberDialog = null
    if (this.state.inviteTeamMemberDialogOpen === true) {
      teamInviteTeamMemberDialog = <InviteTeamMemberDialog
        isLoading={ this.props.team.isLoading }
        errors={ this.props.team.errors }
        onCancel={ () => this.setState({ inviteTeamMemberDialogOpen: false }) }
        onInvite={ this.onInvite }
      />
    }

    return (
      <React.Fragment>
        <SubAppBar title='Team' />
        <Container>
          { teamMembersList }
          { teamInviteTeamMemberDialog }
          <Snackbar
            open={this.state.inviteSentSnackbarOpen}
            autoHideDuration={6000}
            onClose={() => this.setState({ inviteSentSnackbarOpen: false })}
            message="Invite sent"
          />
        </Container>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { team, accounts } = state

  return { team, accounts }
}

export default connect(mapStateToProps)(TeamContainer)