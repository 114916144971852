import PropTypes from 'prop-types'
import 'url-search-params-polyfill'
import Typography from '@material-ui/core/Typography'
import {parseDiff, Diff, Hunk} from 'react-diff-view'
import 'react-diff-view/style/index.css'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import moment from 'moment'

class VersionDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewType: props.viewType,
      expanded: false
    }
  }

  render() {
    let appListingChangeContent = null
    let expandCollapseButton = null
    let unifiedSplitChips = null

    const selectedDiff = this.state.expanded === true ? this.props.snapshot.diffExpanded : this.props.snapshot.diffCollapsed

    const [diff] = parseDiff(selectedDiff, {  })
    const EMPTY_HUNKS = []
    appListingChangeContent = (
      <Diff viewType={this.state.viewType} diffType={diff.type} hunks={diff.hunks || EMPTY_HUNKS}>
          {() =>
              diff.hunks.map(hunk => (
                  <Hunk key={hunk.content} hunk={hunk} />
              ))
          }
      </Diff>
    )

    expandCollapseButton = this.state.expanded === true
      ? <Button variant="outlined" startIcon={<RemoveIcon />} onClick={() => this.setState({ expanded: false })}>View Differences Only</Button>
      : <Button variant="outlined" startIcon={<AddIcon />} onClick={() => this.setState({ expanded: true })}>View Whole App Listing</Button>

    unifiedSplitChips = (
      <React.Fragment>
        <Chip label="Unified"
          size="small"
          color={this.state.viewType === 'unified' ? 'primary' : 'default'}
          onClick={() => this.setState({ viewType: 'unified' })} />
        <Chip label="Split"
          size="small"
          color={this.state.viewType === 'split' ? 'primary' : 'default'}
          onClick={() => this.setState({ viewType: 'split' })} />
      </React.Fragment>
    )

    let previousListingDateText = ''
    if (this.props.snapshot.previousListingStartDate === null && this.props.snapshot.previousListingEndDate === null) {
      previousListingDateText = 'no earlier version recorded'
    } else {
      const previousListingStartDate = this.props.snapshot.previousListingStartDate === null ? 'n/a' : moment(this.props.snapshot.previousListingStartDate).format('DD MMMM YYYY')
      const previousListingEndDate = this.props.snapshot.previousListingEndDate === null ? 'n/a' : moment(this.props.snapshot.previousListingEndDate).format('DD MMMM YYYY')
      previousListingDateText = `${previousListingStartDate} - ${previousListingEndDate}`
    }

    const versionBStartDate = this.props.snapshot.currentListingStartDate === null ? 'n/a' : moment(this.props.snapshot.currentListingStartDate).format('DD MMMM YYYY')
    const versionBEndDate = this.props.snapshot.currentListingEndDate === null ? 'present' : moment(this.props.snapshot.currentListingEndDate).format('DD MMMM YYYY')

    return (
      <Grid container item style={{ marginTop: 24 }}>
        <Paper style={{ width: '100%' }}>
          <Grid container spacing={2} style={{ padding: 16, textAlign: 'center' }}>
            <Grid item xs={6}>
              <Typography variant='h6'>Previous App Listing</Typography>
              <Typography>{previousListingDateText}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6'>This App Listing</Typography>
              <Typography>{versionBStartDate} - {versionBEndDate}</Typography>
            </Grid>
          </Grid>
          <Grid container item style={{ padding: 16 }}>
            {expandCollapseButton}
            <Typography style={{ flexGrow: 1 }}></Typography>
            {unifiedSplitChips}
          </Grid>
          <Grid container item>
            {appListingChangeContent}
          </Grid>
        </Paper>
      </Grid>
    )
  }
}

VersionDetails.defaultProps = {
  viewType: 'unified'
}

VersionDetails.propTypes = {
  snapshot: PropTypes.object.isRequired,
  viewType: PropTypes.string.isRequired,
  change: PropTypes.object.isRequired
}

export default VersionDetails