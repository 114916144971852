import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import EnhancedTableHead from '../googleAnalytics/EnhancedTableHead'
import { Prompt } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CheckIcon from '@material-ui/icons/Check'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import InputAdornment from '@material-ui/core/InputAdornment'
import ClearIcon from '@material-ui/icons/Clear'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

class KeywordsTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
      selectedRows: [],
      order: 'asc',
      orderBy: 'keyword',
      enteredKeyword: '',
      searchedKeyword: ''
    }
    this.onChangePage = this.onChangePage.bind(this)
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this)
    this.onAddToTargetKeywords = this.onAddToTargetKeywords.bind(this)
    this.onRequestSort = this.onRequestSort.bind(this)
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this)
    this.onEnteredKeywordChange = this.onEnteredKeywordChange.bind(this)
    this.onSearchKeywords = this.onSearchKeywords.bind(this)
    this.onClearSearch = this.onClearSearch.bind(this)
  }

  onChangePage(e, newPage) {
    this.setState({
      page: newPage
    })
  }

  onChangeRowsPerPage(e) {
    this.setState({
      rowsPerPage: parseInt(e.target.value, 10),
      page: 0
    })
  }

  onEnteredKeywordChange(e) {
    this.setState({ enteredKeyword: e.target.value })
  }

  onAddToTargetKeywords() {
    this.state.selectedRows.forEach((keyword) => {
      this.props.addAccountAppKeyword(keyword)
    })
    this.setState({
      selectedRows: []
    })
  }

  onSearchKeywords(e) {
    e.preventDefault()
    this.setState({
      searchedKeyword: this.state.enteredKeyword,
      page: 0,
      selectedRows: []
    })
  }

  onClearSearch() {
    this.setState({
      enteredKeyword: '',
      searchedKeyword: '',
      page: 0,
      selectedRows: []
    })
  }

  onClickRow(id) {
    const selectedIndex = this.state.selectedRows.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selectedRows, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selectedRows.slice(1))
    } else if (selectedIndex === this.state.selectedRows.length - 1) {
      newSelected = newSelected.concat(this.state.selectedRows.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selectedRows.slice(0, selectedIndex),
        this.state.selectedRows.slice(selectedIndex + 1),
      )
    }

    this.setState({
      selectedRows: newSelected
    })
  }

  handleSelectAllClick(e) {
    if (e.target.checked) {
      const newSelecteds = this.stableSort(this.props.keywords, this.getComparator(this.state.order, this.state.orderBy))
        .filter(row => row.keyword.includes(this.state.searchedKeyword))
        .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
        .map((row) => row.keyword)
      this.setState({
        selectedRows: newSelecteds
      })
      return
    }
    this.setState({
      selectedRows: []
    })
  }

  onRequestSort(e, property) {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc'
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property
    })
  }

  onEnteredKeywordChange(e) {
    this.setState({
      enteredKeyword: e.target.value
    })
  }

  render() {
    return (
      <React.Fragment>
        {this.renderPositions()}
      </React.Fragment>
    )
  }

  renderPositions() {
    if (this.props.keywords === null) {
      return null
    }

    let data = this.props.keywords

    let headCells = [
      { id: 'keyword', disablePadding: false, label: 'Keyword' },
      { id: 'searchVolume', disablePadding: false, label: 'Search volume' },
      { id: 'viewInAppStore', disablePadding: false },
    ]

    const rows = this.stableSort(data, this.getComparator(this.state.order, this.state.orderBy))
      .filter(row => row.keyword.includes(this.state.searchedKeyword))
    const rowsPage = rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)

    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, rows.length - this.state.page * this.state.rowsPerPage)

    /*

      { id: 'difficulty', disablePadding: false, label: 'Difficulty' },

      <TableCell>
        {row.difficulty === null ? 'M' : row.difficulty === 'low_difficulty' ? 'L' : row.difficulty === 'medium_difficulty' ? 'M' : 'H'}
      </TableCell>

    */

    return (
      <React.Fragment>
        <TableContainer>
          <EnhancedTableToolbar
            numSelected={this.state.selectedRows.length}
            readOnly={false}
            selectedComponent={
              (
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography color="inherit" variant="subtitle1" component="div">
                      {this.state.selectedRows.length} selected
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: 'right' }}>
                    <Tooltip title="Add To Target Keywords">
                      <Button style={{ marginLeft: 8 }} color='secondary' variant='contained' onClick={this.onAddToTargetKeywords}>
                        Add To Target Keywords
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              )
            }
            noneSelectedComponent={
              (
                <form onSubmit={this.onSearchKeywords} style={{ display: 'contents' }}>
                  <TextField 
                    fullWidth
                    value={this.state.enteredKeyword}
                    label='Search Keywords'
                    onChange={this.onEnteredKeywordChange}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                      <IconButton
                        onClick={this.onClearSearch}
                      >
                        {this.state.enteredKeyword.length > 0 ? <ClearIcon /> : null}
                      </IconButton>
                    </InputAdornment>
                    }}
                  />
                  <Button style={{ marginLeft: 8, marginTop: 11 }} variant='outlined' color='secondary' type='submit'>
                    Search
                  </Button>
                </form>
              )
            }
          />
          <Table>
            <EnhancedTableHead
              order={this.state.order}
              orderBy={this.state.orderBy}
              onRequestSort={this.onRequestSort}
              rowCount={rowsPage.length}
              headCells={headCells}
              numSelected={this.state.selectedRows.length}
              onSelectAllClick={this.handleSelectAllClick}
              readOnly={false}
            />
            <TableBody>
              {rowsPage.map((row) => {
                const isItemSelected = this.state.selectedRows.indexOf(row.keyword) !== -1 //  onClick={(e) => this.onClickRow(row.keyword)}
                return (
                  <TableRow key={row.keyword} hover selected={isItemSelected}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onClick={(e) => this.onClickRow(row.keyword)}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.keyword}
                    </TableCell>
                    <TableCell>
                      {row.searchVolume === null ? 'M' : row.searchVolume === 'low_search_volume' ? 'L' : row.searchVolume === 'medium_search_volume' ? 'M' : 'H'}
                    </TableCell>
                    <TableCell>
                      <a href={`https://apps.shopify.com/search?q=${row.keyword}`} target='_blank'>
                        <IconButton size="small">
                          <OpenInNewIcon fontSize="inherit" />
                        </IconButton>
                      </a>
                    </TableCell>
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.onChangePage}
            onChangeRowsPerPage={this.onChangeRowsPerPage}
        />  
      </React.Fragment>
    )
  }

  descendingComparator(a, b, orderBy) {
    let a_for_comparison
    let b_for_comparison

    if (orderBy === 'searchVolume' || orderBy === 'difficulty') {
      a_for_comparison = a[orderBy] === null ? 0 : a[orderBy].includes('low_') ? -1 : a[orderBy].includes('medium_') ? 0 : 1
      b_for_comparison = b[orderBy] === null ? 0 : b[orderBy].includes('low_') ? -1 : b[orderBy].includes('medium_') ? 0 : 1
    } else {
      a_for_comparison = a[orderBy]
      b_for_comparison = b[orderBy]
    }
    if (b_for_comparison < a_for_comparison) {
      return -1
    }
    if (b_for_comparison > a_for_comparison) {
      return 1
    }
    return 0
  }
  
  getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy)
  }
  
  stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }
}

KeywordsTable.propTypes = {
  keywords: PropTypes.array.isRequired,
  addAccountAppKeyword: PropTypes.func.isRequired
}

export default KeywordsTable