import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { signIn } from '../../actions/actions'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Redirect } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'

class SignInContainer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: ''
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
  }

  onInputChange(event) {
    let newState = {}
    newState[event.target.name] = event.target.value
    newState.errors = {}
    this.setState(newState)
  }

  onSubmit(event) {
    event.preventDefault()
    event.stopPropagation()
    this.props.dispatch(signIn(this.state.email, this.state.password))
  }

  render() {
    const { user } = this.props

    if (user && user.isAuthenticated === true) {
      if (this.props.location.state !== undefined && this.props.location.state.from !== undefined && this.props.location.state.from.pathname.startsWith('/l/')) {
        return <Redirect to={`${this.props.location.state.from.pathname}${this.props.location.state.from.search}`} />
      } else {
        return <Redirect to='/l/home' />
      }
    }

    return (
      <Dialog open={true} disableBackdropClick={true} transitionDuration={0} fullWidth={true} disableEnforceFocus>
        <DialogTitle disableTypography={true}>
          <img style={{ height: '35px', width: 'auto' }} alt="App Store Analytics" src="/assets/images/logo.png"/>
        </DialogTitle>
        <form onSubmit={this.onSubmit}>
          <DialogContent dividers>
            <Typography variant="h5">
              Sign In
            </Typography>
            <Typography variant="body1" gutterBottom>
              Not signed up? <Link to='/sign-up'>Sign Up</Link>
            </Typography>
            <div>
              <TextField 
                autoFocus
                value={this.state.email}
                disabled={user && user.isLoading === true}
                ref='SigninEmail'
                name='email'
                label='Email'
                onChange={this.onInputChange}
                fullWidth={true}
                margin='normal'
                required={true}
                error={user && user.errors && user.errors.email ? true : false}
                helperText={user && user.errors && user.errors.email ? user.errors.email.join(', ') : null}
              />  
            </div>
            <div>
              <TextField 
                value={this.state.password}
                disabled={user && user.isLoading === true}
                ref='SigninPassword'
                name='password'
                type='password'
                label='Password'
                onChange={this.onInputChange}
                fullWidth={true}
                margin='normal'
                required={true}
                error={user && user.errors && user.errors.password ? true : false}
              />
            </div>
            <Link to='/request-password-reset'>Reset Password</Link>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' disabled={user && user.isLoading === true} color='primary' type='submit'>
              SIGN IN
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

function mapStateToProps(state) {
  const { user } = state

  return { user }
}

SignInContainer.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(SignInContainer)