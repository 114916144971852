import fetch from 'cross-fetch'
import { ApiUtils, Enums } from 'utils'
import thunk from 'redux-thunk'
import ahoy from 'ahoy.js'
import asyncPool from 'tiny-async-pool/lib/es6'

export const SIGNING_IN_STATUS = 'SIGNING_IN_STATUS'
export const SIGNED_IN_STATUS = 'SIGNED_IN_STATUS'

export function signIn(email, password) {
    return dispatch => {
        dispatch(setSigningIn())
        return fetch(ApiUtils.apiUrl('sessions'), {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                },
                body: JSON.stringify({signin: {
                email: email,
                password: password
                }})
            }
        ).then(response => response.json()
        ).then(json => dispatch(setSignInResponse(json)))
      }
}

function setSigningIn() {
    return {
        type: SIGNING_IN_STATUS
    }
}

function setSignInResponse(json) {
    let errors = json.meta && json.meta.errors ? json.meta.errors : null

    if (errors === null) {
        localStorage.setItem('currentUser', JSON.stringify(json))
    }

    return {
        type: SIGNED_IN_STATUS,
        user: {
            isAuthenticated: errors === null,
            errors: errors,
            id: json.id,
            email: json.email,
            accessToken: json.accessToken,
            anonymous: json.anonymous
        }
    }
}

export const SIGNED_OUT_STATUS = 'SIGNED_OUT_STATUS'

export function signOut() {
    localStorage.removeItem('currentUser')

    return {
        type: SIGNED_OUT_STATUS
    }
}

export const SIGNING_UP_USER_STATUS = 'SIGNING_UP_USER_STATUS'
export const SIGN_UP_USER_STATUS = 'SIGN_UP_USER_STATUS'

export function signUp(email, password, first_name, last_name) {
    return dispatch => {
        dispatch(setSigningUpUserStatus())
        const ahoyVisitorToken = ahoy.getVisitorId()
        return ApiUtils.post('signups/create', { email, password, ahoyVisitorToken, first_name, last_name }
        ).then(response => response.json()
        ).then((json) => {
            const isAuthenticated = json.errors === undefined || json.errors === null
            if (isAuthenticated === true) {
                dispatch(signOut())
            }
            dispatch(setSignUpUserStatus(json, isAuthenticated))
        })  
      }
}

function setSigningUpUserStatus() {
    return {
        type: SIGNING_UP_USER_STATUS
    }
} 

function setSignUpUserStatus(json, isAuthenticated) {
    if (isAuthenticated === true) {
        localStorage.setItem('currentUser', JSON.stringify(json))
    }

    return {
        type: SIGN_UP_USER_STATUS,
        user: {
            isAuthenticated,
            errors: json.errors,
            id: json.id,
            email: json.email,
            accessToken: json.accessToken,
            anonymous: json.anonymous
        }
    }
}

export const CREATING_ANONYMOUS_USER_STATUS = 'CREATING_ANONYMOUS_USER_STATUS'
export const CREATED_ANONYMOUS_USER_STATUS = 'CREATED_ANONYMOUS_USER_STATUS'

export function createAnonymousUser() {
    return dispatch => {
        dispatch(setCreatingAnonymousUserStatus())
        const ahoyVisitorToken = ahoy.getVisitorId()
        return ApiUtils.post('signups/anonymous', { ahoyVisitorToken }
        ).then(response => response.json()
        ).then(json => dispatch(setCreatedAnonymousUserStatus(json)))
      }
}

function setCreatingAnonymousUserStatus() {
    return {
        type: CREATING_ANONYMOUS_USER_STATUS
    }
} 

function setCreatedAnonymousUserStatus(json) {
    localStorage.setItem('currentUser', JSON.stringify(json))

    return {
        type: CREATED_ANONYMOUS_USER_STATUS,
        user: {
            isAuthenticated: true,
            errors: null,
            id: json.id,
            email: json.email,
            accessToken: json.accessToken,
            anonymous: json.anonymous
        }
    }
}

export const REQUESTING_PASSWORD_RESET_STATUS = 'REQUESTING_PASSWORD_RESET_STATUS'
export const REQUESTED_PASSWORD_RESET_STATUS = 'REQUESTED_PASSWORD_RESET_STATUS'

export function requestPasswordReset(email) {
    return (dispatch, getState) => {
        dispatch(setRequestingPasswordResetStatus())
        return ApiUtils.post(`password_reset/request_reset`, { email }
        ).then(response => response.json()
        ).then(json => {
            dispatch(setRequestedPasswordResetStatus(json, email))
        })
    }
}

function setRequestingPasswordResetStatus() {
    return {
        type: REQUESTING_PASSWORD_RESET_STATUS
    }
}

function setRequestedPasswordResetStatus(json, email) {
    return {
        type: REQUESTED_PASSWORD_RESET_STATUS,
        found: json.found,
        email
    }
}

export const RESETTING_PASSWORD_STATUS = 'RESETTING_PASSWORD_STATUS'
export const RESET_PASSWORD_STATUS = 'RESET_PASSWORD_STATUS'

export function resetPassword(token, password) {
    return (dispatch, getState) => {
        dispatch(setResettingPasswordStatus())
        return ApiUtils.post(`password_reset/reset`, { token, password }
        ).then(response => response.json()
        ).then(json => {
            dispatch(setResetPasswordStatus(json))
        })
    }
}

function setResettingPasswordStatus() {
    return {
        type: RESETTING_PASSWORD_STATUS
    }
}

function setResetPasswordStatus(json) {
    return {
        type: RESET_PASSWORD_STATUS,
        validToken: json.validToken,
        reset: json.reset,
        errors: json.errors
    }
}

export const LOADING_ACCOUNTS_STATUS = 'LOADING_ACCOUNTS_STATUS'
export const LOADED_ACCOUNTS_STATUS = 'LOADED_ACCOUNTS_STATUS'

export function loadAccounts(currentAccountId = null, currentAccountAppId = null) {
    return (dispatch, getState) => {
        dispatch(setLoadingAccountsStatus())
        return ApiUtils.get('accounts'
        ).then(response => response.json()
        ).then(json => {
            dispatch(setLoadedAccountsStatus(currentAccountId, json))
            dispatch(loadAccountApps(getState().accounts.currentAccountId, currentAccountAppId))
        })
    }
}

function setLoadingAccountsStatus() {
    return {
        type: LOADING_ACCOUNTS_STATUS
    }
} 

function setLoadedAccountsStatus(currentAccountId, json) {
    return {
        type: LOADED_ACCOUNTS_STATUS,
        currentAccountId,
        data: json
    }
}

export const UPDATING_ACCOUNT_STATUS = 'UPDATING_ACCOUNT_STATUS'
export const UPDATED_ACCOUNT_STATUS = 'UPDATED_ACCOUNT_STATUS'

export function updateAccount(accountId, googleAuthEmail, googleAuthToken, googleAuthRefreshToken, partnerApiOrganizationId, partnerApiAccessToken) {
    return (dispatch, getState) => {
        dispatch(setUpdatingAccountStatus())
        return ApiUtils.put(`accounts/${accountId}`, { googleAuthEmail, googleAuthToken, googleAuthRefreshToken, partnerApiOrganizationId, partnerApiAccessToken }
        ).then(response => {
            dispatch(setUpdatedAccountStatus(accountId, googleAuthEmail, googleAuthToken, googleAuthRefreshToken, partnerApiOrganizationId, partnerApiAccessToken))
            if (googleAuthEmail !== null)
            {
                dispatch(clearGAReports())
                dispatch(checkGAStatus(accountId))
                dispatch(clearRankings())
                dispatch(clearReviewNumbers())
            }
        })
    }
}

function setUpdatingAccountStatus() {
    return {
        type: UPDATING_ACCOUNT_STATUS
    }
} 

function setUpdatedAccountStatus(accountId, googleAuthEmail, googleAuthToken, googleAuthRefreshToken, partnerApiOrganizationId, partnerApiAccessToken) {
    return {
        type: UPDATED_ACCOUNT_STATUS,
        accountId, 
        googleAuthEmail,
        googleAuthToken,
        googleAuthRefreshToken,
        partnerApiOrganizationId,
        partnerApiAccessToken
    }
}

export const SWITCHING_ACCOUNT_STATUS = 'SWITCHING_ACCOUNT_STATUS'
export const SWITCHED_ACCOUNT_STATUS = 'SWITCHED_ACCOUNT_STATUS'

export function switchAccount(accountId) {
    return (dispatch, getState) => {
        dispatch(setSwitchedAccountStatus(accountId))
        const defaultAccountApp = getState().accountApps.forAllAccounts.find(
            x => x.accountId === accountId && x.isDefaultForAccount === true
        )
        dispatch(switchAccountApp(defaultAccountApp.id))
    }
}

function setSwitchingAccountStatus() {
    return {
        type: SWITCHING_ACCOUNT_STATUS
    }
} 

function setSwitchedAccountStatus(accountId) {
    return {
        type: SWITCHED_ACCOUNT_STATUS,
        accountId
    }
}

export const LOADING_ACCOUNT_APPS_STATUS = 'LOADING_ACCOUNT_APPS_STATUS'
export const LOADED_ACCOUNT_APPS_STATUS = 'LOADED_ACCOUNT_APPS_STATUS'

export function loadAccountApps(currentAccountId, currentAccountAppId = null) {
    return (dispatch, getState) => {
        dispatch(setLoadingAccountAppsStatus())
        return ApiUtils.get('account_apps'
        ).then(response => response.json()
        ).then((json) => {
            dispatch(setLoadedAccountAppsStatus(currentAccountId, json))
            if (currentAccountAppId !== null) {
                dispatch(switchAccountApp(currentAccountAppId))
            }
        })
    }
}

function setLoadingAccountAppsStatus() {
    return {
        type: LOADING_ACCOUNT_APPS_STATUS
    }
} 

function setLoadedAccountAppsStatus(currentAccountId, json) {
    return {
        type: LOADED_ACCOUNT_APPS_STATUS,
        currentAccountId,
        data: json
    }
}

export const SWITCHING_ACCOUNT_APP_STATUS = 'SWITCHING_ACCOUNT_APP_STATUS'
export const SWITCHED_ACCOUNT_APP_STATUS = 'SWITCHED_ACCOUNT_APP_STATUS'

export function switchAccountApp(accountAppId) {
    return dispatch => {
        dispatch(setSwitchedAccountAppStatus(accountAppId))
        dispatch(clearGAReports())
        dispatch(clearAppListingState())
        dispatch(clearAccountAppKeywordsState())
        dispatch(clearRankings())
        dispatch(clearReviewNumbers())
      }
}

function setSwitchingAccountAppStatus() {
    return {
        type: SWITCHING_ACCOUNT_APP_STATUS
    }
} 

function setSwitchedAccountAppStatus(accountAppId) {
    return {
        type: SWITCHED_ACCOUNT_APP_STATUS,
        accountAppId
    }
}

export const UPDATING_ACCOUNT_APP_STATUS = 'UPDATING_ACCOUNT_APP_STATUS'
export const UPDATED_ACCOUNT_APP_STATUS = 'UPDATED_ACCOUNT_APP_STATUS'

export function updateAccountApp(accountAppId, params) {
    return (dispatch, getState) => {
        dispatch(setUpdatingAccountAppStatus())
        return ApiUtils.put(`account_apps/${accountAppId}`, params
        ).then(response => {
            if ((params['googleAnalyticsProperty'] !== undefined && params['googleAnalyticsProperty'] !== null) || (params['googleAnalyticsPropertyGA4'] !== undefined && params['googleAnalyticsPropertyGA4'] !== null)) {
                dispatch(clearGAReports())
            }
            dispatch(setUpdatedAccountAppStatus(accountAppId, params))
        })
    }
}

function setUpdatingAccountAppStatus() {
    return {
        type: UPDATING_ACCOUNT_APP_STATUS
    }
} 

function setUpdatedAccountAppStatus(accountAppId, params) {
    return {
        type: UPDATED_ACCOUNT_APP_STATUS,
        accountAppId, 
        params
    }
}

export const ADDING_ACCOUNT_APP_STATUS = 'ADDING_ACCOUNT_APP_STATUS'
export const ADDED_ACCOUNT_APP_STATUS = 'ADDED_ACCOUNT_APP_STATUS'

export function addAccountApp(accountId, appsId, appType, googleAnalyticsProperty, googleAnalyticsPropertyGA4, bigqueryProjectId, shopifyAppId, url, name, switchAfterAdd = false) {
    const tempId = Date.now()
    return (dispatch, getState) => {
        dispatch(setAddingAccountAppStatus({ tempId, accountId, appsId, appType, googleAnalyticsProperty, googleAnalyticsPropertyGA4, bigqueryProjectId, shopifyAppId, url, name }))
        return ApiUtils.post(`account_apps`, { accountId, appsId, appType, googleAnalyticsProperty, googleAnalyticsPropertyGA4, bigqueryProjectId, shopifyAppId }
        ).then(response => response.json()
        ).then(json => {
            json.tempId = tempId
            dispatch(setAddedAccountAppStatus(json, switchAfterAdd))
        })
    }
}

function setAddingAccountAppStatus(accountApp) {
    return {
        type: ADDING_ACCOUNT_APP_STATUS,
        accountApp
    }
} 

function setAddedAccountAppStatus(data, switchAfterAdd) {
    return {
        type: ADDED_ACCOUNT_APP_STATUS,
        data,
        switchAfterAdd
    }
}

export const DELETING_ACCOUNT_APP_STATUS = 'DELETING_ACCOUNT_APP_STATUS'
export const DELETED_ACCOUNT_APP_STATUS = 'DELETED_ACCOUNT_APP_STATUS'

export function deleteAccountApp(accountAppId) {
    return (dispatch, getState) => {
        dispatch(setDeletingAccountAppStatus())
        return ApiUtils.delete(`account_apps/${accountAppId}`
        ).then(response => response.json()
        ).then(json => dispatch(setDeletedAccountAppStatus(accountAppId, json)))
    }
}

function setDeletingAccountAppStatus() {
    return {
        type: DELETING_ACCOUNT_APP_STATUS
    }
} 

function setDeletedAccountAppStatus(deletedAccountAppId, json) {
    return {
        type: DELETED_ACCOUNT_APP_STATUS,
        deletedAccountAppId,
        data: json
    }
}

export const LOADING_ACCOUNT_APP_KEYWORDS_STATUS = 'LOADING_ACCOUNT_APP_KEYWORDS_STATUS'
export const LOADED_ACCOUNT_APP_KEYWORDS_STATUS = 'LOADED_ACCOUNT_APP_KEYWORDS_STATUS'

export function loadAccountAppKeywords(accountAppId) {
    return (dispatch, getState) => {
        dispatch(setLoadingAccountAppKeywordsStatus())
        return ApiUtils.get(`account_app_keywords?accountAppId=${accountAppId}`
        ).then(response => response.json()
        ).then((json) => {
            dispatch(setLoadedAccountAppKeywordsStatus(accountAppId, json))
        })
    }
}

function setLoadingAccountAppKeywordsStatus() {
    return {
        type: LOADING_ACCOUNT_APP_KEYWORDS_STATUS
    }
} 

function setLoadedAccountAppKeywordsStatus(accountAppId, json) {
    return {
        type: LOADED_ACCOUNT_APP_KEYWORDS_STATUS,
        accountAppId,
        data: json
    }
}

export const ADDING_ACCOUNT_APP_KEYWORD_STATUS = 'ADDING_ACCOUNT_APP_KEYWORD_STATUS'
export const ADDED_ACCOUNT_APP_KEYWORD_STATUS = 'ADDED_ACCOUNT_APP_KEYWORD_STATUS'

export function addAccountAppKeyword(accountAppId, keyword) {
    const tempId = Date.now()
    return (dispatch, getState) => {
        dispatch(setAddingAccountAppKeywordStatus(tempId, keyword))
        return ApiUtils.post(`account_app_keywords`, { accountAppId, keyword }
        ).then(response => response.json()
        ).then((json) => {
            json.tempId = tempId
            dispatch(setAddedAccountAppKeywordStatus(json))
        })
    }
}

function setAddingAccountAppKeywordStatus(tempId, keyword) {
    return {
        type: ADDING_ACCOUNT_APP_KEYWORD_STATUS,
        tempId,
        keyword
    }
} 

function setAddedAccountAppKeywordStatus(data) {
    return {
        type: ADDED_ACCOUNT_APP_KEYWORD_STATUS,
        data
    }
}

export const DELETING_ACCOUNT_APP_KEYWORD_STATUS = 'DELETING_ACCOUNT_APP_KEYWORD_STATUS'
export const DELETED_ACCOUNT_APP_KEYWORD_STATUS = 'DELETED_ACCOUNT_APP_KEYWORD_STATUS'

export function deleteAccountAppKeyword(accountAppId, accountAppKeywordId) {
    return (dispatch, getState) => {
        dispatch(setDeletingAccountAppKeywordStatus(accountAppId, accountAppKeywordId))
        return ApiUtils.delete(`account_app_keywords?accountAppId=${accountAppId}&accountAppKeywordId=${accountAppKeywordId}`
        ).then(json => dispatch(setDeletedAccountAppKeywordStatus(accountAppId, accountAppKeywordId)))
    }
}

function setDeletingAccountAppKeywordStatus(accountAppId, accountAppKeywordId) {
    return {
        type: DELETING_ACCOUNT_APP_KEYWORD_STATUS,
        accountAppId,
        accountAppKeywordId
    }
} 

function setDeletedAccountAppKeywordStatus(accountAppId, accountAppKeywordId) {
    return {
        type: DELETED_ACCOUNT_APP_KEYWORD_STATUS,
        accountAppId,
        accountAppKeywordId
    }
}

export const CHECKING_ACCOUNT_APP_KEYWORD_POSITION_STATUS = 'CHECKING_ACCOUNT_APP_KEYWORD_POSITION_STATUS'
export const CHECKED_ACCOUNT_APP_KEYWORD_POSITION_STATUS = 'CHECKED_ACCOUNT_APP_KEYWORD_POSITION_STATUS'

export function checkAccountAppKeywordPosition(accountAppId, keyword = null) {
    return (dispatch, getState) => {
        dispatch(setCheckingAccountAppKeywordPositionStatus(keyword))
        const keywordQuery = keyword !== null ? `&keyword=${keyword}` : ''
        return ApiUtils.get(`account_app_keywords/check_position?accountAppId=${accountAppId}${keywordQuery}`
        ).then(response => response.json()
        ).then((json) => {
            dispatch(setCheckedAccountAppKeywordPositionStatus(json))
        })
    }
}

function setCheckingAccountAppKeywordPositionStatus(keyword) {
    return {
        type: CHECKING_ACCOUNT_APP_KEYWORD_POSITION_STATUS,
        keyword
    }
} 

function setCheckedAccountAppKeywordPositionStatus(data) {
    return {
        type: CHECKED_ACCOUNT_APP_KEYWORD_POSITION_STATUS,
        data
    }
}

export const CHECKING_ALL_ACCOUNT_APP_KEYWORD_POSITIONS_STATUS = 'CHECKING_ALL_ACCOUNT_APP_KEYWORD_POSITIONS_STATUS'
export const CHECKED_ALL_ACCOUNT_APP_KEYWORD_POSITIONS_STATUS = 'CHECKED_ALL_ACCOUNT_APP_KEYWORD_POSITIONS_STATUS'

export function checkAllAccountAppKeywordPositions(accountAppId, keywords) {
    return (dispatch, getState) => {
        dispatch(setCheckingAllAccountAppKeywordPositionsStatus())

        const checkKeyword = (k) => ApiUtils.get(`account_app_keywords/check_position?accountAppId=${accountAppId}&keyword=${k.keyword}`
        ).then(response => response.json()
        ).then((json) => {
            dispatch(setCheckedAccountAppKeywordPositionStatus(json))
        })
        return asyncPool(3, keywords, checkKeyword).then(results => {
            dispatch(setCheckedAllAccountAppKeywordPositionsStatus())
        })
    }
}

function setCheckingAllAccountAppKeywordPositionsStatus() {
    return {
        type: CHECKING_ALL_ACCOUNT_APP_KEYWORD_POSITIONS_STATUS
    }
} 

function setCheckedAllAccountAppKeywordPositionsStatus() {
    return {
        type: CHECKED_ALL_ACCOUNT_APP_KEYWORD_POSITIONS_STATUS
    }
}

export const CLEAR_ACCOUNT_APP_KEYWORDS_STATE_STATUS = 'CLEAR_ACCOUNT_APP_KEYWORDS_STATE_STATUS'

export function clearAccountAppKeywordsState() {
    return {
        type: CLEAR_ACCOUNT_APP_KEYWORDS_STATE_STATUS
    }
}

export const LOADING_ACCOUNT_USER_SETTINGS_STATUS = 'LOADING_ACCOUNT_USER_SETTINGS_STATUS'
export const LOADED_ACCOUNT_USER_SETTINGS_STATUS = 'LOADED_ACCOUNT_USER_SETTINGS_STATUS'

export function loadAccountUserSettings(accountId) {
    return (dispatch, getState) => {
        dispatch(setLoadingAccountUserSettingsStatus())
        return ApiUtils.get(`account_user_settings?accountId=${accountId}`
        ).then(response => response.json()
        ).then(json => {
            dispatch(setLoadedAccountUserSettingsStatus(json))
        })
    }
}

function setLoadingAccountUserSettingsStatus() {
    return {
        type: LOADING_ACCOUNT_USER_SETTINGS_STATUS
    }
} 

function setLoadedAccountUserSettingsStatus(json) {
    return {
        type: LOADED_ACCOUNT_USER_SETTINGS_STATUS,
        emailUpdatesFrequencyDays: json.emailUpdatesFrequencyDays
    }
}

export const UPDATING_ACCOUNT_USER_SETTINGS_STATUS = 'UPDATING_ACCOUNT_USER_SETTINGS_STATUS'
export const UPDATED_ACCOUNT_USER_SETTINGS_STATUS = 'UPDATED_ACCOUNT_USER_SETTINGS_STATUS'

export function updateAccountUserSettings(accountId, emailUpdatesFrequencyDays) {
    return (dispatch, getState) => {
        dispatch(setUpdatingAccountUserSettingsStatus(emailUpdatesFrequencyDays))
        return ApiUtils.put(`account_user_settings`, { accountId, emailUpdatesFrequencyDays }
        ).then(response => {
            dispatch(setUpdatedAccountUserSettingsStatus(emailUpdatesFrequencyDays))
        })
    }
}

function setUpdatingAccountUserSettingsStatus(emailUpdatesFrequencyDays) {
    return {
        type: UPDATING_ACCOUNT_USER_SETTINGS_STATUS,
        emailUpdatesFrequencyDays
    }
} 

function setUpdatedAccountUserSettingsStatus(emailUpdatesFrequencyDays) {
    return {
        type: UPDATED_ACCOUNT_USER_SETTINGS_STATUS,
        emailUpdatesFrequencyDays
    }
}

export const ACCEPTING_INVITE_STATUS = 'ACCEPTING_INVITE_STATUS'
export const ACCEPTED_INVITE_STATUS = 'ACCEPTED_INVITE_STATUS'

export function acceptInvite(invitationToken, password) {
    return dispatch => {
        dispatch(setAcceptingInviteStatus())
        return ApiUtils.post('invitations/accept', { invitationToken, password }
        ).then(response => response.json()
        ).then(json => dispatch(setAcceptedInviteStatus(json)))
    }
}

function setAcceptingInviteStatus() {
    return {
        type: ACCEPTING_INVITE_STATUS
    }
}

function setAcceptedInviteStatus(json) {
    if (json.errors === undefined || json.errors === null) {
        localStorage.setItem('currentUser', JSON.stringify(json))
    }

    return {
        type: ACCEPTED_INVITE_STATUS,
        user: {
            isAuthenticated: json.errors === undefined || json.errors === null,
            errors: json.errors,
            id: json.id,
            email: json.email,
            accessToken: json.accessToken
        }
    }
}

export const UPDATING_USER_STATUS = 'UPDATING_USER_STATUS'
export const UPDATED_USER_STATUS = 'UPDATED_USER_STATUS'

export function updateUser(attributes) {
    return dispatch => {
        dispatch(setUpdatingUserStatus())
        return ApiUtils.put('users/current', { user: attributes }
        ).then(response => response.json()
        ).then(json => dispatch(setUpdatedUserStatus(json)))
      }
}

function setUpdatingUserStatus() {
    return {
        type: UPDATING_USER_STATUS
    }
} 

function setUpdatedUserStatus(json) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    currentUser = json
    localStorage.setItem('currentUser', JSON.stringify(currentUser))

    return {
        type: UPDATED_USER_STATUS,
        user: currentUser
    }
}

export const REFRESHING_LOCAL_USER_STATUS = 'REFRESHING_LOCAL_USER_STATUS'
export const REFRESHED_LOCAL_USER_STATUS = 'REFRESHED_LOCAL_USER_STATUS'

export function refreshLocalUser() {
    return dispatch => {
        dispatch(setRefreshingLocalUserStatus())
        return ApiUtils.get('users/current'
        ).then(response => response.json()
        ).then(json => dispatch(setRefreshedLocalUserStatus(json)))
      }
}

function setRefreshingLocalUserStatus() {
    return {
        type: REFRESHING_LOCAL_USER_STATUS
    }
}

function setRefreshedLocalUserStatus(json) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    currentUser = json
    localStorage.setItem('currentUser', JSON.stringify(currentUser))

    return {
        type: REFRESHED_LOCAL_USER_STATUS,
        user: currentUser
    }
}


export const CHECKING_GA_STATUS = 'CHECKING_GA_STATUS'
export const CHECKED_GA_STATUS = 'CHECKED_GA_STATUS'

export function checkGAStatus(accountId) {
    return (dispatch, getState) => {
        dispatch(setCheckingGAStatus())
        return ApiUtils.get(`google_analytics/status?accountId=${accountId}`
        ).then(response => response.json()
        ).then(json => dispatch(setGAStatus(json)))
    }
}

function setCheckingGAStatus() {
    return {
        type: CHECKING_GA_STATUS
    }
} 

function setGAStatus(json) {
    return {
        type: CHECKED_GA_STATUS,
        googleAnalytics: {
            status: json.status
        }
    }
}

export const CHECKING_GA_GOAL_STATUS = 'CHECKING_GA_GOAL_STATUS'
export const CHECKED_GA_GOAL_STATUS = 'CHECKED_GA_GOAL_STATUS'

export function checkGAGoalStatus(accountAppId) {
    return (dispatch, getState) => {
        dispatch(setCheckingGAGoalStatus())
        return ApiUtils.get(`google_analytics/goal_status?accountAppId=${accountAppId}`
        ).then(response => response.json()
        ).then(json => dispatch(setGAGoalStatus(accountAppId, json)))
    }
}

function setCheckingGAGoalStatus() {
    return {
        type: CHECKING_GA_GOAL_STATUS
    }
} 

function setGAGoalStatus(accountAppId, json) {
    return {
        type: CHECKED_GA_GOAL_STATUS,
        accountAppId,
        setUp: json.setUp
    }
}

export const LOADING_GA_PROPERTIES_STATUS = 'LOADING_GA_PROPERTIES_STATUS'
export const LOADED_GA_PROPERTIES_STATUS = 'LOADED_GA_PROPERTIES_STATUS'

export function loadGAProperties(accountId) {
    return dispatch => {
        dispatch(setLoadingGAPropertiesStatus())
        return ApiUtils.get(`google_analytics/list_account_summaries?accountId=${accountId}`
        ).then(response => response.json()
        ).then(json => {
            if (json.status && json.status === Enums.GAStatus.PermissionsError) {
                dispatch(setPermissionsErrorStatus())
            } else if (json.status && json.status != Enums.GAStatus.Authed) {
                dispatch(setUnknownErrorStatus())
            } else {
                dispatch(setGAProperties(json))
            }
        })
    }
}

function setLoadingGAPropertiesStatus() {
    return {
        type: LOADING_GA_PROPERTIES_STATUS
    }
} 

function setGAProperties(json) {
    return {
        type: LOADED_GA_PROPERTIES_STATUS,
        googleAnalytics: {
            data: json.data
        }
    }
}

export const LOADING_SURFACE_TYPES_STATUS = 'LOADING_SURFACE_TYPES_STATUS'
export const LOADED_SURFACE_TYPES_STATUS = 'LOADED_SURFACE_TYPES_STATUS'

export function loadSurfaceTypes(accountAppId) {
    return dispatch => {
        dispatch(setLoadingSurfaceTypesStatus())
        return ApiUtils.get(`google_analytics/list_surface_types?accountAppId=${accountAppId}`
        ).then(response => response.json()
        ).then(arr => {
            arr.unshift('all')
            dispatch(setSurfaceTypes(arr))
        })
    }
}

function setLoadingSurfaceTypesStatus() {
    return {
        type: LOADING_SURFACE_TYPES_STATUS
    }
} 

function setSurfaceTypes(arr) {
    return {
        type: LOADED_SURFACE_TYPES_STATUS,
        surfaceTypes: {
            data: arr
        }
    }
}

export const CLEAR_GA_REPORTS_STATUS = 'CLEAR_GA_REPORTS_STATUS'

export function clearGAReports() {
    return {
        type: CLEAR_GA_REPORTS_STATUS
    }
}

export const LOADING_GA_CURRENT_TIME_SERIES_CHART_REPORT_STATUS = 'LOADING_GA_CURRENT_TIME_SERIES_CHART_REPORT_STATUS'
export const LOADED_GA_CURRENT_TIME_SERIES_CHART_REPORT_STATUS = 'LOADED_GA_CURRENT_TIME_SERIES_CHART_REPORT_STATUS'

export function loadGACurrentTimeSeriesChartReport(accountAppId, groupBy, surfaceType, locale, surfaceDetail, group, fromDate, toDate, hideDatasets, compareFromDate, compareToDate) {
    return (dispatch, getState) => {
        const loadedReports = getState().googleAnalytics.reports.filter(
            x => x.accountAppId === accountAppId && x.isLoading === false && x.errors === null
        )

        let matchedReport = undefined
        if (loadedReports !== undefined) {
            matchedReport = loadedReports.find(
                x => x.groupBy === groupBy
                && x.surfaceType === surfaceType
                && x.locale === locale
                && x.surfaceDetail === surfaceDetail
                && x.group === group
                && x.fromDate === fromDate
                && x.toDate === toDate
                && x.compareFromDate === compareFromDate
                && x.compareToDate === compareToDate
            )
        }

        if (matchedReport !== undefined) {
            dispatch(setLoadedGACurrentTimeSeriesChartReportStatus(accountAppId, matchedReport, groupBy, surfaceType, locale, surfaceDetail, group, fromDate, toDate, hideDatasets, compareFromDate, compareToDate))
        } else {
            dispatch(setLoadingGACurrentTimeSeriesChartReportStatus(accountAppId, groupBy, surfaceType, locale, surfaceDetail, group, fromDate, toDate, compareFromDate, compareToDate))
                return ApiUtils.get(buildGAReportAPIUrl(accountAppId, groupBy, surfaceType, locale, surfaceDetail, group, fromDate, toDate, compareFromDate, compareToDate)
                ).then(response => response.json()
                ).then(json => {
                    dispatch(setLoadedGACurrentTimeSeriesChartReportStatus(accountAppId, json, groupBy, surfaceType, locale, surfaceDetail, group, fromDate, toDate, hideDatasets, compareFromDate, compareToDate))
            })
        }
    }
}

function setLoadingGACurrentTimeSeriesChartReportStatus(accountAppId, groupBy, surfaceType, locale, surfaceDetail, group, fromDate, toDate, compareFromDate, compareToDate) {
    return {
        type: LOADING_GA_CURRENT_TIME_SERIES_CHART_REPORT_STATUS,
        googleAnalytics: {
            report: {
                accountAppId,
                groupBy,
                surfaceType,
                locale,
                surfaceDetail,
                group,
                fromDate,
                toDate,
                compareFromDate,
                compareToDate
            }
        }
    }
} 

function setLoadedGACurrentTimeSeriesChartReportStatus(accountAppId, json, groupBy, surfaceType, locale, surfaceDetail, group, fromDate, toDate, hideDatasets, compareFromDate, compareToDate) {
    return {
        type: LOADED_GA_CURRENT_TIME_SERIES_CHART_REPORT_STATUS,
        googleAnalytics: {
            report: {
                accountAppId,
                groupBy,
                surfaceType,
                locale,
                surfaceDetail,
                group,
                fromDate,
                toDate,
                errors: json.errors || null,
                dateRangeStatus: json.dateRangeStatus,
                data: json.data,
                hideDatasets,
                compareFromDate,
                compareToDate
            }
        }
    }
}


export const LOADING_GA_REPORT_STATUS = 'LOADING_GA_REPORT_STATUS'
export const LOADED_GA_REPORT_STATUS = 'LOADED_GA_REPORT_STATUS'

export function loadGAReport(accountAppId, groupBy, surfaceType, locale, surfaceDetail, group, fromDate, toDate, compareFromDate, compareToDate) {
    return (dispatch, getState) => {
        dispatch(setLoadingGAReportStatus(accountAppId, groupBy, surfaceType, locale, surfaceDetail, group, fromDate, toDate, compareFromDate, compareToDate))
            return ApiUtils.get(buildGAReportAPIUrl(accountAppId, groupBy, surfaceType, locale, surfaceDetail, group, fromDate, toDate, compareFromDate, compareToDate)
            ).then(response => response.json()
            ).then(json => {
                dispatch(setLoadedGAReportStatus(accountAppId, json, groupBy, surfaceType, locale, surfaceDetail, group, fromDate, toDate, compareFromDate, compareToDate))
        })
    }
}

function setLoadingGAReportStatus(accountAppId, groupBy, surfaceType, locale, surfaceDetail, group, fromDate, toDate, compareFromDate, compareToDate) {
    return {
        type: LOADING_GA_REPORT_STATUS,
        googleAnalytics: {
            report: {
                accountAppId,
                groupBy,
                surfaceType,
                locale,
                surfaceDetail,
                group,
                fromDate,
                toDate,
                compareFromDate,
                compareToDate
            }
        }
    }
} 

function setLoadedGAReportStatus(accountAppId, json, groupBy, surfaceType, locale, surfaceDetail, group, fromDate, toDate, compareFromDate, compareToDate) {
    return {
        type: LOADED_GA_REPORT_STATUS,
        googleAnalytics: {
            report: {
                accountAppId,
                groupBy,
                surfaceType,
                locale,
                surfaceDetail,
                group,
                fromDate,
                toDate,
                compareFromDate,
                compareToDate,
                errors: json.errors || null,
                dateRangeStatus: json.dateRangeStatus,
                data: json.data
            }
        }
    }
}

function buildGAReportAPIUrl(accountAppId, groupBy, surfaceType, locale, surfaceDetail, group, fromDate, toDate, compareFromDate, compareToDate) {
    let surfaceTypeQuery = ''
    if (surfaceType !== undefined && surfaceType !== 'all') {
        surfaceTypeQuery = `&surface_type=${surfaceType}`
    }
    let localeQuery = ''
    if (locale !== undefined && locale !== 'all') {
        localeQuery = `&locale=${locale}`
    }
    let surfaceDetailQuery = ''
    if (surfaceDetail !== undefined && surfaceDetail !== null) {
        surfaceDetailQuery = `&surface_detail=${surfaceDetail}`
    }
    let groupQuery = ''
    if (group !== undefined && group !== 'all') {
        groupQuery = `&group=${group}`
    }
    let fromDateQuery = ''
    if (fromDate !== undefined && fromDate !== null) {
        fromDateQuery = `&from_date=${fromDate}`
    }
    let toDateQuery = ''
    if (toDate !== undefined && toDate !== null) {
        toDateQuery = `&to_date=${toDate}`
    }
    let compareFromDateQuery = ''
    if (compareFromDate !== undefined && compareFromDate !== null) {
        compareFromDateQuery = `&compare_from_date=${compareFromDate}`
    }
    let compareToDateQuery = ''
    if (compareToDate !== undefined && compareToDate !== null) {
        compareToDateQuery = `&compare_to_date=${compareToDate}`
    }

    return `google_analytics/query_surfaces?accountAppId=${accountAppId}&group_by=${groupBy}${surfaceTypeQuery}${localeQuery}${surfaceDetailQuery}${groupQuery}${fromDateQuery}${toDateQuery}${compareFromDateQuery}${compareToDateQuery}`
}

export const PERMISSIONS_ERROR_STATUS = 'PERMISSIONS_ERROR_STATUS'
export const UNKNOWN_ERROR_STATUS = 'UNKNOWN_ERROR_STATUS'

function setPermissionsErrorStatus() {
    return {
        type: PERMISSIONS_ERROR_STATUS
    }
}

function setUnknownErrorStatus() {
    return {
        type: UNKNOWN_ERROR_STATUS
    }
}

export const CREATING_CHECKOUT_SESSION_STATUS = 'CREATING_CHECKOUT_SESSION_STATUS'
export const CREATED_CHECKOUT_SESSION_STATUS = 'CREATED_CHECKOUT_SESSION_STATUS'

export function createCheckoutSession(accountId, stripePrice) {
    return dispatch => {
        dispatch(setCreatingCheckoutSessionStatus())
        return ApiUtils.post('billing/checkout_session', { accountId, stripePrice }
        ).then(response => response.json()
        ).then(json => {
            dispatch(setCreatedCheckoutSessionStatus(json))
        })
    }
}

function setCreatingCheckoutSessionStatus() {
    return {
        type: CREATING_CHECKOUT_SESSION_STATUS
    }
}

function setCreatedCheckoutSessionStatus(json) {
    return {
        type: CREATED_CHECKOUT_SESSION_STATUS,
        sessionId: json.sessionId
    }
}

export const CREATING_PORTAL_SESSION_STATUS = 'CREATING_PORTAL_SESSION_STATUS'
export const CREATED_PORTAL_SESSION_STATUS = 'CREATED_PORTAL_SESSION_STATUS'

export function createPortalSession(accountId) {
    return dispatch => {
        dispatch(setCreatingPortalSessionStatus())
        return ApiUtils.post('billing/portal_session', { accountId }
        ).then(response => response.json()
        ).then(json => {
            dispatch(setCreatedPortalSessionStatus(json))
        })
    }
}

export function createOldPortalSession(accountId) {
    return dispatch => {
        dispatch(setCreatingPortalSessionStatus())
        return ApiUtils.post('billing/old_portal_session', { accountId }
        ).then(response => response.json()
        ).then(json => {
            dispatch(setCreatedPortalSessionStatus(json))
        })
    }
}

function setCreatingPortalSessionStatus() {
    return {
        type: CREATING_PORTAL_SESSION_STATUS
    }
}

function setCreatedPortalSessionStatus(json) {
    return {
        type: CREATED_PORTAL_SESSION_STATUS,
        portalUrl: json.portalUrl
    }
}

export const LOADING_SUBSCRIPTION_STATUS = 'LOADING_SUBSCRIPTION_STATUS'
export const LOADED_SUBSCRIPTION_STATUS = 'LOADED_SUBSCRIPTION_STATUS'

export function loadSubscription(accountId) {
    return dispatch => {
        dispatch(setLoadingSubscriptionStatus())
        return ApiUtils.get(`billing/subscription?accountId=${accountId}`
        ).then(response => response.json()
        ).then(json => {
            dispatch(setLoadedSubscriptionStatus(json))
        })
    }
}

function setLoadingSubscriptionStatus() {
    return {
        type: LOADING_SUBSCRIPTION_STATUS
    }
}

function setLoadedSubscriptionStatus(json) {
    return {
        type: LOADED_SUBSCRIPTION_STATUS,
        activeStripePrice: json.activeStripePrice
    }
}

export const CHANGING_SUBSCRIPTION_STATUS = 'CHANGING_SUBSCRIPTION_STATUS'
export const CHANGED_SUBSCRIPTION_STATUS = 'CHANGED_SUBSCRIPTION_STATUS'

export function changeSubscription(accountId, newStripePrice) {
    return (dispatch, getState) => {
        dispatch(setChangingSubscriptionStatus())
        return ApiUtils.put(`billing/subscription`, { accountId, newStripePrice }
        ).then(response => response.json()
        ).then(json => {
            dispatch(setChangedSubscriptionStatus(json))
            dispatch(loadAccounts(getState().accounts.currentAccountId))
        })
    }
}

function setChangingSubscriptionStatus() {
    return {
        type: CHANGING_SUBSCRIPTION_STATUS
    }
}

function setChangedSubscriptionStatus(json) {
    return {
        type: CHANGED_SUBSCRIPTION_STATUS,
        activeStripePrice: json.activeStripePrice
    }
}

export const LOADING_CURRENT_APP_LISTING_STATUS = 'LOADING_CURRENT_APP_LISTING_STATUS'
export const LOADED_CURRENT_APP_LISTING_STATUS = 'LOADED_CURRENT_APP_LISTING_STATUS'

export function loadCurrentAppListing(appPath, accountId, crawl = false) {
    return dispatch => {
        dispatch(setLoadingCurrentAppListingStatus())
        return ApiUtils.get(`app_listing/current?appPath=${appPath}&accountId=${accountId}&crawl=${crawl}`
        ).then(response => response.json()
        ).then(json => {
            dispatch(setLoadedCurrentAppListingStatus(json))
        })
    }
}

function setLoadingCurrentAppListingStatus() {
    return {
        type: LOADING_CURRENT_APP_LISTING_STATUS
    }
}

function setLoadedCurrentAppListingStatus(json) {
    return {
        type: LOADED_CURRENT_APP_LISTING_STATUS,
        data: json
    }
}

export const LOADING_APP_LISTING_HISTORY_STATUS = 'LOADING_APP_LISTING_HISTORY_STATUS'
export const LOADED_APP_LISTING_HISTORY_STATUS = 'LOADED_APP_LISTING_HISTORY_STATUS'

export function loadAppListingHistory(appPath, accountId) {
    return dispatch => {
        dispatch(setLoadingAppListingHistoryStatus())
        return ApiUtils.get(`app_listing/history?appPath=${appPath}&accountId=${accountId}`
        ).then(response => response.json()
        ).then(json => {
            dispatch(setLoadedAppListingHistoryStatus(json))
        })
    }
}

function setLoadingAppListingHistoryStatus() {
    return {
        type: LOADING_APP_LISTING_HISTORY_STATUS
    }
}

function setLoadedAppListingHistoryStatus(json) {
    return {
        type: LOADED_APP_LISTING_HISTORY_STATUS,
        changeDates: json.changeDates
    }
}

export const LOADING_APP_LISTING_ACTIVE_AT_STATUS = 'LOADING_APP_LISTING_ACTIVE_AT_STATUS'
export const LOADED_APP_LISTING_ACTIVE_AT_STATUS = 'LOADED_APP_LISTING_ACTIVE_AT_STATUS'

export function loadAppListingActiveAt(activeAt, appPath, accountId) {
    return (dispatch, getState) => {
        /*
        const loadedSnapshots = getState().appListing.snapshots.filter(
            x => x.isLoading === false
        )

        let matchedSnapshot = undefined
        if (loadedSnapshots !== undefined) {
            matchedSnapshot = loadedSnapshots.find(
                x => x.activeAt == activeAt
            )
        }

        if (matchedSnapshot !== undefined ) {
            dispatch(setLoadedAppListingActiveAtStatus(activeAt, matchedSnapshot))
        } else {
        */
            dispatch(setLoadingAppListingActiveAtStatus(activeAt))
                return ApiUtils.get(`app_listing/active_at?date=${activeAt}&appPath=${appPath}&accountId=${accountId}`
                ).then(response => response.json()
                ).then(json => {
                    dispatch(setLoadedAppListingActiveAtStatus(activeAt, json))
            })
        /*
        }*/
    }
}

function setLoadingAppListingActiveAtStatus(activeAt) {
    return {
        type: LOADING_APP_LISTING_ACTIVE_AT_STATUS,
        snapshot: {
            activeAt: activeAt
        }
    }
}

function setLoadedAppListingActiveAtStatus(activeAt, snapshot) {
    snapshot.activeAt = activeAt
    return {
        type: LOADED_APP_LISTING_ACTIVE_AT_STATUS,
        snapshot: snapshot
    }
}

export const CLEAR_APP_LISTING_STATE_STATUS = 'CLEAR_APP_LISTING_STATE_STATUS'

export function clearAppListingState() {
    return {
        type: CLEAR_APP_LISTING_STATE_STATUS
    }
}

export const LOADING_TEAM_STATUS = 'LOADING_TEAM_STATUS'
export const LOADED_TEAM_STATUS = 'LOADED_TEAM_STATUS'

export function loadTeam(accountId) {
    return dispatch => {
        dispatch(setLoadingTeamStatus())
        return ApiUtils.get(`team?accountId=${accountId}`
        ).then(response => response.json()
        ).then(json => dispatch(setLoadedTeamStatus(json)))
    }
}

function setLoadingTeamStatus() {
    return {
        type: LOADING_TEAM_STATUS
    }
}

function setLoadedTeamStatus(team) {
    return {
        type: LOADED_TEAM_STATUS,
        members: team.members
    }
}

export const INVITING_TEAM_MEMBER_STATUS = 'INVITING_TEAM_MEMBER_STATUS'
export const INVITED_TEAM_MEMBER_STATUS = 'INVITED_TEAM_MEMBER_STATUS'

export function inviteTeamMember(accountId, email, first_name, last_name) {
    return dispatch => {
        dispatch(setInvitingTeamMemberStatus())
        return ApiUtils.post(`team/members?accountId=${accountId}`, { email, first_name, last_name }
        ).then(response => response.json()
        ).then(json => dispatch(setInvitedTeamMemberStatus(email, json)))
    }
}

function setInvitingTeamMemberStatus() {
    return {
        type: INVITING_TEAM_MEMBER_STATUS
    }
}

function setInvitedTeamMemberStatus(email, json) {
    return {
        type: INVITED_TEAM_MEMBER_STATUS,
        errors: json.errors,
        email: email
    }
}

export const REMOVING_TEAM_MEMBER_STATUS = 'REMOVING_TEAM_MEMBER_STATUS'
export const REMOVED_TEAM_MEMBER_STATUS = 'REMOVED_TEAM_MEMBER_STATUS'

export function removeTeamMember(accountId, email) {
    return dispatch => {
        dispatch(setRemovingTeamMemberStatus())
        return ApiUtils.delete(`team/members?accountId=${accountId}`, { email: email }
        ).then(dispatch(setRemovedTeamMemberStatus(email)))
    }
}

function setRemovingTeamMemberStatus() {
    return {
        type: REMOVING_TEAM_MEMBER_STATUS
    }
}

function setRemovedTeamMemberStatus(email) {
    return {
        type: REMOVED_TEAM_MEMBER_STATUS,
        email
    }
}

export const SHOW_BANNER_STATUS = 'SHOW_BANNER_STATUS'

export function showBanner(text, icon = null, button = null) {
    return dispatch => {
        dispatch(setShowBannerStatus(text, icon, button))
    }
}

function setShowBannerStatus(text, icon, button) {
    return {
        type: SHOW_BANNER_STATUS,
        text,
        icon,
        button
    }
}

export const HIDE_BANNER_STATUS = 'HIDE_BANNER_STATUS'

export function hideBanner() {
    return dispatch => {
        dispatch(setHideBannerStatus())
    }
}

function setHideBannerStatus() {
    return {
        type: HIDE_BANNER_STATUS
    }
}

export const SHOW_HELP_STATUS = 'SHOW_HELP_STATUS'

export function showHelp(title, body) {
    return dispatch => {
        dispatch(setShowHelpStatus(title, body))
    }
}

function setShowHelpStatus(title, body) {
    return {
        type: SHOW_HELP_STATUS,
        title,
        body
    }
}

export const HIDE_HELP_STATUS = 'HIDE_HELP_STATUS'

export function hideHelp() {
    return dispatch => {
        dispatch(setHideHelpStatus())
    }
}

function setHideHelpStatus() {
    return {
        type: HIDE_HELP_STATUS
    }
}

export const LOADING_ALL_APPS_STATUS = 'LOADING_ALL_APPS_STATUS'
export const LOADED_ALL_APPS_STATUS = 'LOADED_ALL_APPS_STATUS'

export function loadAllApps() {
    return dispatch => {
        dispatch(setLoadingAllAppsStatus())
        return ApiUtils.get(`apps`
        ).then(response => response.json()
        ).then(json => {
            dispatch(setLoadedAllAppsStatus(json))
        })
    }
}

function setLoadingAllAppsStatus() {
    return {
        type: LOADING_ALL_APPS_STATUS
    }
}

function setLoadedAllAppsStatus(json) {
    return {
        type: LOADED_ALL_APPS_STATUS,
        data: json
    }
}

export const SEARCHING_APPS_STATUS = 'SEARCHING_APPS_STATUS'
export const SEARCHED_APPS_STATUS = 'SEARCHED_APPS_STATUS'

export function searchApps(appUrlPath) {
    return dispatch => {
        dispatch(setSearchingAppsStatus(appUrlPath))
        return ApiUtils.get(`apps?appUrlPath=${appUrlPath}`
        ).then(response => response.json()
        ).then(json => {
            dispatch(setSearchedAppsStatus(json))
        })
    }
}

function setSearchingAppsStatus(appUrlPath) {
    return {
        type: SEARCHING_APPS_STATUS,
        appUrlPath
    }
}

function setSearchedAppsStatus(json) {
    return {
        type: SEARCHED_APPS_STATUS,
        data: json
    }
}

export const LOADING_KEYWORDS_STATUS = 'LOADING_KEYWORDS_STATUS'
export const LOADED_KEYWORDS_STATUS = 'LOADED_KEYWORDS_STATUS'

export function loadKeywords() {
    return dispatch => {
        dispatch(setLoadingKeywordsStatus())
        return ApiUtils.get(`keywords`
        ).then(response => response.json()
        ).then(json => {
            dispatch(setLoadedKeywordsStatus(json))
        })
    }
}

function setLoadingKeywordsStatus() {
    return {
        type: LOADING_KEYWORDS_STATUS
    }
}

function setLoadedKeywordsStatus(json) {
    return {
        type: LOADED_KEYWORDS_STATUS,
        data: json
    }
}

export const LOADING_RANKINGS_STATUS = 'LOADING_RANKINGS_STATUS'
export const LOADED_RANKINGS_STATUS = 'LOADED_RANKINGS_STATUS'

export function loadRankings(accountAppId, surfaceType, fromDate, toDate, groupBy) {
    return dispatch => {
        dispatch(setLoadingRankingsStatus(surfaceType))
        return ApiUtils.get(`rankings?accountAppId=${accountAppId}&surfaceType=${surfaceType}&fromDate=${fromDate}&toDate=${toDate}&groupBy=${groupBy}`
        ).then(response => response.json()
        ).then(json => {
            dispatch(setLoadedRankingsStatus(surfaceType, json))
        })
    }
}

function setLoadingRankingsStatus(surfaceType) {
    return {
        type: LOADING_RANKINGS_STATUS,
        surfaceType
    }
}

function setLoadedRankingsStatus(surfaceType, json) {
    return {
        type: LOADED_RANKINGS_STATUS,
        surfaceType,
        data: json
    }
}

export const LOADING_COMPETITOR_RANKINGS_STATUS = 'LOADING_COMPETITOR_RANKINGS_STATUS'
export const LOADED_COMPETITOR_RANKINGS_STATUS = 'LOADED_COMPETITOR_RANKINGS_STATUS'

export function loadCompetitorRankings(accountAppId, competitorAppUrl, surfaceType, fromDate, toDate, groupBy) {
    return dispatch => {
        dispatch(setLoadingCompetitorRankingsStatus(surfaceType))
        return ApiUtils.get(`rankings/competitor?accountAppId=${accountAppId}&competitorAppUrl=${competitorAppUrl}&surfaceType=${surfaceType}&fromDate=${fromDate}&toDate=${toDate}&groupBy=${groupBy}`
        ).then(response => response.json()
        ).then(json => {
            dispatch(setLoadedCompetitorRankingsStatus(surfaceType, json))
        })
    }
}

function setLoadingCompetitorRankingsStatus(surfaceType) {
    return {
        type: LOADING_COMPETITOR_RANKINGS_STATUS,
        surfaceType
    }
}

function setLoadedCompetitorRankingsStatus(surfaceType, json) {
    return {
        type: LOADED_COMPETITOR_RANKINGS_STATUS,
        surfaceType,
        data: json
    }
}

export const CLEAR_RANKINGS_STATUS = 'CLEAR_RANKINGS_STATUS'

export function clearRankings() {
    return {
        type: CLEAR_RANKINGS_STATUS
    }
}

export const LOADING_REVIEW_NUMBERS_TOTALS_STATUS = 'LOADING_REVIEW_NUMBERS_TOTALS_STATUS'
export const LOADED_REVIEW_NUMBERS_TOTALS_STATUS = 'LOADED_REVIEW_NUMBERS_TOTALS_STATUS'

export function loadReviewNumbersTotals(accountAppId, fromDate, toDate) {
    return dispatch => {
        dispatch(setLoadingReviewNumbersTotalsStatus())
        return ApiUtils.get(`review_numbers?accountAppId=${accountAppId}&fromDate=${fromDate}&toDate=${toDate}`
        ).then(response => response.json()
        ).then(json => {
            dispatch(setLoadedReviewNumbersTotalsStatus(json))
        })
    }
}

function setLoadingReviewNumbersTotalsStatus() {
    return {
        type: LOADING_REVIEW_NUMBERS_TOTALS_STATUS
    }
}

function setLoadedReviewNumbersTotalsStatus(json) {
    return {
        type: LOADED_REVIEW_NUMBERS_TOTALS_STATUS,
        data: json
    }
}

export const LOADING_REVIEW_NUMBERS_INCREASE_STATUS = 'LOADING_REVIEW_NUMBERS_INCREASE_STATUS'
export const LOADED_REVIEW_NUMBERS_INCREASE_STATUS = 'LOADED_REVIEW_NUMBERS_INCREASE_STATUS'

export function loadReviewNumbersIncrease(accountAppId, fromDate, toDate, groupBy) {
    return dispatch => {
        dispatch(setLoadingReviewNumbersIncreaseStatus())
        return ApiUtils.get(`review_numbers/increase?accountAppId=${accountAppId}&fromDate=${fromDate}&toDate=${toDate}&groupBy=${groupBy}`
        ).then(response => response.json()
        ).then(json => {
            dispatch(setLoadedReviewNumbersIncreaseStatus(json))
        })
    }
}

function setLoadingReviewNumbersIncreaseStatus() {
    return {
        type: LOADING_REVIEW_NUMBERS_INCREASE_STATUS
    }
}

function setLoadedReviewNumbersIncreaseStatus(json) {
    return {
        type: LOADED_REVIEW_NUMBERS_INCREASE_STATUS,
        data: json
    }
}

export const CLEAR_REVIEW_NUMBERS_STATUS = 'CLEAR_REVIEW_NUMBERS_STATUS'

export function clearReviewNumbers() {
    return {
        type: CLEAR_REVIEW_NUMBERS_STATUS
    }
}

export function saveNewAppListingFormat(accountAppId, payload) {
  return (dispatch) => {
    const { app_name, app_introduction, app_description, app_highlights } =
      payload
    dispatch(setSavingNewAppListingStatus())
    return ApiUtils.post(`new_app_listings/`, {
      app_name,
      app_introduction,
      app_description,
      app_highlights,
      account_apps_id: accountAppId
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch(setSavedNewAppListingStatus(json))
      })
  }
}

export function loadNewAppListingFormat(accountAppId) {
  return (dispatch) => {
    return ApiUtils.get(`new_app_listings/${accountAppId}`)
      .then((response) => response.json())
      .then((json) => {
        dispatch(setLoadNewAppListingStatus(json))
      })
  }
}

export const SAVING_NEW_APP_LISTING_STATUS = 'SAVING_NEW_APP_LISTING_STATUS'
export const SAVED_NEW_APP_LISTING_STATUS = 'SAVED_NEW_APP_LISTING_STATUS'
export const LOAD_NEW_APP_LISTING_STATUS = 'LOAD_NEW_APP_LISTING_STATUS'
export const CLEAR_NEW_APP_LISTING_DATA = 'CLEAR_NEW_APP_LISTING_DATA'

function setSavingNewAppListingStatus() {
  return {
    type: SAVING_NEW_APP_LISTING_STATUS
  }
}

function setSavedNewAppListingStatus(json) {
  return {
    type: SAVED_NEW_APP_LISTING_STATUS,
    data: json
  }
}

function setLoadNewAppListingStatus(json) {
  return {
    type: LOAD_NEW_APP_LISTING_STATUS,
    data: json
  }
}

export function clearNewAppListingData() {
    return {
      type: CLEAR_NEW_APP_LISTING_DATA
    }
}

export const LOADING_ATTRIBUTED_INSTALLS_STATUS = 'LOADING_ATTRIBUTED_INSTALLS_STATUS'
export const LOADED_ATTRIBUTED_INSTALLS_STATUS = 'LOADED_ATTRIBUTED_INSTALLS_STATUS'

export function loadAttributedInstalls(accountAppId, surfaceId, fromDate, toDate, locale) {
    return (dispatch, getState) => {
        dispatch(setLoadingAttributedInstallsStatus())
        let url = `attributed_installs?accountAppId=${accountAppId}&surfaceId=${surfaceId}&fromDate=${fromDate}&toDate=${toDate}`
        if (locale !== null) {
            url += `&locale=${locale}`
        }
        return ApiUtils.get(url
        ).then(response => response.json()
        ).then((json) => {
            dispatch(setLoadedAttributedInstallsStatus(accountAppId, surfaceId, fromDate, toDate, locale, json))
        })
    }
}

function setLoadingAttributedInstallsStatus() {
    return {
        type: LOADING_ATTRIBUTED_INSTALLS_STATUS
    }
}

function setLoadedAttributedInstallsStatus(accountAppId, surfaceId, fromDate, toDate, locale, json) {
    return {
        type: LOADED_ATTRIBUTED_INSTALLS_STATUS,
        accountAppId,
        surfaceId,
        fromDate,
        toDate,
        locale,
        data: json
    }
}