import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import PropertiesList from './PropertiesList'
import { connect } from 'react-redux'
import { showBanner, hideBanner } from '../../actions/actions'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PropertiesListGA4 from './PropertiesListGA4'
import TextField from '@material-ui/core/TextField'

class SelectLinkedProperty extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        selectedProperty: props.currentPropertyId,
        selectedPropertyGA4: props.currentPropertyIdGA4,
        enteredBigQueryProjectId: props.currentBigQueryProjectId,
        enteredShopifyAppId: props.currentShopifyAppId,
        dialogOpen: (!props.currentPropertyId || props.currentPropertyId.length < 1) && (!props.currentPropertyIdGA4 || props.currentPropertyIdGA4.length < 1) ? true : false,
        updatingProperty: false,
        selectedTab: 1
    }
    this.showNotLinkedBanner = this.showNotLinkedBanner.bind(this)
  }

  componentDidMount() {
    if (this.props.currentPropertyId === null && this.props.currentPropertyIdGA4 === null) {
        this.showNotLinkedBanner()
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.currentPropertyId !== prevProps.currentPropertyId || this.props.currentPropertyIdGA4 !== prevProps.currentPropertyIdGA4)
        && this.state.dialogOpen === true) {
        this.setState({ dialogOpen: false, updatingProperty: false })
    }
  }

  componentWillUnmount() {
    this.props.dispatch(hideBanner())
  }

  showNotLinkedBanner() {
    this.props.dispatch(
        showBanner(
            <React.Fragment>Not linked to a Google Analytics property. Link a Google Analytics property to get all of the features of the app.</React.Fragment>,
            null,
            <Button variant="outlined" color="secondary" onClick={ () => { this.setState({ dialogOpen: true }) } } style={{ whiteSpace: 'nowrap' }}>
                Link Google Analytics Property
            </Button>
        ))
  }

  render() {
      let controls = null
      switch (this.state.selectedTab) {
        case 0:
            controls = this.renderPropertiesList()
            break
        case 1:
            controls = this.renderPropertiesListGA4()
            break
        case 2:
            controls = this.renderShopifyAppId()
            break
        case 3:
            controls = this.renderBigQuery()
            break
      }

      // Shopify deployed a new server side event that does similar to what we were doing with BigQuery, so we can use that instead
      // <Tab label="BigQuery" />
      let dialog = (
          <Dialog
              disableEnforceFocus
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth='md'
              fullWidth={true}
              open={this.state.dialogOpen}>
              <DialogTitle>
                  Linked Property
                  <DialogContentText>
                      Select the Google Analytics property that is tracking your Shopify app listing
                      at {this.props.appStoreUrl}
                  </DialogContentText>
              </DialogTitle>
              <DialogContent dividers>
                  <Tabs value={this.state.selectedTab} onChange={(obj, value) => this.setState({selectedTab: value})}>
                      <Tab label="Pre-GA4" />
                      <Tab label="GA4" />
                      <Tab label="Partner API" />

                  </Tabs>
                  {controls}
              </DialogContent>
              <DialogActions>
                  <Button disabled={this.state.updatingProperty} onClick={() => {
                      this.setState({dialogOpen: false, selectedProperty: this.props.currentPropertyId, selectedPropertyGA4: this.props.currentPropertyIdGA4})
                  }}>
                      Cancel
                  </Button>
                  <Button variant='outlined' color='primary' disabled={this.state.updatingProperty} onClick={() => {
                      this.setState({updatingProperty: true})
                      this.props.onPropertySelected(this.state.selectedProperty, this.state.selectedPropertyGA4, this.state.enteredBigQueryProjectId, this.state.enteredShopifyAppId, this.props.accountAppId)
                  }}>
                      Change Linked Property
                  </Button>
              </DialogActions>
          </Dialog>
      )

    return (
        <React.Fragment>
            { this.renderCurrentLinkedProfileName() }
            { dialog }
        </React.Fragment>
      )
  }

  renderPropertiesList() {
      return (
          <PropertiesList selectedProperty={this.state.selectedProperty} properties={this.props.properties}
            onPropertySelected={(selectedProperty) => this.setState({selectedProperty})}/>
      )
  }

  renderPropertiesListGA4() {
      return (
          <PropertiesListGA4 selectedPropertyGA4={this.state.selectedPropertyGA4} properties={this.props.properties}
            onPropertySelected={(selectedPropertyGA4) => this.setState({selectedPropertyGA4})} />
      )
  }

  renderBigQuery() {
      return (
          <React.Fragment>
            <Typography variant="body1" gutterBottom style={{ marginTop: 20 }}>
                Enter the BigQuery Project ID that's linked to your Google Analytics property.
            </Typography>
              <TextField
                  id='bigquery-project-id-text-field'
                  value={this.state.enteredBigQueryProjectId}
                  label='BigQuery Project ID'
                  fullWidth
                  onChange={ (event) => this.setState({ enteredBigQueryProjectId: event.target.value }) }>
              </TextField>
          </React.Fragment>
      )
  }

    renderShopifyAppId() {
        return (
            <React.Fragment>
                <Typography variant="body1" gutterBottom style={{ marginTop: 20 }}>
                    Find your Shopify App ID by logging into your Shopify Partner admin and clicking on the app. The App ID is the number in the URL. For example, 8888888888 here: https://partners.shopify.com/1235/apps/8888888888/overview
                </Typography>
                <TextField
                    id='shopify-app-id-text-field'
                    value={this.state.enteredShopifyAppId}
                    label='Shopify App ID'
                    fullWidth
                    onChange={ (event) => this.setState({ enteredShopifyAppId: event.target.value }) }>
                </TextField>
            </React.Fragment>
        )
    }

  renderCurrentLinkedProfileName() {
    if (this.props.hideIfLinked === true) {
        return (
            <React.Fragment>
                <Typography variant="body1" gutterBottom style={{ marginTop: 20 }}></Typography>
            </React.Fragment>
        )
    }

    const isLinked = this.props.currentPropertyId !== null || this.props.currentPropertyIdGA4 !== null

    if (!isLinked) {
        return null // 'Not linked to Google Analytics'
    }
    let currentLinkedProfileName = 'Google Analytics' //KISS
  /*
  let currentLinkedProfileName = 'a Google Analytics property that doesn\'t currently exist in your authorized Google Account'

  if (this.props.properties.data.length > 0) {
      this.props.properties.data.account_summaries.forEach((a) => {
          a.web_properties.forEach((p) => {
              p.profiles.forEach((prf) => {
                  if (this.props.currentPropertyId === prf.id) {
                      currentLinkedProfileName = `${p.name} (${p.id}) ${prf.name} `
                  }
              })
          })
      })
  }
  */
    return (
        <Typography variant="body1" gutterBottom style={{ marginTop: 20 }}>
            { `Linked to ${currentLinkedProfileName}` }
            <Button color='primary' onClick={ () => { this.setState({ dialogOpen: true }) } }>
                { isLinked ? 'Change' : 'Select Linked Property' }
            </Button>
        </Typography>
    )
  }
}

SelectLinkedProperty.defaultProps = {
    hideIfLinked: false
}

SelectLinkedProperty.propTypes = {
    currentPropertyId: PropTypes.string,
    currentPropertyIdGA4: PropTypes.string,
    currentBigQueryProjectId: PropTypes.string,
    currentShopifyAppId: PropTypes.string,
    accountAppId: PropTypes.string.isRequired,
    properties: PropTypes.object.isRequired,
    onPropertySelected: PropTypes.func.isRequired,
    appStoreUrl: PropTypes.string.isRequired,
    hideIfLinked: PropTypes.bool.isRequired
}

export default connect(null)(SelectLinkedProperty)