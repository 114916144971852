import { connect, batch } from 'react-redux'
import { loadAccountAppKeywords, addAccountAppKeyword, deleteAccountAppKeyword, checkAccountAppKeywordPosition, checkAllAccountAppKeywordPositions, loadCurrentAppListing, showHelp } from '../../actions/actions'
import { Link } from 'react-router-dom'
import SubAppBar from '../../components/navigation/SubAppBar'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import AccountAppKeywordsTable from '../../components/keywords/AccountAppKeywordsTable'
import ListingTextField from '../../components/appListing/ListingTextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepButton from '@material-ui/core/StepButton'
import Button from '@material-ui/core/Button'
import { Alert, AlertTitle } from '@material-ui/lab'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import HelpIcon from '@material-ui/icons/Help'

class AppListingEditorContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      appListingActiveAt: Date.now,
      step: 0,
      anyChanged: false,
      keywordMatches: []
    }
    this.onListingTextFieldChange = this.onListingTextFieldChange.bind(this)
    this.displayAppListingHelp = this.displayAppListingHelp.bind(this)
    this.displayMeasureHelp = this.displayMeasureHelp.bind(this)
    this.onKeywordMatch = this.onKeywordMatch.bind(this)
    this.onKeywordUnmatch = this.onKeywordUnmatch.bind(this)
  }

  componentDidMount() {
    if (this.props.accountAppKeywords.data === null && this.props.accountAppKeywords.isLoading === false) {
      this.props.dispatch(loadAccountAppKeywords(this.props.accountApps.currentAccountApp.id))
    }

    if (this.props.appListing.current.listing === null && this.props.appListing.current.isLoading === false) {
      this.props.dispatch(loadCurrentAppListing(this.props.accountApps.currentAccountApp.url, this.props.accounts.currentAccountId, true))
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.step === 1 && this.state.step === 2) {
      batch(() => {
        this.props.dispatch(checkAllAccountAppKeywordPositions(this.props.accountApps.currentAccountApp.id, this.props.accountAppKeywords.data))
        this.props.dispatch(loadCurrentAppListing(this.props.accountApps.currentAccountApp.url, this.props.accounts.currentAccountId, true))
      })
    }
  }

  onListingTextFieldChange() {
    if (this.state.anyChanged === false) {
      this.setState({ anyChanged: true })
    }
  }

  onKeywordMatch(keyword) {
    this.setState((state) => {
      const keywordMatches = [...state.keywordMatches]
      keywordMatches.push(keyword)
      return {
        keywordMatches
      }
    })
  }

  onKeywordUnmatch(keyword) {
    this.setState((state) => {
      const keywordMatches = [...state.keywordMatches]
      keywordMatches.splice(keywordMatches.indexOf(keyword), 1)
      return {
        keywordMatches
      }
    })
  }

  render() {
    let subAppBarAction = null
    if (this.state.step === 0) {
      subAppBarAction = this.renderNextStepButton()
    } else if (this.state.step === 1) {
      subAppBarAction = (
        <React.Fragment>
          <Button onClick={() =>this.setState({ step: 0 })}>
            Back
          </Button>
          {this.renderNextStepButton()}
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <SubAppBar title='App Listing Editor' rightComponent={subAppBarAction} />
        <Container>
          <Grid container spacing={2}>
            <Hidden only='sm'>
              <Grid item md={2}></Grid>
            </Hidden>
            <Grid item xs={12} md={8}>
              <Stepper activeStep={this.state.step} style={{ backgroundColor: 'inherit' }}>
                <Step>
                  <StepButton onClick={() => this.setState({ step: 0 })} completed={this.state.step > 0}>
                    Optimize
                  </StepButton>
                </Step>
                <Step>
                  <StepButton onClick={() => this.setState({ step: 1 })} completed={this.state.step > 1}>
                    Publish
                  </StepButton>
                </Step>
                <Step>
                  <StepLabel>Measure</StepLabel>
                </Step>
              </Stepper>
            </Grid>

            {this.renderStepDirections()}

            <Grid item xs={12} lg={this.state.step === 0 ? 8 : 12} style={{ display: this.state.step === 0 || this.state.step === 1 ? 'initial' : 'none' }}>

              <Paper style={{ padding: 24 }}>
                <Grid container spacing={2}>
                  <Grid container item xs={12} alignItems="center">
                    <Grid item xs>
                      <Typography variant='h6'>
                        App Listing
                      </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                      <IconButton onClick={this.displayAppListingHelp}>
                        <HelpIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  {
                    this.props.accountAppKeywords.data !== null && this.props.appListing.current.listing !== null &&
                      <React.Fragment>
                        <Grid item xs={12}>
                          <ListingTextField label='App name'
                            keywords={this.props.accountAppKeywords.data}
                            initialText={this.props.appListing.current.listing.appName}
                            maxChars={30}
                            onChanged={this.onListingTextFieldChange}
                            onKeywordMatch={this.onKeywordMatch}
                            onKeywordUnmatch={this.onKeywordUnmatch}
                            readOnly={this.state.step > 0} />
                        </Grid>
                        <Grid item xs={12}>
                          <ListingTextField label='Introduction'
                            keywords={this.props.accountAppKeywords.data}
                            initialText={this.props.appListing.current.listing.tagline}
                            maxChars={100}
                            onChanged={this.onListingTextFieldChange}
                            onKeywordMatch={this.onKeywordMatch}
                            onKeywordUnmatch={this.onKeywordUnmatch}
                            readOnly={this.state.step > 0} />
                        </Grid>
                        <Grid item xs={12}>
                          <ListingTextField label='Description'
                            keywords={this.props.accountAppKeywords.data}
                            initialText={this.props.appListing.current.listing.descriptionMarkdown}
                            maxChars={500}
                            onChanged={this.onListingTextFieldChange}
                            onKeywordMatch={this.onKeywordMatch}
                            onKeywordUnmatch={this.onKeywordUnmatch}
                            readOnly={this.state.step > 0} />
                        </Grid>
                        <Grid item xs={12}>
                          <ListingTextField label='Feature 1'
                              keywords={this.props.accountAppKeywords.data}
                              initialText={this.props.appListing.current.listing.appFeature1}
                              maxChars={80}
                              onChanged={this.onListingTextFieldChange}
                              onKeywordMatch={this.onKeywordMatch}
                              onKeywordUnmatch={this.onKeywordUnmatch}
                              readOnly={this.state.step > 0} />
                        </Grid>
                        <Grid item xs={12}>
                          <ListingTextField label='Feature 2'
                              keywords={this.props.accountAppKeywords.data}
                              initialText={this.props.appListing.current.listing.appFeature2}
                              maxChars={80}
                              onChanged={this.onListingTextFieldChange}
                              onKeywordMatch={this.onKeywordMatch}
                              onKeywordUnmatch={this.onKeywordUnmatch}
                              readOnly={this.state.step > 0} />
                        </Grid>
                        <Grid item xs={12}>
                          <ListingTextField label='Feature 3'
                              keywords={this.props.accountAppKeywords.data}
                              initialText={this.props.appListing.current.listing.appFeature3}
                              maxChars={80}
                              onChanged={this.onListingTextFieldChange}
                              onKeywordMatch={this.onKeywordMatch}
                              onKeywordUnmatch={this.onKeywordUnmatch}
                              readOnly={this.state.step > 0} />
                        </Grid>
                        <Grid item xs={12}>
                          <ListingTextField label='Feature 4'
                              keywords={this.props.accountAppKeywords.data}
                              initialText={this.props.appListing.current.listing.appFeature4}
                              maxChars={80}
                              onChanged={this.onListingTextFieldChange}
                              onKeywordMatch={this.onKeywordMatch}
                              onKeywordUnmatch={this.onKeywordUnmatch}
                              readOnly={this.state.step > 0} />
                        </Grid>
                        <Grid item xs={12}>
                          <ListingTextField label='Feature 5'
                              keywords={this.props.accountAppKeywords.data}
                              initialText={this.props.appListing.current.listing.appFeature5}
                              maxChars={80}
                              onChanged={this.onListingTextFieldChange}
                              onKeywordMatch={this.onKeywordMatch}
                              onKeywordUnmatch={this.onKeywordUnmatch}
                              readOnly={this.state.step > 0} />
                        </Grid>
                      </React.Fragment>
                  }
                  <Grid item xs={12}>
                    {this.renderNextStepButton()}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} lg={ this.state.step === 0 ? 4 : 12} style={{ display: this.state.step === 0 || this.state.step === 2 ? 'initial' : 'none' }}>
              <Paper style={{ padding: 24 }}>
                <Grid container spacing={2}>
                  <Grid container item xs={12} alignItems="center">
                    <Grid item xs>
                      <Typography variant='h6'>
                        Target Keywords
                      </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                      <IconButton onClick={ this.state.step === 2 ? this.displayMeasureHelp : this.displayAppListingHelp}>
                        <HelpIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {
                      this.state.step === 0 && this.props.accountAppKeywords.data !== null && this.props.accountAppKeywords.data.length > 30 &&
                      <Alert severity="warning">
                        <AlertTitle>Targeting too many keywords can be slow</AlertTitle>
                        You've entered more than 30 keywords. Aim for around 20. Many more, and the final Measure step can be slow.
                      </Alert>
                    }
                    {
                      this.props.accountAppKeywords.data !== null &&
                        <AccountAppKeywordsTable
                          positionLabel={this.state.step === 2 ? 'New Position' : 'Current Position'}
                          displayPreviousPosition={this.state.step === 2}
                          displayPosition={this.state.step === 2}
                          readOnly={this.state.step === 2}
                          accountAppKeywords={this.props.accountAppKeywords}
                          deleteAccountAppKeyword={ (id) => this.props.dispatch(deleteAccountAppKeyword(this.props.accountApps.currentAccountApp.id, id)) }
                          checkAccountAppKeywordPosition={ (keyword) => this.props.dispatch(checkAccountAppKeywordPosition(this.props.accountApps.currentAccountApp.id, keyword)) }
                          addAccountAppKeyword={ (keyword) => this.props.dispatch(addAccountAppKeyword(this.props.accountApps.currentAccountApp.id, keyword)) }
                          matches={this.state.keywordMatches}
                          displayMatches={this.state.step === 0}
                        />
                    }
                    {
                      this.state.step === 0 && this.props.accountAppKeywords.data !== null && this.props.accountAppKeywords.data.length < 1 &&
                      <Alert severity="info">
                        <AlertTitle>Start by entering some Target Keywords</AlertTitle>
                      </Alert>
                    }
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

          </Grid>
        </Container>
      </React.Fragment>
    )
  }

  displayAppListingHelp() {
    this.props.dispatch(showHelp(
      'Optimize',
      (
        <React.Fragment>
          <Typography variant='h6' gutterBottom>
            Target Keywords
          </Typography>
          <Typography gutterBottom>
            Enter around 20 Target Keywords to use in optimizing your App Listing. Think about the keywords and phrases merchants type into App Store search.
          </Typography>
          <Typography gutterBottom>
            You'll see a before and after position for each of these Target Keywords at the end of publishing.
          </Typography>
          <Typography variant='h6' gutterBottom>
            App Listing
          </Typography>
          <Typography gutterBottom>
            The most important keyword ranking elements of the app listing are available to edit here.
          </Typography>
          <Typography gutterBottom>
            Your Target Keywords are highlighted in darker text so that you can make the best use of available space.
          </Typography>
          <Typography gutterBottom>
            The app name and tagline carry the most weight for ranking. Use a minimum of punctuation and non-keywords here, while remaining coherent and non-spammy.
          </Typography>
          <Typography gutterBottom>
            Key benefits and description keywords are also used for ranking. Avoid sentences or whole paragraphs containing no keywords, while being mindful that merchants will decide to install your app or not by reading this text.
          </Typography>
        </React.Fragment>
      )
    ))
  }

  displayMeasureHelp() {
    this.props.dispatch(showHelp(
      'Measure',
      (
        <React.Fragment>
          <Typography gutterBottom>
            Your keyword positions before and after optimization are shown.
          </Typography>
          <Typography gutterBottom>
            Position changes are usually instantaneous after publishing the App Listing.
          </Typography>
          <Typography gutterBottom>
            Occasionally you may see fluctuations in your ranking between searches. This tends to be +/- 4-8 positions. It can be due to ad slots, cached results, or experiments being ran by Shopify.
          </Typography>
        </React.Fragment>
      )
    ))
  }

  renderStepDirections() {
    let title = null
    let directions = null

    if (this.state.step === 0) {
      title = 'Optimize'
      directions = (
        <React.Fragment>
          <Typography gutterBottom>
            Add around 20 Target Keywords. Edit your App Listing below, optimizing for them.
          </Typography>
          <Typography gutterBottom>
            When you're finished optimizing, push Publish.
          </Typography>
        </React.Fragment>
      )
    } else if (this.state.step === 1) {
      title = 'Publish'
      directions = (
        <React.Fragment>
          <Typography gutterBottom>
            <a href='https://partners.shopify.com/organizations' target='_blank'>Go to your Shopify Partner Admin</a> and open the app listing editor. Copy your optimized App Listing below and paste it in there. Push Save and update.
          </Typography>
          <Typography gutterBottom>
            When you've published your optimized app listing there, push Measure here.
          </Typography>
        </React.Fragment>
      )
    } else if (this.state.step === 2) {
      title = 'Measure'
      directions = (
        <React.Fragment>
          <Typography gutterBottom>
            <strong>Now:</strong> Check your new target keyword positions below. You can <a href='#' onClick={ () => this.setState({ step: 0 }) }> go back to Optimize</a> if your positions aren't as you want.
          </Typography>
          <Typography gutterBottom>
            <strong>In a couple of days:</strong> <Link to={`./listing-history`}>Check how you're performing in search by opening the change in Changelog</Link> and pushing 'Compare in Analytics'.
          </Typography>
        </React.Fragment>
      )
    }
/*
        <Hidden only='sm'>
          
        </Hidden>
*/
    return (
      <Grid item container xs={12}>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
          <Typography variant='h5' gutterBottom>{title}</Typography>
          {directions}
        </Grid>
      </Grid>
    )
  }

  renderNextStepButton() {
    if (this.state.step === 0) {
      return (
        <Button
          variant="contained"
          color="secondary"
          disabled={this.state.anyChanged === false}
          onClick={() => {
            this.setState({ step: 1 })
            window.scrollTo({top: 0, behavior: 'smooth'})
          }}>
          Publish
        </Button>
      )
    } else if (this.state.step === 1) {
      return (
        <Button variant="contained" color="secondary" onClick={() => {
          this.setState({ step: 2 })
          window.scrollTo({top: 0, behavior: 'smooth'})
        }}>
          Measure
        </Button>
      )
    } else {

    }
  }
}

function mapStateToProps(state) {
  const { accountApps, accounts, accountAppKeywords, appListing } = state

  return { accountApps, accounts, accountAppKeywords, appListing }
}

AppListingEditorContainer.propTypes = {

}

export default connect(mapStateToProps)(AppListingEditorContainer)