import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import 'url-search-params-polyfill'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import ResponsiveNavigation from "./components/navigation/ResponsiveNavigation"
import ahoy from "ahoy.js"

import {
  GoogleAnalyticsContainer,
  AppListingTrafficContainer,
  SignInContainer,
  SignOutContainer,
  SignUpContainer,
  AcceptInviteContainer,
  BillingContainer,
  TeamContainer,
  AccountsContainer,
  LoggedInHomeContainer,
  AppListingHistoryContainer,
  RequestPasswordResetContainer,
  ResetPasswordContainer,
  SetupContainer,
  AuthenticatedBaseContainer,
  CurrentAppBaseContainer,
  AnonymousContainer,
  AnonymousHomeContainer,
  KeywordsContainer,
  AppListingEditorContainer,
  PositionsContainer,
  ReviewsContainer,
  PartnerApiContainer
} from './containers'

const theme = createMuiTheme({
  overrides: {
    MuiListItemAvatar: {
      root: {
        minWidth: 0
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 0
      }
    }
  },
  palette: {
    primary: {
      light: "#6d8290",
      main: "#505e68",
      dark: "#2f363b",
      contrastText: "#fff"
    }
  }
})

// https://stackoverflow.com/a/41733396
const NavRoute = ({ exact, path, component: Component }) => (
  <Route
    exact={exact}
    path={path}
    render={(props) => (
      <React.Fragment>
        <AppBar>
          <Toolbar>
            <img
              style={{ height: "25px", width: "auto", marginLeft: "18px" }}
              alt="App Store Analytics"
              src="/assets/images/logo.png"
            />
          </Toolbar>
        </AppBar>
        <Toolbar />
        <Component {...props} />
      </React.Fragment>
    )}
  />
)

class Routes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    ahoy.configure({
      cookieDomain: CONFIG.ahoyCookieDomain,
      urlPrefix: CONFIG.apiUrl
    })
    //alert(ahoy.getVisitorId())
    //ahoy.track('test', { testing: true })
    /*
    WARNING: was not sure what this was used for, commenting out with no testing
    if (this.props.user && this.props.user.isAuthenticated !== false) {
      let query = new URLSearchParams(location.search)
      let currentAccountId = query.get('accountId')
      this.props.dispatch(loadAccounts(currentAccountId))
    }
    */
  }

  componentDidUpdate(prevProps) {
    /*
    WARNING: was not sure what this was used for, commenting out with no testing
    if ((!prevProps.user || prevProps.user.id === null) && this.props.user && this.props.user.isAuthenticated !== false) {
      let query = new URLSearchParams(location.search)
      let currentAccountId = query.get('accountId')
      this.props.dispatch(loadAccounts(currentAccountId))
    }
    */
  }

  render() {
    let authenticatedRoutes = null
    if (!this.props.user || this.props.user.isAuthenticated === false) {
      authenticatedRoutes = (
        <Switch>
          <Route exact path="/">
            <Redirect to="/sign-in" />
          </Route>
          <Route
            path="/sign-in"
            render={(props) => <SignInContainer {...props} />}
          />
          <Route path="/sign-up">
            <SignUpContainer />
          </Route>
          <Route
            path="/accept-invite"
            render={(props) => <AcceptInviteContainer {...props} />}
          />
          <Route
            path="/request-password-reset"
            render={(props) => <RequestPasswordResetContainer {...props} />}
          />
          <Route
            path="/reset-password"
            render={(props) => <ResetPasswordContainer {...props} />}
          />
          <Route
            path="/a/apps/:appUrlPath/listing-history"
            render={(props) => <AnonymousContainer {...props} />}
          />
          <Route
            path="/a/home"
            render={(props) => <AnonymousContainer {...props} />}
          />
          <Route
            path="*"
            render={(props) => (
              <Redirect
                to={{ pathname: "/sign-in", state: { from: props.location } }}
              />
            )}
          />
        </Switch>
      )
    } else {
      authenticatedRoutes = (
        <Route
          path="*"
          render={(props) => (
            <AuthenticatedBaseContainer {...props}>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/l/home" />
                </Route>
                <Route
                  path="/sign-in"
                  render={(props) => <SignInContainer {...props} />}
                />
                <Route
                  path="/sign-up"
                  render={(props) => {
                    if (this.props.user.anonymous !== false) {
                      return <SignUpContainer {...props} />
                    } else {
                      return <Redirect to="/l/home" />
                    }
                  }}
                />
                <Route path="/accept-invite">
                  <Redirect to="/l/home" />
                </Route>
                <Route path="/a/home">
                  <Redirect to="/l/home" />
                </Route>
                <Route
                  path="/a/apps/:appUrlPath/listing-history"
                  render={(props) => (
                    <Redirect
                      to={`/l/apps/${props.match.params.appUrlPath}/listing-history`}
                    />
                  )}
                />
                <Route
                  path="/l/setup"
                  render={(props) => <SetupContainer {...props} />}
                />
                <Route
                  path="/l"
                  render={(props) => (
                    <ResponsiveNavigation {...props}>
                      <Switch>
                        <Route
                          path="/l/home"
                          render={(props) => {
                            if (this.props.user.anonymous !== false) {
                              return <AnonymousHomeContainer {...props} />
                            } else {
                              return <LoggedInHomeContainer {...props} />
                            }
                          }}
                        />
                        <Route
                          path="/l/billing"
                          render={(props) => {
                            if (this.props.user.anonymous !== false) {
                              return <Redirect to="/sign-up" />
                            } else {
                              return <BillingContainer {...props} />
                            }
                          }}
                        />
                        <Route
                          path="/l/google-account"
                          render={(props) => (
                            <GoogleAnalyticsContainer
                              title="Google Account"
                              {...props}
                            />
                          )}
                        />
                        <Route
                          path="/l/team"
                          render={(props) => <TeamContainer {...props} />}
                        />
                        <Route
                          path="/l/accounts"
                          render={(props) => <AccountsContainer {...props} />}
                        />
                        <Route
                          path="/l/partner-api"
                          render={(props) => <PartnerApiContainer {...props} />}
                        />
                        <Route
                          path="/l/apps/:appUrlPath"
                          render={(props) => (
                            <CurrentAppBaseContainer {...props}>
                              <Switch>
                                <Route
                                  path="/l/apps/:appUrlPath/listing-history"
                                  render={(props) => (
                                    <AppListingHistoryContainer {...props} />
                                  )}
                                />
                                <Route
                                  path="/l/apps/:appUrlPath/listing-traffic"
                                  render={(props) => (
                                    <GoogleAnalyticsContainer
                                      title="Analytics"
                                      {...props}
                                    >
                                      <AppListingTrafficContainer {...props} />
                                    </GoogleAnalyticsContainer>
                                  )}
                                />
                                <Route
                                  path="/l/apps/:appUrlPath/keywords"
                                  render={(props) => (
                                    <GoogleAnalyticsContainer
                                      title="Keywords"
                                      {...props}
                                      hideIfLinked={true}
                                    >
                                      <KeywordsContainer {...props} />
                                    </GoogleAnalyticsContainer>
                                  )}
                                />
                                <Route
                                  path="/l/apps/:appUrlPath/listing-editor"
                                  render={(props) => (
                                    <AppListingEditorContainer {...props} />
                                  )}
                                />
                                <Route
                                  path="/l/apps/:appUrlPath/positions"
                                  render={(props) => (
                                    <PositionsContainer {...props} />
                                  )}
                                />
                                <Route
                                  path="/l/apps/:appUrlPath/reviews"
                                  render={(props) => (
                                    <ReviewsContainer {...props} />
                                  )}
                                />
                              </Switch>
                            </CurrentAppBaseContainer>
                          )}
                        />
                      </Switch>
                    </ResponsiveNavigation>
                  )}
                />
                <Route path="*">
                  <div>Not found here</div>
                </Route>
              </Switch>
            </AuthenticatedBaseContainer>
          )}
        />
      )
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Router>
            <Route path="/sign-out">
              <SignOutContainer />
            </Route>
            {authenticatedRoutes}
          </Router>
        </ThemeProvider>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { user, accounts, accountApps } = state

  return { user, accounts, accountApps }
}
export default connect(mapStateToProps)(Routes)