import { createReducer } from '@reduxjs/toolkit'
import {
  SIGNING_IN_STATUS,
  SIGNED_IN_STATUS,
  SIGNED_OUT_STATUS,
  UPDATING_USER_STATUS,
  UPDATED_USER_STATUS,
  REFRESHING_LOCAL_USER_STATUS,
  REFRESHED_LOCAL_USER_STATUS,
  SIGNING_UP_USER_STATUS,
  SIGN_UP_USER_STATUS,
  ACCEPTING_INVITE_STATUS,
  ACCEPTED_INVITE_STATUS
} from '../actions/actions'

// see the store for the default initial state of user

const user = createReducer(null, {
  SIGNING_IN_STATUS: (state, action) => { 
    state.isAuthenticated = false
    state.isLoading = true
    state.errors = null
    state.id = null
    state.email = null
    state.accessToken = null
    state.anonymous = null
    state.ahoyVisitorToken = null
  },
  SIGNED_IN_STATUS: (state, action) => {
    state.isAuthenticated = action.user.isAuthenticated
    state.isLoading = false
    state.errors = action.user.errors
    state.id = action.user.id
    state.email = action.user.email
    state.accessToken = action.user.accessToken
    state.anonymous = action.user.anonymous
    state.ahoyVisitorToken = action.user.ahoyVisitorToken
  },
  CREATING_ANONYMOUS_USER_STATUS: (state, action) => { 
    state.isAuthenticated = false
    state.isLoading = true
    state.errors = null
    state.id = null
    state.email = null
    state.accessToken = null
    state.anonymous = null
    state.ahoyVisitorToken = null
  },
  CREATED_ANONYMOUS_USER_STATUS: (state, action) => {
    state.isAuthenticated = true
    state.isLoading = false
    state.errors = action.user.errors
    state.id = action.user.id
    state.email = action.user.email
    state.accessToken = action.user.accessToken
    state.anonymous = action.user.anonymous
    state.ahoyVisitorToken = action.user.ahoyVisitorToken
  },
  SIGNED_OUT_STATUS: (state, action) => { 
    state.isAuthenticated = false
    state.isLoading = false
    state.errors = null
    state.id = null
    state.email = null
    state.accessToken = null
    state.anonymous = null
    state.ahoyVisitorToken = null
  },
  UPDATING_USER_STATUS: (state, action) => {
    state.isLoading = true
  },
  UPDATED_USER_STATUS: (state, action) => {
    state.isLoading = false
  },
  REFRESHING_LOCAL_USER_STATUS: (state, action) => {
    state.isLoading = true
  },
  REFRESHED_LOCAL_USER_STATUS: (state, action) => {
    state.isAuthenticated = action.user.isAuthenticated
    state.isLoading = false
    state.errors = action.user.errors
    state.id = action.user.id
    state.email = action.user.email
    state.accessToken = action.user.accessToken
    state.anonymous = action.user.anonymous
    state.ahoyVisitorToken = action.user.ahoyVisitorToken
  },
  SIGNING_UP_USER_STATUS: (state, action) => {
    state.isLoading = true
  },
  SIGN_UP_USER_STATUS: (state, action) => {
    state.isAuthenticated = action.user.isAuthenticated
    state.isLoading = false
    state.errors = action.user.errors
    state.id = action.user.id
    state.email = action.user.email
    state.accessToken = action.user.accessToken
    state.anonymous = action.user.anonymous
    state.ahoyVisitorToken = action.user.ahoyVisitorToken
  },
  ACCEPTING_INVITE_STATUS: (state, action) => {
    state.isLoading = true
  },
  ACCEPTED_INVITE_STATUS: (state, action) => {
    state.isAuthenticated = action.user.isAuthenticated
    state.isLoading = false
    state.errors = action.user.errors
    state.id = action.user.id
    state.email = action.user.email
    state.accessToken = action.user.accessToken
    state.anonymous = action.user.anonymous
    state.ahoyVisitorToken = action.user.ahoyVisitorToken
  },
  REQUESTING_PASSWORD_RESET_STATUS: (state, action) => {
    state.isLoading = true
    state.errors = false
  },
  REQUESTED_PASSWORD_RESET_STATUS: (state, action) => {
    state.isLoading = false
    state.errors = action.found !== true ? "A user doesn't exist for this email" : null
    state.email = action.found !== true ? null : action.email
  },
  RESETTING_PASSWORD_STATUS: (state, action) => {
    state.isLoading = true
    state.errors = false
  },
  RESET_PASSWORD_STATUS: (state, action) => {
    state.isLoading = false
    state.errors = action.validToken !== true ? { validToken: false } : null
    state.email = action.validToken === true && action.reset === true ? action.email : null
  },
})
  
export default user