import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  totals: {
    isLoading: false,
    data: null
  },
  increase: {
    isLoading: false,
    data: null
  },
}

const reviewNumbers = createReducer(initialState, {
  CLEAR_REVIEW_NUMBERS_STATUS: (state, action) => {
    state.totals.data = null
    state.increase.data = null
  },
  LOADING_REVIEW_NUMBERS_TOTALS_STATUS: (state, action) => {
    state.totals.isLoading = true
  },
  LOADED_REVIEW_NUMBERS_TOTALS_STATUS: (state, action) => {
    state.totals.isLoading = false
    state.totals.data = action.data
  },
  LOADING_REVIEW_NUMBERS_INCREASE_STATUS: (state, action) => {
    state.increase.isLoading = true
  },
  LOADED_REVIEW_NUMBERS_INCREASE_STATUS: (state, action) => {
    state.increase.isLoading = false

    // somewhat strange we're dealing with colors in the store, but this is to avoid cloning/mutating data since the chart component blends these in its properties
    colorize(action.data, 'new_five_stars', 'rgb(51,193,92)')
    colorize(action.data, 'new_four_stars', 'rgb(113,212,141)')
    colorize(action.data, 'new_three_stars', 'rgb(195,236,206)')
    colorize(action.data, 'new_two_stars', 'rgb(247,203,218)')
    colorize(action.data, 'new_one_stars', 'rgb(227,81,134)')

    let dataIndex = action.data.findIndex(x => x.name == 'overall_rating')
    if (dataIndex !== -1 && action.data[dataIndex].library == undefined) {
      const overallRatingColor = 'rgb(51,51,79)'
      action.data[dataIndex].library = {
        type: 'line',
        yAxisID: 'rating-y-axis',
        order: 1,
        borderColor: overallRatingColor,
        backgroundColor: overallRatingColor,
        pointBackgroundColor: overallRatingColor,
        pointBorderColor: overallRatingColor,
        pointHoverBackgroundColor: overallRatingColor,
        pointHoverBorderColor: overallRatingColor,
        label: cleanLabel(action.data[dataIndex].name)
      }
    }

    state.increase.data = action.data
  },
})

function cleanLabel(text) {
  return text.toLowerCase()
    .split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

function colorize(data, name, color) {
  let dataIndex = data.findIndex(x => x.name == name)
  if (dataIndex !== -1 && data[dataIndex].library == undefined) {
     data[dataIndex].library = {
      borderColor: color,
      backgroundColor: color,
      pointBackgroundColor: color,
      pointBorderColor: color,
      pointHoverBackgroundColor: color,
      pointHoverBorderColor: color,
      yAxisID: 'reviews-y-axis',
      order: 2,
      label: cleanLabel(data[dataIndex].name)
     }
  }
}

export default reviewNumbers