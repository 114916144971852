import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { acceptInvite } from '../../actions/actions'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'
import { Redirect } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import 'url-search-params-polyfill'

class AcceptInviteContainer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      password: '',
      invitationToken: null
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
  }

  componentDidMount() {
    let query = new URLSearchParams(this.props.location.search)
    let invitationToken = query.get('it')
    if (invitationToken) {
      this.props.history.replace(this.props.location.pathname)
      this.setState({ invitationToken })
    }
  }

  onInputChange(event) {
    let newState = {}
    newState[event.target.name] = event.target.value
    newState.errors = {}
    this.setState(newState)
  }

  onSubmit(event) {
    event.preventDefault()
    event.stopPropagation()
    this.props.dispatch(acceptInvite(this.state.invitationToken, this.state.password))
  }

  render() {
    const { user } = this.props

    if (user && user.isAuthenticated === true) {
      return <Redirect to='/l/home' />
    }

    let dialogBody = null
    if (this.state.invitationToken === null) {
      dialogBody = (
        <DialogContent dividers>
          <Typography variant="h5">
            Accept Invite
          </Typography>
          <Typography variant="body1" gutterBottom>
            Error: The invite link is invalid. Please try following the link again.
          </Typography>
        </DialogContent>
      )
    } else if (user && user.errors && user.errors.invite) {
      dialogBody = (
        <DialogContent dividers>
          <Typography variant="h5">
            Accept Invite
          </Typography>
          <Typography variant="body1" gutterBottom>
            Error: {user.errors.invite}
          </Typography>
        </DialogContent>
      )
    } else {
      dialogBody = (
        <form onSubmit={this.onSubmit}>
          <DialogContent dividers>
            <Typography variant="h5">
              Accept Invite
            </Typography>
            <Typography variant="body1" gutterBottom>
              Choose a password
            </Typography>
            <div>
              <TextField 
                autoFocus
                value={this.state.password}
                disabled={user && user.isLoading === true}
                ref='SignUpPassword'
                name='password'
                type='password'
                label='Password'
                onChange={this.onInputChange}
                fullWidth={true}
                margin='normal'
                required={true}
                error={user && user.errors && user.errors.password ? true : false}
                helperText={user && user.errors && user.errors.password ? user.errors.password.join(', ') : null}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' disabled={user && user.isLoading === true} color='primary' type='submit'>
              ACCEPT INVITE
            </Button>
          </DialogActions>
        </form>
      )
    }

    return (
      <Dialog open={true} disableBackdropClick={true} transitionDuration={0} disableEnforceFocus>
        <DialogTitle disableTypography={true}>
          <img style={{ height: '35px', width: 'auto' }} alt="App Store Analytics" src="/assets/images/logo.png"/>
        </DialogTitle>
        {dialogBody}
      </Dialog>
    )
  }
}

function mapStateToProps(state) {
  const { user } = state

  return {
    user
  }
}

AcceptInviteContainer.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(AcceptInviteContainer)