import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  requestingPasswordReset: false,
  requestPasswordResetErrors: null,
  requestedPasswordReset: false,

  resettingPassword: false,
  resetPasswordValidToken: null,
  resetPasswordErrors: null,
  resetPassword: false
}

const user = createReducer(initialState, {
  REQUESTING_PASSWORD_RESET_STATUS: (state, action) => {
    state.requestingPasswordReset = true
    state.requestPasswordResetErrors = null
    state.requestedPasswordReset = false
  },
  REQUESTED_PASSWORD_RESET_STATUS: (state, action) => {
    state.requestingPasswordReset = false
    state.requestPasswordResetErrors = action.found !== true ? "A user doesn't exist for this email" : null
    state.requestedPasswordReset = action.found === true
  },
  RESETTING_PASSWORD_STATUS: (state, action) => {
    state.resettingPassword = true
    state.resetPasswordValidToken = null
    state.resetPasswordErrors = null
    state.resetPassword = false
  },
  RESET_PASSWORD_STATUS: (state, action) => {
    state.resettingPassword = false
    state.resetPasswordValidToken = action.validToken
    state.resetPasswordErrors = action.errors
    state.resetPassword = action.reset
  },
})
  
export default user