import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import EnhancedTableHead from '../googleAnalytics/EnhancedTableHead'
import { Prompt } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CheckIcon from '@material-ui/icons/Check'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'

class AccountAppKeywordsTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedRows: [],
      order: 'asc',
      orderBy: 'keyword',
      enteredKeyword: ''
    }
    this.onAddKeyword = this.onAddKeyword.bind(this)
    this.onDeleteKeyword = this.onDeleteKeyword.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.onRequestSort = this.onRequestSort.bind(this)
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this)
    this.onEnteredKeywordChange = this.onEnteredKeywordChange.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    // keywords just loaded
    if (prevProps.accountAppKeywords.data === null && this.props.accountAppKeywords.data !== null) {
      this.props.accountAppKeywords.data.forEach(item => {
        if (item.latest_parse_date === null) {
          this.props.checkAccountAppKeywordPosition(item.keyword)
        }
      })
    } else if (prevProps.accountAppKeywords.data !== null && prevProps.accountAppKeywords.isLoading === true && this.props.accountAppKeywords.isLoading === false) {
      // keyword added/deleted
      this.props.accountAppKeywords.data.forEach(item => {
        if (item.latest_parse_date === null && (item.checkingPosition === undefined || item.checkingPosition === false)) {
          this.props.checkAccountAppKeywordPosition(item.keyword)
        }
      })
    }
  }

  onEnteredKeywordChange(e) {
    this.setState({ enteredKeyword: e.target.value })
  }

  onAddKeyword() {
    if (this.state.enteredKeyword === '') {
      return
    }
    this.props.addAccountAppKeyword(this.state.enteredKeyword)
  }

  onDeleteKeyword(id) {
    this.props.deleteAccountAppKeyword(id)
  }

  onDelete() {
    this.state.selectedRows.forEach((id) => {
      this.props.deleteAccountAppKeyword(id)
    })
    this.setState({
      selectedRows: []
    })
  }

  onClickRow(id) {
    const selectedIndex = this.state.selectedRows.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selectedRows, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selectedRows.slice(1))
    } else if (selectedIndex === this.state.selectedRows.length - 1) {
      newSelected = newSelected.concat(this.state.selectedRows.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selectedRows.slice(0, selectedIndex),
        this.state.selectedRows.slice(selectedIndex + 1),
      )
    }

    this.setState({
      selectedRows: newSelected
    })
  }

  handleSelectAllClick(e) {
    if (e.target.checked) {
      const newSelecteds = this.props.accountAppKeywords.data.map((n) => n.id)
      this.setState({
        selectedRows: newSelecteds
      })
      return
    }
    this.setState({
      selectedRows: []
    })
  }

  onRequestSort(e, property) {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc'
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property
    })
  }

  onEnteredKeywordChange(e) {
    this.setState({
      enteredKeyword: e.target.value
    })
  }

  render() {
    return (
      <React.Fragment>
        {this.renderPositions()}
      </React.Fragment>
    )
  }

  renderPositions() {
    if (this.props.accountAppKeywords.data === null) {
      return null
    }

    let data = [...this.props.accountAppKeywords.data]

    let headCells = [
      { id: 'keyword', disablePadding: false, label: 'Keyword' }
    ]

    let flattenedMatches = ''
    if (this.props.displayMatches === true && this.props.matches !== undefined) {
      flattenedMatches = this.props.matches.join(' ')
      headCells.push({ id: 'matched', disablePadding: true, label: 'Used' })
      data = data.map((row) => ({
        ...row,
        matched: flattenedMatches.includes(row.keyword.toLowerCase())
      }))
    }

    if (this.props.displayPreviousPosition === true) {
      headCells.push({ id: 'previous_position', numeric: true, disablePadding: false, label: 'Previous Position' })
    }
    if (this.props.displayPosition === true) {
      headCells.push({ id: 'position', numeric: true, disablePadding: false, label: this.props.positionLabel })
    }

    return (
      <React.Fragment>
        <TableContainer>
        <EnhancedTableToolbar
          numSelected={this.state.selectedRows.length}
          readOnly={this.props.readOnly}
          selectedComponent={
            (
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography color="inherit" variant="subtitle1" component="div">
                    {this.state.selectedRows.length} selected
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                  <Tooltip title="Delete">
                    <IconButton aria-label="delete" onClick={this.onDelete}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            )
          }
          noneSelectedComponent={
            (
              <React.Fragment>
                <TextField 
                  fullWidth
                  value={this.state.enteredKeyword}
                  label='Add Keyword'
                  onChange={this.onEnteredKeywordChange}
                />
                <Button style={{ marginLeft: 8, marginTop: 11 }} variant='outlined' color='secondary' onClick={this.onAddKeyword}>
                  Add
                </Button>
              </React.Fragment>
            )
          }
        />
        <Table>
          <EnhancedTableHead
            order={this.state.order}
            orderBy={this.state.orderBy}
            onRequestSort={this.onRequestSort}
            rowCount={data.length}
            headCells={headCells}
            numSelected={this.state.selectedRows.length}
            onSelectAllClick={this.handleSelectAllClick}
            readOnly={this.props.readOnly}
          />
          <TableBody>
            {this.stableSort(data, this.getComparator(this.state.order, this.state.orderBy)).map((row) => {
              const isItemSelected = this.state.selectedRows.indexOf(row.id) !== -1
              let positionColour = 'inherit'
              let textDecoration = 'underline'
              if (this.props.displayPreviousPosition === true) {
                positionColour = '#000'
                textDecoration = 'initial'
                if (row.checkingPosition !== true) {
                  if (row.previous_position !== null) {
                    positionColour = row.previous_position < row.position ? '#f44336' : row.previous_position > row.position ? '#4caf50' : '#000'
                  }
                }
              }
              return (
                <TableRow key={row.id} hover selected={isItemSelected}>
                  {
                    this.props.readOnly === false &&
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(e) => this.onClickRow(row.id)}
                        />
                      </TableCell>
                  }
                  <TableCell component="th" scope="row">
                    {row.keyword}
                  </TableCell>
                  {
                    this.props.displayMatches === true && this.props.matches !== undefined &&
                      <TableCell>
                        { row.matched === true &&
                            <CheckIcon style={{ color: 'rgb(0, 137, 123)', fontSize: 'inherit' }} />
                        }
                      </TableCell>
                  }
                  {
                    this.props.displayPreviousPosition === true &&
                      <TableCell>
                        {row.previous_position}
                      </TableCell>
                  }
                  {
                    this.props.displayPosition === true &&
                      <TableCell>
                        <Tooltip title={row.latest_parse_date !== null ? row.latest_parse_date : 'not checked'}>
                            <span style={{ color: positionColour }}>
                              { row.checkingPosition === true ? 'checking...'
                                : row.position !== null ?
                                row.position
                                : row.latest_parse_date !== null ?
                                '> 100'
                                : 'not checked'
                              }
                            </span>
                        </Tooltip>
                      </TableCell>
                  }
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Prompt
          when={data.some(x => x.checkingPosition === true )}
          message="Keyword positions are still loading. The app may be slow until this is finished. Leave this page?"
        />
        </TableContainer>
      </React.Fragment>
    )
  }

  descendingComparator(a, b, orderBy) {
    let a_for_comparison
    let b_for_comparison

    if (orderBy === 'position' || orderBy === 'position_after') {
      a_for_comparison = a[orderBy] === null ? 999999 : Number(a[orderBy])
      b_for_comparison = b[orderBy] === null ? 999999 : Number(b[orderBy])
    } else if (orderBy === 'locale') {
      a_for_comparison = a[orderBy] === null ? '' : a[orderBy]
      b_for_comparison = b[orderBy] === null ? '' : b[orderBy]
    } else {
      a_for_comparison = a[orderBy]
      b_for_comparison = b[orderBy]
    }
    if (b_for_comparison < a_for_comparison) {
      return -1
    }
    if (b_for_comparison > a_for_comparison) {
      return 1
    }
    return 0
  }
  
  getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy)
  }
  
  stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }
}

AccountAppKeywordsTable.propTypes = {
  accountAppKeywords: PropTypes.object.isRequired,
  deleteAccountAppKeyword: PropTypes.func.isRequired,
  addAccountAppKeyword: PropTypes.func.isRequired,
  checkAccountAppKeywordPosition: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  displayPreviousPosition: PropTypes.bool.isRequired,
  positionLabel: PropTypes.string.isRequired,
  displayPosition: PropTypes.bool.isRequired,
  matches: PropTypes.array,
  displayMatches: PropTypes.bool.isRequired
}

export default AccountAppKeywordsTable