import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import AppSearch from '../apps/AppSearch'
import Chip from '@material-ui/core/Chip'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import AddIcon from '@material-ui/icons/Add'
import { Enums } from 'utils'
import { GOOGLE_OAUTH2_PATH, API_BASE } from 'consts'
import PropertiesListGA4 from '../googleAnalytics/PropertiesListGA4'

class AddAccountApp extends React.Component {
  constructor(props) {
    super(props)

    // these act like steps
    this.state = {
      adding: props.adding,
      selectedApp: null,
      isCompetitor: props.isCompetitor,
      selectedProperty: null,
      done: false,
      maybeSkippingGAAuth: false,
      definitelySkippingGAAuth: props.definitelySkippingGAAuth
    }
    this.onSelectedApp = this.onSelectedApp.bind(this)

    if (props.selectAppsId !== undefined && props.selectAppsId !== null) {
      this.state.selectedApp = props.allApps.find(x => x.apps_id === props.selectAppsId)
    }
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {
    // oh, my head hurts
    if (this.state.selectedApp !== null && this.state.isCompetitor !== null && (this.state.isCompetitor === true || (this.state.selectedProperty !== null || this.state.definitelySkippingGAAuth === true)) && this.state.done === false) {
      this.setState({ done: true, adding: false }, () => this.props.onAdded(this.state.selectedApp.apps_id, this.state.isCompetitor, this.state.selectedProperty, this.state.selectedApp.url, this.state.selectedApp.app_name))
    }
  }

  onSelectedApp(newValue) {
    this.setState({ selectedApp: newValue })
  }

  render() {
    if (this.state.adding === false) {
      return this.renderNotAddingState()
    } else {
      return this.renderAddingState()
    }
  }

  renderNotAddingState() {
    return (
      <Button
        fullWidth
        style={{ height: 82 }}
          variant="outlined" size="medium"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={ () => this.setState({ adding: true, selectedApp: null, selectedProperty: null, done: false }) } disabled={this.props.disabled}
        >
        { this.props.hitLimit === true ? 'Upgrade To Track More' : 'Track Another' }
      </Button>
    )
  }

  renderAddingState() {
    let stepContent = null
    let cardHeader = null
    let subheader = null

    if (this.state.selectedApp === null) {
      cardHeader = <CardHeader style={{ alignItems: 'initial', overflowWrap: 'anywhere' }} title={ <Typography variant='h6'>Track An App</Typography> } subheader={subheader} />
      stepContent = <AppSearch allApps={this.props.allApps} onChange={ (target, newValue) => this.setState({ selectedApp: newValue })} appType={Enums.AppType.Own}/>
    } else if (this.state.isCompetitor === null) {
      cardHeader = <CardHeader style={{ alignItems: 'initial', overflowWrap: 'anywhere' }} title={ <Typography variant='h6'>Track An App</Typography> } subheader={subheader} />
      stepContent = (
        <React.Fragment>
          <Typography variant='subtitle1' gutterBottom>Is this your own app or a competitor?</Typography>
          <Chip color={ this.state.isCompetitor === false ? 'primary' : 'default' } size="small" label="Own App" onClick={ () => this.setState({ isCompetitor: false }) } />
          <Chip color={ this.state.isCompetitor === true ? 'primary' : 'default' } size="small" label="Competitor" onClick={ () => this.setState({ isCompetitor: true }) } />
        </React.Fragment>
      )
    } else if (this.state.isCompetitor === false && this.props.gaStatus !== Enums.GAStatus.Authed && this.state.definitelySkippingGAAuth === false) {

      if (this.state.maybeSkippingGAAuth === false) {
        cardHeader = <CardHeader style={{alignItems: 'initial', overflowWrap: 'anywhere'}}
                                 title={<Typography variant='h6'>Track An App</Typography>}
                                 subheader={<Typography variant='subtitle1'>App Store Analytics will use Google
                                   Analytics to provide detailed insights for
                                   your {this.state.selectedApp.app_name} app listing</Typography>}/>
        stepContent = (
            <React.Fragment>
              <Button variant="contained" color="secondary"
                      href={`${API_BASE}${GOOGLE_OAUTH2_PATH}?state=${encodeURIComponent(`/l/setup||${this.props.currentAccountId}||${this.state.selectedApp.apps_id}`)}`}>Authorize
                Google Analytics</Button>
              <Button onClick={() => this.setState({maybeSkippingGAAuth: true})}>Skip</Button>
              <Typography variant='subtitle2' style={{marginTop: 8}} gutterBottom>You can revoke this at any time in
                your Google Account</Typography>
              <Typography variant='subtitle2' style={{marginTop: 28}}>Don't have Google Analytics tracking your app
                listing? <a
                    href='https://shopify.dev/concepts/app-store/being-successful-in-the-app-store/marketing-internally/tracking-your-listing-traffic'
                    target='_blank'>Follow the instructions here</a></Typography>
            </React.Fragment>
        )
      } else {
        cardHeader = <CardHeader style={{alignItems: 'initial', overflowWrap: 'anywhere'}}
                                 title={<Typography variant='h6'>Track An App</Typography>}
                                 subheader={<Typography variant='subtitle1'>The app will be very limited without a
                                   link to your Google Analytics. You can revoke access at any time in your Google
                                   Account.</Typography>}/>
        stepContent = (
            <React.Fragment>
              <Button variant="contained" color="secondary"
                      href={`${API_BASE}${GOOGLE_OAUTH2_PATH}?state=${encodeURIComponent(`/l/setup||${this.props.currentAccountId}||${this.state.selectedApp.apps_id}`)}`}>Okay,
                Authorize Google Analytics</Button>
              <Button onClick={() => this.setState({definitelySkippingGAAuth: true})}>I understand, still skip
                this</Button>
            </React.Fragment>
        )
      }

    } else if (this.state.isCompetitor === false && this.state.selectedProperty === null) {
      cardHeader = <CardHeader style={{ alignItems: 'initial', overflowWrap: 'anywhere' }} title={ <Typography variant='h6'>Track An App</Typography> } subheader={ <Typography variant='subtitle1'>Select the Google Analytics property tracking your {this.state.selectedApp.app_name} app listing</Typography> } />
      stepContent = <PropertiesListGA4 selectedProperty={null} properties={this.props.properties} onPropertySelected={(selectedProperty) => { this.setState({ selectedProperty }) }} />
    } else {
      stepContent = 'Done!'
    }

    let cardActions = null
    if (this.props.canCancel === true) {
      cardActions = (
        <CardActions style={{ height: 50 }}>
          <Button size="small" color="primary" onClick={() => this.setState({ adding: false })}>
            Cancel
          </Button>
        </CardActions>
      )
    }

    return (
      <Card style={{ width: '100%' }} elevation={3}>
        {cardHeader}
        <CardContent style={{ height: 245, overflow: 'auto' }}>{stepContent}</CardContent>
        <Divider />
        {cardActions}
      </Card>
    )
  }
}

AddAccountApp.defaultProps = {
  adding: false,
  disabled: false,
  canCancel: true,
  definitelySkippingGAAuth: false,
  hitLimit: false
}

AddAccountApp.propTypes = {
  adding: PropTypes.bool.isRequired,
  onAdded: PropTypes.func.isRequired,
  gaStatus: PropTypes.oneOf(Object.values(Enums.GAStatus)).isRequired,
  properties: PropTypes.object.isRequired,
  isCompetitor: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  allApps: PropTypes.array,
  canCancel: PropTypes.bool.isRequired,
  currentAccountId: PropTypes.string.isRequired,
  selectAppsId: PropTypes.string,
  definitelySkippingGAAuth: PropTypes.bool.isRequired,
  hitLimit: PropTypes.bool.isRequired
}

export default AddAccountApp