import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ListboxComponent from '../shared/ListboxComponent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { ApiUtils, Enums } from 'utils'
import Button from '@material-ui/core/Button'
import { Redirect } from 'react-router-dom'
import { addAccountApp } from '../../actions/actions'
import { connect } from 'react-redux'
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const defaultFilterOptions = createFilterOptions();

class AppSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: null,
      IsValidUrl: null,
      errorMessage: null,
      hideButton: false
    }
    this.timerId = null;

  }
  
  handleAddApp(app_url){
    const shopifyAppPattern = /^https:\/\/apps\.shopify\.com\/[a-zA-Z0-9_-]+$/;
    let isValidUrl = shopifyAppPattern.test(app_url);

    this.setState({
      IsValidUrl: isValidUrl,
      errorMessage: isValidUrl ? null : 'Please enter a valid Shopify App URL.'
    });
    
    if (isValidUrl) {
      this.setState({ hideButton: true });

      ApiUtils.post('apps', { app_url: app_url }
        ).then(response => response.json())
        .then((json) => {
          if (json.status == 404) {
            this.setState({
                  IsValidUrl: false,
                  errorMessage: 'The provided URL does not correspond to a valid Shopify App.',
                  hideButton: false
              });
          }
          else{
            this.setState({
              IsValidUrl: true,
              errorMessage: null,
              isappfound: true
            });

            this.props.onChange(null, json.data);
            
            if (this.props.appType == Enums.AppType.Lookup || this.props.appType == Enums.AppType.Competitor){
              this.props.dispatch(addAccountApp(
                  json.data.account_id,
                  json.data.apps_id,
                  this.props.appType,
                  null,
                  null,
                  null,
                  null,
                  json.data.url,
                  json.data.app_name,
                  true))
            }
          }

        })
    }
  };

  render() {
    let allApps = this.props.allApps
    let placeholder = "Search app names..."
    if (this.props.isLoading === true || allApps.length == 0) {
      allApps = []
      placeholder = "we’re searching for your app..."
    }
        
    // would love to include the app url here too, but the autocomplete component currently renders items at fixed heights
    // maybe: https://github.com/bvaughn/react-virtualized/blob/master/docs/CellMeasurer.md
    /*
renderOption={(option) => (
          <List>
            <ListItem>
              <ListItemText primary={option.app_name} secondary={option.url.replace('https://','')} />
            </ListItem>
          </List>
        )}

    */

    return (
      <div style={{width: '100%'}}>
        <Autocomplete
          disabled={this.props.isLoading === true || allApps.length === 0}
          size="small"
          options={allApps}
          // onChange={this.props.onChange}
          onChange={(event, value) => {
            if (value.apps_id === "no-caption") {
              this.setState({ isappfound: false, hideButton: false });
            }else{
              this.setState({ isappfound: true });
              this.props.onChange(null, value);
            }
          }}  
          ListboxComponent={ListboxComponent}
          fullWidth
          value={this.state.value}
          onInputChange={this.inputChange}
          renderInput={(params) => <TextField {...params} autoFocus variant="outlined" placeholder={placeholder} />}
          getOptionLabel={(option) => option.app_name}
          filterOptions={(options, state) => {
            const results = defaultFilterOptions(options, state);
            if (results.length === 0) {
              return [{apps_id: 'no-caption', app_name: 'Add App URL manually', url: 'https://apps.shopify.com/xx-xxx-xx'}]
            }

            return results;
          }}
        />

        {this.state.isappfound === false && (
          <div style={{ marginTop: 20 }}>
            Add Your Shopify App URL
            <TextField id="app_url" type="url" variant="outlined" placeholder='https://apps.shopify.com/YOUR_APP_NAME' style={{width: '100%'}} autoFocus={true}/>
            {!this.state.IsValidUrl && <p style={{ color: 'red' }}>{this.state.errorMessage}</p>}
            <Button size="small" disabled={this.state.hideButton} variant="outlined" style={{ marginTop: 10, float: 'right' }} color="primary" onClick={() => { this.handleAddApp(document.getElementById('app_url').value) }}>
              {this.state.hideButton ? (
                <>
                  <span>Adding App</span>
                  <img src="/assets/images/loading.gif" alt="Loading" style={{ marginLeft: 5, height: 15, width: 15 }} />
                </>
              ) : (
                'Add Your App'
              )}
            </Button>
          </div>
        )}
      </div>  
    )
  }
}

AppSearch.defaultProps = {
  isLoading: false
}

AppSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  allApps: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  appType: PropTypes.string.isRequired
}

// export default AppSearch
function mapStateToProps(state) {
  return { }
}


export default connect(mapStateToProps)(AppSearch)