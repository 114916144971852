import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Enums } from 'utils'
import 'url-search-params-polyfill'
import {hideBanner, loadAppListingHistory, loadAppListingActiveAt, updateAccountApp} from '../../actions/actions'
import Container from '@material-ui/core/Container'
import moment from 'moment'
import VersionTimeline from '../../components/appListing/VersionTimeline'
import VersionDetails from '../../components/appListing/VersionDetails'
import SubAppBar from '../../components/navigation/SubAppBar'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Snackbar from '@material-ui/core/Snackbar'
import CompareInAnalyticsDialog from '../../components/appListing/CompareInAnalyticsDialog'

class AppListingHistoryContainer extends React.Component {
  constructor(props) {
    super(props)
    // appPath: null,
    this.state = {
      changeDate: null,
      trackAppButtonOpen: false,
      selectedTrackAppButtonCompetitor: true,
      addedSnackbarOpen: false,
      compareInAnalyticsDialogOpen: false
    }
    this.onChangeDateClick = this.onChangeDateClick.bind(this)
    this.onTrackAppButtonClick = this.onTrackAppButtonClick.bind(this)
    this.onToggleTrackAppButton = this.onToggleTrackAppButton.bind(this)
    this.onCloseTrackAppButton = this.onCloseTrackAppButton.bind(this)
    this.onTrackAppButtonItemClick = this.onTrackAppButtonItemClick.bind(this)

    this.trackAppButtonRef = React.createRef()
  }

  componentDidMount() {
    this.props.dispatch(loadAppListingHistory(this.props.accountApps.currentAccountApp.url, this.props.accounts.currentAccountId))
    const query = new URLSearchParams(this.props.location.search)
    const changeDate = query.get('d')
    if (changeDate !== null) {
      this.setState({ changeDate })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.accountApps.currentAccountApp.id !== this.props.accountApps.currentAccountApp.id) {
      if (this.state.changeDate !== null) {
        this.props.history.push(`${this.props.location.pathname}`)
        this.setState({ changeDate: null }) // flip back to the timeline if just switched apps
      }
      this.props.dispatch(loadAppListingHistory(this.props.accountApps.currentAccountApp.url, this.props.accounts.currentAccountId))
    } else if (this.state.changeDate !== prevState.changeDate && this.state.changeDate !== null) {
      //const targetSnapshot = this.props.appListing.snapshots.find(x => x.activeAt === this.state.changeDate)
      //if (targetSnapshot === undefined) {
        this.props.dispatch(loadAppListingActiveAt(this.state.changeDate, this.props.accountApps.currentAccountApp.url, this.props.accounts.currentAccountId))
      //}
    } else if (prevProps.location.search !== this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search)
      const changeDate = query.get('d')
      if (changeDate === null && prevProps.changeDate !== null) {
        this.setState({ changeDate: null })
      } else if (changeDate !== null && prevProps.changeDate !== changeDate) {
        this.setState({ changeDate })
      }
    }

    if (prevProps.accountApps.currentAccountApp.id === this.props.accountApps.currentAccountApp.id && this.props.accountApps.currentAccountApp.appType !== Enums.AppType.Lookup && prevProps.accountApps.currentAccountApp.appType !== this.props.accountApps.currentAccountApp.appType) {
      this.setState({ addedSnackbarOpen: true })
    }

    /*
    if (prevProps.location.search !== this.props.location.search || prevProps.accountApps.currentAccountApp.id !== this.props.accountApps.currentAccountApp.id) {
      this.setStateFromUrl()
    } else {
      if (this.state.changeDate !== prevState.changeDate && this.state.changeDate !== null) {
        const targetSnapshot = this.props.appListing.snapshots.find(x => x.activeAt === this.state.changeDate)
        if (targetSnapshot === undefined) {
          this.props.dispatch(loadAppListingActiveAt(this.state.changeDate, this.state.appPath, this.props.accounts.currentAccountId))
        }
      } else if (this.state.appPath !== prevState.appPath) {
        this.props.dispatch(loadAppListingHistory(this.state.appPath, this.props.accounts.currentAccountId))
      }
    }
    */
  }

componentWillUnmount() {
  this.props.dispatch(hideBanner())
}

/*
  setStateFromUrl() {
    const query = new URLSearchParams(this.props.location.search)
    let appPath = query.get('ap')
    const changeDate = query.get('d')
    if (appPath === null) {
      // this is the only exception
      appPath = this.props.accountApps.currentAccountApp.url
      this.props.history.replace(`${this.props.location.pathname}?ap=${appPath}`)
    } else {
      this.setState({ appPath, changeDate })
    }
  }
*/
  onChangeDateClick(changeDate) {
    this.props.history.push(`${this.props.location.pathname}?d=${changeDate}`)
    this.setState({ changeDate })
  }

  onTrackAppButtonClick() {
    if (this.props.user.anonymous === true) {
      this.props.history.push('/sign-up')
    } else {
      this.props.dispatch(updateAccountApp(this.props.accountApps.currentAccountApp.id, { appType: this.state.selectedTrackAppButtonCompetitor === true ? Enums.AppType.Competitor : Enums.AppType.Own }))
    }
  }

  onTrackAppButtonItemClick(competitor) {
    if (this.props.user.anonymous === true) {
      this.props.history.push('/sign-up')
    } else {
      this.props.dispatch(updateAccountApp(this.props.accountApps.currentAccountApp.id, { appType: competitor === true ? Enums.AppType.Competitor : Enums.AppType.Own }))
      this.setState({ trackAppButtonOpen: false, selectedTrackAppButtonCompetitor: competitor })
    }
  }

  onToggleTrackAppButton() {
    this.setState({ trackAppButtonOpen: !this.state.trackAppButtonOpen })
  }

  onCloseTrackAppButton(e) {
    if (this.trackAppButtonRef.current && this.trackAppButtonRef.current.contains(e.target)) {
      return
    }
    this.setState({ trackAppButtonOpen: false })
  }

  render() {
    let timeline = null
    let title = 'Changelog'
    let onBack = null
    let rightComponent = null

    if (this.state.changeDate !== null) {
      const targetSnapshot = this.props.appListing.snapshots.find(x => x.activeAt === this.state.changeDate)
      const change = this.props.appListing.history.changes === null ? null : this.props.appListing.history.changes.find(x => x.changeDate === this.state.changeDate)
      if (targetSnapshot !== undefined && targetSnapshot.isLoading === false && change !== null && this.props.appListing.history.isLoading === false) {
        title = `App Listing Changes ${moment(this.state.changeDate).format('DD MMMM YYYY')}`
        onBack = () => this.props.history.push(`${this.props.location.pathname}`)
        timeline = (
          <VersionDetails snapshot={targetSnapshot} change={change} viewType='split' />
        )
        if (this.props.accountApps.currentAccountApp.appType === Enums.AppType.Own) {
          rightComponent = (
            <React.Fragment>
              <Button color="secondary" variant="outlined" onClick={() => this.setState({ compareInAnalyticsDialogOpen: true })}>
                Compare in Analytics
              </Button>
              <CompareInAnalyticsDialog open={this.state.compareInAnalyticsDialogOpen} targetSnapshot={targetSnapshot} onClose={() => this.setState({ compareInAnalyticsDialogOpen: false })} />
            </React.Fragment>
          )
        }
      }
    } else {
      if (this.props.appListing.history.changes !== null && this.props.appListing.history.isLoading === false) {
        timeline = (
          <React.Fragment>
            <VersionTimeline changes={this.props.appListing.history.changes} onChangeDateClick={this.onChangeDateClick} ownApp={this.props.accountApps.currentAccountApp.appType === Enums.AppType.Own} />
          </React.Fragment>
        ) 
      } 
    }

    if (this.props.accountApps.currentAccountApp.appType === Enums.AppType.Lookup && this.props.accounts.currentAccount.appsLimit > 0) {
      if (this.props.accountApps.forCurrentAccount.filter(x => x.appType !== Enums.AppType.Lookup).length + 1 > this.props.accounts.currentAccount.appsLimit) {
        rightComponent = (
          <Button color="primary" component={Link} to='/l/billing' style={{ whiteSpace: 'nowrap' }}>
            Upgrade to track more apps
          </Button>
        )
      } else {
        rightComponent = (
          <React.Fragment>
            <ButtonGroup color="secondary" ref={this.trackAppButtonRef} aria-label="split button">
              <Button onClick={this.onTrackAppButtonClick}>{this.state.selectedTrackAppButtonCompetitor === true ? 'Track as competitor' : 'Track as own app'}</Button>
              <Button
                color="secondary"
                size="small"
                onClick={this.onToggleTrackAppButton}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper open={this.state.trackAppButtonOpen} anchorEl={this.trackAppButtonRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.onCloseTrackAppButton}>
                    <MenuList id="split-button-menu">
                      <MenuItem
                        selected={this.state.selectedTrackAppButtonCompetitor === true}
                        onClick={(event) => this.onTrackAppButtonItemClick(true)}
                      >
                        Track as competitor
                      </MenuItem>
                      <MenuItem
                        selected={this.state.selectedTrackAppButtonCompetitor === false}
                        onClick={(event) => this.onTrackAppButtonItemClick(false)}
                      >
                        Track as own app
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
            </Popper>
          </React.Fragment>
        )
      }
    }

    return (
      <React.Fragment>
        <SubAppBar title={title} onBack={onBack} rightComponent={rightComponent} />
        <Container>
          {timeline}
        </Container>
        <Snackbar
            open={this.state.addedSnackbarOpen}
            autoHideDuration={6000}
            onClose={() => this.setState({ addedSnackbarOpen: false })}
            message="Added"
          />
      </React.Fragment>
    )
  }

  buildAnalyticsUrl(snapshot) {
    let fromDate = moment(snapshot.currentListingStartDate)
    let toDate = snapshot.currentListingEndDate
    let compareFromDate = snapshot.previousListingStartDate
    let compareToDate = snapshot.previousListingEndDate

    toDate = toDate === null ? moment().subtract(1, 'days') : moment(toDate)

    const currentListingDaysRunning = toDate.diff(fromDate, 'days')

    compareFromDate = moment(fromDate).subtract(currentListingDaysRunning, 'days')
    compareToDate = fromDate

    // super-simple... just the current app listing days backward: probably wrong in lots of cases
    return `./listing-traffic?fd=${fromDate.format('YYYY-MM-DD')}&td=${toDate.format('YYYY-MM-DD')}&cfd=${compareFromDate.format('YYYY-MM-DD')}&ctd=${compareToDate.format('YYYY-MM-DD')}`
  }
}

function mapStateToProps(state) {
  const { user, appListing, accountApps, accounts } = state

  return { user, appListing, accountApps, accounts }
}

AppListingHistoryContainer.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(AppListingHistoryContainer)