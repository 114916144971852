import { createReducer } from '@reduxjs/toolkit'
import {
  SHOW_BANNER_STATUS,
  HIDE_BANNER_STATUS
} from '../actions/actions'
import { Enums } from 'utils'

const initialState = {
  text: null,
  icon: null,
  button: null
}

const banner = createReducer(initialState, {
  SHOW_BANNER_STATUS: (state, action) => { 
    state.text = action.text,
    state.icon = action.icon,
    state.button = action.button
  },
  HIDE_BANNER_STATUS: (state, action) => {
    state.text = null
    state.icon = null
    state.button = null
  }
})

export default banner