import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import { ApiUtils, Enums } from 'utils'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import VpnLockIcon from '@material-ui/icons/VpnLock'
import CardMedia from '@material-ui/core/CardMedia'
import WarningIcon from '@material-ui/icons/Warning'

class AccountAppCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    if (this.props.accountApp.appType === Enums.AppType.Competitor) {
    } else {
    }

    let status = null
    if (this.props.accountApp.appType !== Enums.AppType.Competitor && this.props.accountApp.googleAnalyticsProperty === null) {
      status = (
        <React.Fragment>
          <Divider />
          <CardActions style={{ height: 50 }}>
          <Button size="small" variant="outlined" color="secondary" startIcon={<WarningIcon />} onClick={() => this.setState({ adding: false })}>
          Link Google Analytics
          </Button>
        </CardActions>
        
        </React.Fragment>
      )
    }
    status = null // feature pushed back

    const linkTo = this.props.accountApp.appType === Enums.AppType.Own ? `/l/apps/${this.props.accountApp.urlPath}/listing-traffic` : `/l/apps/${this.props.accountApp.urlPath}/listing-history`

    return (
      <Card elevation={1}>
        <div style={{ display: 'flex', height: 82 }} >
          <CardMedia
          component={Link} to={linkTo}
          style={{ flexDirection: 'column', flex: '0 0 82px' }}
            image={ ApiUtils.apiUrl(`/app_listing/icon?path=${ this.props.accountApp.urlPath }`) }
          />
          <CardActionArea component={Link} to={linkTo}>
            <CardContent style={{ flexDirection: 'column', flex: '1' }}>
              <Typography variant='subtitle1' style={{ lineHeight: 'initial', overflowWrap: 'anywhere' }}>{this.props.accountApp.name}</Typography>
            </CardContent>
          </CardActionArea>
          <CardActions style={{ flexDirection: 'column', flex: '0 0 38px' }}>
            <IconButton aria-label="delete" onClick={ () => this.props.onDelete(this.props.accountApp.id) } disabled={this.props.allowDelete === false}>
              <DeleteIcon />
            </IconButton>
          </CardActions>
        </div>
        {status}
      </Card>
    )
  }
}

AccountAppCard.propTypes = {
  accountApp: PropTypes.object.isRequired,
  allowDelete: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default AccountAppCard