import { createReducer } from '@reduxjs/toolkit'
import {
  LOADING_APP_LISTING_HISTORY_STATUS,
  LOADED_APP_LISTING_HISTORY_STATUS,
  LOADING_APP_LISTING_ACTIVE_AT_STATUS,
  LOADED_APP_LISTING_ACTIVE_AT_STATUS,
  CLEAR_APP_LISTING_STATE_STATUS,
  LOAD_NEW_APP_LISTING_STATUS,
  CLEAR_NEW_APP_LISTING_DATA
} from "../actions/actions"
import { Enums } from "utils"
import { diffLines, formatLines } from "unidiff"
import moment from "moment"

const initialState = {
  history: {
    isLoading: false,
    changes: null // []
  },
  current: {
    isLoading: false,
    listing: null
  },
  snapshots: [
    {
      isLoading: false,
      activeAt: '1900-06-15',
      currentListing: null,
      currentListingStartDate: null,
      currentListingEndDate: null,
      previousListing: null,
      previousListingStartDate: null,
      previousListingEndDate: null,
      diffExpanded: null,
      diffCollapsed: null
    }
  ],
  newAppListing: {
    id: null,
    appName: '',
    appIntroduction: '',
    appDescription: '',
    appHighlights: ['', '', '', '', '']
  }
}

const appListing = createReducer(initialState, {
  LOADING_CURRENT_APP_LISTING_STATUS: (state, action) => {
    state.current = {
      isLoading: true,
      listing: null
    }
  },
  LOADED_CURRENT_APP_LISTING_STATUS: (state, action) => {
    state.current = {
      isLoading: false,
      listing: action.data
    }
  },
  LOADING_APP_LISTING_HISTORY_STATUS: (state, action) => {
    state.history = {
      isLoading: true,
      changes: null
    }
  },
  LOADED_APP_LISTING_HISTORY_STATUS: (state, action) => {
    let changes = []
    action.changeDates.forEach((changeDate) => {
      const versionDates = calculateVersionDates(action.changeDates, changeDate)
      changes.push({
        changeDate,
        versionDates
      })
    })
    state.history = {
      isLoading: false,
      changes
    }
  },
  LOADING_APP_LISTING_ACTIVE_AT_STATUS: (state, action) => {
    let matchedSnapshot = state.snapshots.find(
      (x) => x.activeAt === action.snapshot.activeAt
    )
    if (matchedSnapshot === undefined) {
      action.snapshot.isLoading = true
      state.snapshots.push(action.snapshot)
    } else {
      matchedSnapshot.isLoading = true
      matchedSnapshot.currentListing = null
      matchedSnapshot.currentListingStartDate = null
      matchedSnapshot.currentListingEndDate = null
      matchedSnapshot.previousListing = null
      matchedSnapshot.previousListingStartDate = null
      matchedSnapshot.previousListingEndDate = null
      matchedSnapshot.diffExpanded = null
      matchedSnapshot.diffCollapsed = null
    }
  },
  LOADED_APP_LISTING_ACTIVE_AT_STATUS: (state, action) => {
    let matchedSnapshot = state.snapshots.find(
      (x) => x.activeAt === action.snapshot.activeAt
    )

    const previousListing =
      action.snapshot.previousListing === null
        ? ''
        : action.snapshot.previousListing
    const diffExpanded = formatLines(
      diffLines(previousListing, action.snapshot.currentListing),
      { context: 999999 }
    )
    const diffCollapsed = formatLines(
      diffLines(previousListing, action.snapshot.currentListing),
      { context: 3 }
    )

    if (matchedSnapshot === undefined) {
      action.snapshot.isLoading = false
      action.snapshot.diffExpanded = diffExpanded
      action.snapshot.diffCollapsed = diffCollapsed
      state.snapshots.push(action.snapshot)
    } else {
      matchedSnapshot.isLoading = false
      matchedSnapshot.currentListing = action.snapshot.currentListing
      matchedSnapshot.currentListingStartDate =
        action.snapshot.currentListingStartDate
      matchedSnapshot.currentListingEndDate =
        action.snapshot.currentListingEndDate
      matchedSnapshot.previousListing = previousListing
      matchedSnapshot.previousListingStartDate =
        action.snapshot.previousListingStartDate
      matchedSnapshot.previousListingEndDate =
        action.snapshot.previousListingEndDate
      matchedSnapshot.diffExpanded = diffExpanded
      matchedSnapshot.diffCollapsed = diffCollapsed
    }
  },
  CLEAR_APP_LISTING_STATE_STATUS: (state, action) => {
    state.history = initialState.history
    state.snapshots = initialState.snapshots
    state.current = initialState.current
  },

  LOAD_NEW_APP_LISTING_STATUS: (state, action) => {
    state.newAppListing = {
      id: action.data.app_name ?? '',
      appName: action.data.app_name ?? '',
      appIntroduction: action.data.app_introduction ?? '',
      appDescription: action.data.app_description ?? '',
      appHighlights: action.data.app_highlights ?? ''
    }
  },

  CLEAR_NEW_APP_LISTING_DATA: (state, action) => {
    state.newAppListing = {
      id: '',
      appName: '',
      appIntroduction: '',
      appDescription: '',
      appHighlights: ''
    }
  }
})


function calculateVersionDates(changeDates, changeDate) {
  let notEnoughChanges = false

  let versionB = changeDate
  let versionBStartDate = null
  let versionBEndDate = null

  let versionA = null
  let versionAStartDate = null
  let versionAEndDate = null

  if (changeDates !== null) {
    if (changeDates.length < 2) {
      notEnoughChanges = true
    } else {
      for (let ix = 0; ix < changeDates.length; ix++) {
        if (changeDates[ix] === versionB) {
          if (ix === 0) {
            // latest changes
            versionB = moment(versionB)
            versionBStartDate = versionB
            versionBEndDate = null
            
            versionA = moment(changeDates[ix+1])
            versionAStartDate = versionA
            versionAEndDate = versionB
          } else if (ix === changeDates.length - 1) {
            // first changes
            // for simplicity, versionA is always earlier than versionB
            versionA = moment(versionB)
            versionAStartDate = versionA
            versionAEndDate = moment(changeDates[ix-1])

            versionB = versionAEndDate
            versionBStartDate = versionAEndDate
            versionBEndDate = changeDates.length > 2 ? moment(changeDates[ix-2]) : null
          } else {
            // not latest, not first changes
            versionB = moment(versionB)
            versionBStartDate = moment(versionB)
            versionBEndDate = moment(changeDates[ix-1])
            
            versionA = moment(changeDates[ix+1])
            versionAStartDate = versionA
            versionAEndDate = versionB
          }
        }
      }
    }
  }
  return {
    versionAStartDate: versionAStartDate === null ? null : versionAStartDate.toISOString(),
    versionAEndDate: versionAEndDate === null ? null : versionAEndDate.toISOString(),
    versionBStartDate: versionBStartDate === null ? null : versionBStartDate.toISOString(),
    versionBEndDate: versionBEndDate === null ? null : versionBEndDate.toISOString()
  }
}

export default appListing